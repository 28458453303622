import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Box,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { Brand, TypeBrand, User, UserRole } from '../../__generated__/graphql';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_BRAND } from '../../graphql/mutations';
import SocialMediaBrand from '../SocialMediaAccounts.tsx/SocialMediaBrand';
import { BrandContext } from '../../context/BrandContext';
import BrandCorporateForm from './BrandCorporateForm';
import { BrandInput } from './BrandData';
import BrandPersonalForm from './BrandPersonalForm';
import * as Yup from 'yup';
import BrandPersonalContentContextForm from './BrandPersonalContentContextForm';
import BrandPersonalIdeationForm from './BrandPersonalIdeationForm';
import BrandCorporateInsightsForm from './BrandCorporateInsightsForm';
import { SnackbarContext } from '../../context/SnackbarContext';
import useBrandForm from '../../hooks/useBrandForm';
import { Controller } from 'react-hook-form';
import { AuthContext } from '../../context/AuthContext';
import { ExpandMore } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { GET_USERS_BY_COMPANY } from '../../graphql/queries';
import ListBrandManager from './ListBrandManager';

const inputStyle = {
  width: '100%',
  borderRadius: '8px',
  marginBottom: '10px',
  '&.MuiInputBase-root': {
    borderRadius: '8px',
  },
};

const styleChip = {
  color: '#BBBBBB',
  background: 'white',
  height: '30px',
  border: '1px solid rgba(255, 0, 122, 1)',
  padding: '0px 5px',
  '& .MuiChip-label': {
    color: 'rgba(255, 0, 122, 1)',
  },
  '&:hover': {
    backgroundColor: 'rgba(255, 0, 122, 0.1)',
  },
};

const accordionStyle = {
  borderRadius: '10px !important',
  marginBottom: '10px',
  '&:before': {
    display: 'none',
  },
  borderBottomLeftRadius: '10px',
  '& .MuiAccordion-root:last-of-type .MuiPaper-root': {
    borderBottom: 'none',
  },
  '& .MuiAccordion-root': {
    margin: 0,
  },
  '&.Mui-expanded': {
    marginTop: '0 !important',
  },
};

const accordionSummaryStyle = {
  backgroundColor: 'rgba(244, 244, 244, 1)',
  fontWeight: 700,
  borderRadius: '10px',
  border: 'none',
  '& .MuiAccordionSummary-content': {
    margin: '12px !important',
  },
  '&.Mui-expanded': {
    minHeight: '0px !important',
  },
};

interface Props {
  brandSelected: Brand | null | undefined;
  isBrandPage: boolean;
  isModal: boolean;
}

const BrandForm = ({ brandSelected, isBrandPage, isModal }: Props) => {
  // Context
  const { refetch, dataBrands } = useContext(BrandContext);
  const { user, refetchCurrentUser } = useContext(AuthContext);
  const { setErrorMessage, setSuccessMessage } = useContext(SnackbarContext);
  const { data: dataUsers } = useQuery(GET_USERS_BY_COMPANY, {
    variables: {
      filter: {
        deleted: true,
      },
    },
    fetchPolicy: 'network-only',
    skip:
      !user?.role.includes(UserRole.BrandManager) &&
      !user?.role.includes(UserRole.LimitedBrandManager),
  });

  const users = dataUsers?.colleagueUsers || [];

  // State
  const [expanded, setExpanded] = useState<string | false>(false);

  // Mutation
  const [updateBrand, { loading }] = useMutation(UPDATE_BRAND, {
    onCompleted: (data) => {
      if (data.updateBrand?.success) {
        refetch();
        refetchCurrentUser();
        setSuccessMessage('Brand updated successfully');
        setExpanded(false);
        window.scrollTo(0, 0);
        return;
      }
      setErrorMessage(data.updateBrand?.message || 'Error updating brand');
    },
  });

  // Form
  const schema = Yup.object().shape({
    associatedBrand: Yup.string().required('Associated Brand is required'),
    userBrand: Yup.object().shape({
      id: Yup.string().test('required', 'User is required', (value) => {
        if (
          watchAssociatedBrand &&
          !value &&
          brandSelected?.typeBrand === TypeBrand.Personal
        ) {
          return false;
        }
        return true;
      }),
    }),
    companyBrandId: Yup.object().shape({
      id: Yup.string().test('required', 'Company Brand is required', (value) => {
        if (
          watchAssociatedCorporateBrand &&
          isBrandPage &&
          !value &&
          brandSelected?.typeBrand === TypeBrand.Personal
        ) {
          return false;
        }
        return true;
      }),
    }),
    position: Yup.string().test('required', 'Position is required', (value) => {
      if (!value && brandSelected?.typeBrand === TypeBrand.Personal) {
        return false;
      }
      return true;
    }),
    targetBrand: Yup.array().test('required', 'Target Brand is required', (value) => {
      if (value!.length === 0 && brandSelected?.typeBrand === TypeBrand.Personal) {
        return false;
      }
      return true;
    }),
    targetPublic: Yup.string().test('required', 'Target Public is required', (value) => {
      if (!value && brandSelected?.typeBrand === TypeBrand.Personal) {
        return false;
      }
      return true;
    }),
    themeTalk: Yup.string().test(
      'required',
      'The topics you would like to talk about is required',
      (value) => {
        if (!value && brandSelected?.typeBrand === TypeBrand.Personal) {
          return false;
        }
        return true;
      },
    ),
    toneVoice: Yup.array().test('required', 'Tone of voice is required', (value) => {
      if (value!.length === 0 && brandSelected?.typeBrand === TypeBrand.Personal) {
        return false;
      }
      return true;
    }),
    corporateVoice: Yup.array().test('required', 'Tone of voice is required', (value) => {
      if (value!.length === 0 && brandSelected?.typeBrand === TypeBrand.Corporate) {
        return false;
      }
      return true;
    }),
    industry: Yup.string().test('required', 'Industry is required', (value) => {
      if (!value && brandSelected?.typeBrand === TypeBrand.Corporate) {
        return false;
      }
      return true;
    }),
    subIndustry: Yup.string().test('required', 'Sub Industry is required', (value) => {
      if (
        !value &&
        brandSelected?.typeBrand === TypeBrand.Corporate &&
        watchIndustry !== 'Other industry'
      ) {
        return false;
      }
      return true;
    }),
    otherIndustries: Yup.string().test(
      'required',
      'Other Industries is required',
      (value) => {
        if (
          !value &&
          (watchSubIndustry === 'Others' || watchIndustry === 'Other industry') &&
          brandSelected?.typeBrand === TypeBrand.Corporate
        ) {
          return false;
        }
        return true;
      },
    ),
    competitors: Yup.string().test('required', 'Competitors is required', (value) => {
      if (!value && brandSelected?.typeBrand === TypeBrand.Corporate) {
        return false;
      }
      return true;
    }),
    negatives: Yup.string().test('required', 'Negatives is required', (value) => {
      if (!value && brandSelected?.typeBrand === TypeBrand.Corporate) {
        return false;
      }
      return true;
    }),
    competitiveAdvantage: Yup.string().test(
      'required',
      'Competitive Advantage is required',
      (value) => {
        if (!value && brandSelected?.typeBrand === TypeBrand.Corporate) {
          return false;
        }
        return true;
      },
    ),
    hashtags: Yup.string().test('required', 'Hashtags is required', (value) => {
      if (!value && brandSelected?.typeBrand === TypeBrand.Corporate) {
        return false;
      }
      return true;
    }),
  });

  const { control, errors, handleSubmit, watchAllFields, watch, setValue } = useBrandForm(
    {
      schema,
      brandData: brandSelected as Brand,
    },
  );

  const {
    watchIndustry,
    watchSubIndustry,
    watchGoalsBrand,
    watchName,
    watchAssociatedBrand,
    watchAssociatedCorporateBrand,
  } = watchAllFields;

  // functions

  const handleChangeAccordion =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const shellUsersNotAsignedOrSelected = users.filter(
    (user) =>
      !dataBrands.find(
        (brand) => brand.userId === user._id && brand.userId !== brandSelected?.userId,
      ) || user._id === brandSelected?.userId,
  );

  const onSuccess = (input: BrandInput) => {
    const {
      competitors,
      hashtags,
      negatives,
      industry,
      subIndustry,
      competitiveAdvantage,
      otherIndustries,
      toneVoice,
      corporateVoice,
      position,
      targetBrand,
      targetPublic,
      themeTalk,
      language,
      goalsBrand,
      otherGoalsBrand,
      valuesBrand,
      corporateValues,
      otherCorporatePostFirstPart,
      otherCorporatePostSecondPart,
      otherCorporatePostThirdPart,
      worldLeaderInspiration,
      industryLeaderInspiration,
      hobbies,
      artistsInspiration,
      contentConsumption,
      otherContentConsumption,
      politicalSocialActivityBrand,
      trendingTopics,
      trustedMedia,
      firstPost,
      secondPost,
      thirdPost,
      fourthPost,
      themeExtra,
      themeNegative,
      corporatePostFirstPart,
      corporatePostSecondPart,
      corporatePostThirdPart,
      numberEmojis,
      numberHashtags,
      textLength,
      responsabilities,
      uniqueBrand,
      perspective,
      name,
      userBrand,
      companyBrandId,
      corporateIdeas,
      corporateTarget,
    } = input;

    updateBrand({
      variables: {
        input: {
          name: name,
          userId: watchAssociatedBrand ? userBrand.id : null,
          ...(isBrandPage
            ? { companyBrandId: watchAssociatedCorporateBrand ? companyBrandId.id : null }
            : {}),
          contentCreation: {
            industry: industry,
            subIndustry: subIndustry,
            otherIndustries: otherIndustries,
            competitiveAdvantage: competitiveAdvantage,
            toneVoice: toneVoice,
            hashtags: hashtags,
            competitors: competitors,
            negatives: negatives,
            position: position,
            targetBrand: targetBrand,
            targetPublic: targetPublic,
            themeTalk: themeTalk,
            language: language,
            goalsBrand: goalsBrand,
            otherGoalsBrand: goalsBrand?.includes('Other') ? otherGoalsBrand : '',
            valuesBrand: valuesBrand,
            corporateValues: corporateValues,
            otherCorporatePostFirstPart: otherCorporatePostFirstPart,
            otherCorporatePostSecondPart: otherCorporatePostSecondPart,
            otherCorporatePostThirdPart: otherCorporatePostThirdPart,
            worldLeaderInspiration: worldLeaderInspiration,
            industryLeaderInspiration: industryLeaderInspiration,
            hobbies: hobbies,
            artistsInspiration: artistsInspiration,
            contentConsumption: contentConsumption,
            otherContentConsumption: otherContentConsumption,
            politicalSocialActivityBrand: politicalSocialActivityBrand,
            trendingTopics: trendingTopics,
            trustedMedia: trustedMedia,
            firstPost: firstPost,
            secondPost: secondPost,
            thirdPost: thirdPost,
            fourthPost: fourthPost,
            themeExtra: themeExtra,
            themeNegative: themeNegative,
            corporatePostFirstPart: corporatePostFirstPart,
            corporatePostSecondPart: corporatePostSecondPart,
            corporatePostThirdPart: corporatePostThirdPart,
            numberEmojis: numberEmojis,
            numberHashtags: numberHashtags,
            textLength: textLength,
            responsabilities: responsabilities,
            uniqueBrand: uniqueBrand,
            perspective: perspective,
            corporateIdeas: corporateIdeas,
            corporateTarget: corporateTarget,
            corporateVoice: corporateVoice,
          },
        },
        brandId: brandSelected!._id,
      },
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSuccess)} style={inputStyle}>
        <Box
          bgcolor={'rgba(244, 244, 244, 1)'}
          borderRadius={'10px'}
          marginBottom="10px"
          padding={2}
        >
          <SocialMediaBrand
            brandSelected={brandSelected}
            control={control}
            errors={errors}
            watchName={watchName}
            inputStyle={inputStyle}
            isBrandPage={isBrandPage}
          />
        </Box>
        <Accordion
          // defaultExpanded
          sx={accordionStyle}
          expanded={expanded === 'panel1'}
          onChange={handleChangeAccordion('panel1')}
        >
          <AccordionSummary sx={accordionSummaryStyle} expandIcon={<ExpandMore />}>
            Essential Information
          </AccordionSummary>
          <AccordionDetails>
            {brandSelected?.typeBrand === TypeBrand.Corporate ? (
              <BrandCorporateForm
                control={control}
                inputStyle={inputStyle}
                styleChip={styleChip}
                watchIndustry={watchIndustry}
                watchSubIndustry={watchSubIndustry}
                errors={errors}
                setValue={setValue}
              />
            ) : (
              <>
                {brandSelected?.typeBrand === TypeBrand.Personal && (
                  <>
                    <Stack
                      flexDirection="row"
                      justifyContent="flex-end"
                      gap={2}
                      paddingRight={1}
                    >
                      <Typography fontWeight={700}>Yes</Typography>
                      <Typography fontWeight={700}>No</Typography>
                    </Stack>
                    {isBrandPage && (
                      <Controller
                        name="associatedBrand"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <RadioGroup
                            aria-label="collaboration"
                            value={value}
                            onChange={onChange}
                            sx={{
                              py: 1,
                              borderRadius: 1,
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Stack>
                              <Typography fontSize="13px" fontWeight={700}>
                                Do you want the brand to be associated with a magnettu
                                account?
                              </Typography>
                              <Typography fontSize="13px" fontWeight={700} color={'red'}>
                                {!shellUsersNotAsignedOrSelected?.length &&
                                  '(Currently, there are no users available to associate with the brand)'}
                              </Typography>
                            </Stack>
                            <Box>
                              <Radio
                                value={true}
                                disabled={!shellUsersNotAsignedOrSelected?.length}
                              />
                              <Radio
                                value={false}
                                disabled={!shellUsersNotAsignedOrSelected?.length}
                              />
                            </Box>
                          </RadioGroup>
                        )}
                      />
                    )}
                  </>
                )}

                {watchAssociatedBrand && isBrandPage && (
                  <Stack gap={2}>
                    <Typography fontSize="13px">
                      By selecting the user, this brand will be associated with the
                      magnettu account
                    </Typography>
                    <Controller
                      name="userBrand"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          sx={{ width: '400px' }}
                          options={[
                            ...(shellUsersNotAsignedOrSelected?.map((user) => ({
                              label: user.firstName
                                ? `${user.firstName} ${user.lastName}`
                                : user.email,
                              id: user._id,
                            })) || []),
                          ]}
                          value={value}
                          getOptionLabel={(option) => option.label}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={inputStyle}
                              value={value}
                              error={!!errors.userBrand?.id}
                              helperText={errors.userBrand?.id?.message}
                            />
                          )}
                          onChange={(_, selectedOption) =>
                            onChange(
                              selectedOption ? selectedOption : { id: '', label: '' },
                            )
                          }
                        />
                      )}
                    />
                  </Stack>
                )}

                {brandSelected?.typeBrand === TypeBrand.Personal && isBrandPage && (
                  <Controller
                    name="associatedCorporateBrand"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <RadioGroup
                        aria-label="collaboration"
                        value={value}
                        onChange={onChange}
                        sx={{
                          py: 1,
                          borderRadius: 1,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography fontSize="13px" fontWeight={700}>
                          Do you want to associate with a Corporate Brand?
                        </Typography>
                        <Box>
                          <Radio value={true} />
                          <Radio value={false} />
                        </Box>
                      </RadioGroup>
                    )}
                  />
                )}

                {watchAssociatedCorporateBrand && isBrandPage && (
                  <Stack gap={2} paddingLeft={2}>
                    <Typography fontSize="13px">
                      By selecting the corporate brand, this brand will be associated with
                      a Corporate Brand. (If you don't have one, it is recommended to
                      create one first
                    </Typography>
                    <Controller
                      name="companyBrandId"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          sx={{ width: '400px' }}
                          options={
                            dataBrands
                              .filter((brand) => brand.typeBrand === TypeBrand.Corporate)
                              .map((brand) => ({
                                label: brand.name,
                                id: brand._id,
                              })) || []
                          }
                          value={value}
                          getOptionLabel={(option) => option.label}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={inputStyle}
                              error={!!errors.companyBrandId?.id}
                              value={value}
                              helperText={errors.companyBrandId?.id?.message}
                            />
                          )}
                          onChange={(_, selectedOption) =>
                            onChange(
                              selectedOption ? selectedOption : { id: '', label: '' },
                            )
                          }
                        />
                      )}
                    />
                  </Stack>
                )}

                <BrandPersonalForm
                  control={control}
                  inputStyle={inputStyle}
                  styleChip={styleChip}
                  errors={errors}
                  isModal={isModal}
                />
              </>
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={accordionStyle}
          expanded={expanded === 'panel2'}
          onChange={handleChangeAccordion('panel2')}
        >
          <AccordionSummary sx={accordionSummaryStyle} expandIcon={<ExpandMore />}>
            {brandSelected?.typeBrand === TypeBrand.Corporate
              ? 'Insights'
              : 'Content Context'}
          </AccordionSummary>
          <AccordionDetails>
            {brandSelected?.typeBrand === TypeBrand.Corporate ? (
              <BrandCorporateInsightsForm
                control={control}
                inputStyle={inputStyle}
                errors={errors}
                watchGoalsBrand={watchGoalsBrand}
              />
            ) : (
              <BrandPersonalContentContextForm
                control={control}
                inputStyle={inputStyle}
                errors={errors}
                watch={watch}
              />
            )}
          </AccordionDetails>
        </Accordion>

        {brandSelected?.typeBrand === TypeBrand.Personal && (
          <Accordion
            expanded={expanded === 'panel3'}
            onChange={handleChangeAccordion('panel3')}
            sx={accordionStyle}
          >
            <AccordionSummary sx={accordionSummaryStyle} expandIcon={<ExpandMore />}>
              Ideation questions
            </AccordionSummary>
            <AccordionDetails>
              <BrandPersonalIdeationForm
                control={control}
                inputStyle={inputStyle}
                styleChip={styleChip}
                errors={errors}
                watch={watch}
              />
            </AccordionDetails>
          </Accordion>
        )}
        {(user?.role.includes(UserRole.BrandManager) ||
          user?.role.includes(UserRole.LimitedBrandManager)) && (
          <Accordion
            expanded={expanded === 'panel4'}
            onChange={handleChangeAccordion('panel4')}
            sx={accordionStyle}
          >
            <AccordionSummary sx={accordionSummaryStyle} expandIcon={<ExpandMore />}>
              Brand members
            </AccordionSummary>
            <AccordionDetails>
              <ListBrandManager
                brandSelected={brandSelected as Brand}
                listUser={users as User[]}
              />
            </AccordionDetails>
          </Accordion>
        )}
        <LoadingButton
          variant="contained"
          type="button"
          sx={inputStyle}
          onClick={handleSubmit(onSuccess)}
          loading={loading}
        >
          Save
        </LoadingButton>
        {Object.keys(errors).length > 0 && (
          <Alert
            sx={{
              alignItems: 'center',
              p: 1,
              width: 'fit-content',
              backgroundColor: 'inherit',
            }}
            severity="error"
          >
            {Object.entries(errors)
              .map(([key, value]) => value.message)
              .join(', ')}
          </Alert>
        )}
      </form>
    </>
  );
};

export default BrandForm;
