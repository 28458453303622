import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable
} from 'firebase/storage';
import {
  BrandUser,
  PostContentMedia,
  Shell,
  User,
  UserPlan,
} from './__generated__/graphql';
import { firebaseApp, storage } from './index';

export const getFullName = (
  user: Pick<User | BrandUser, 'firstName' | 'lastName' | 'email'> | null,
) => {
  if (!user?.firstName && !user?.lastName) return user?.email || '';
  return `${user?.firstName || ''} ${user?.lastName || ''}`;
};

export const getDrawerWidth = (
  isMobile: boolean,
  isSmallDevice: boolean,
  navbar?: boolean,
) => (isMobile ? '75%' : isSmallDevice ? 65 : navbar ? 260 : 100);

export const timeAgo = (date: Date) => {
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
  let interval = Math.floor(seconds / 31536000);
  if (interval > 0) {
    return interval + ` year${interval > 1 ? 's' : ''}`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 0) {
    return interval + ` month${interval > 1 ? 's' : ''}`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 0) {
    return interval + ` day${interval > 1 ? 's' : ''}`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 0) {
    return interval + ` hour${interval > 1 ? 's' : ''}`;
  }
  interval = Math.floor(seconds / 60);
  if (interval > 0) {
    return interval + ` minute${interval > 1 ? 's' : ''}`;
  }
  return Math.floor(seconds) + ' seconds';
};

export const formatDate = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };
  const currentDate = new Date();
  const year = date.getFullYear();

  if (year === currentDate.getFullYear()) {
    options.year = undefined;
  } else {
    options.year = 'numeric';
  }

  return date.toLocaleDateString('en-US', options);
};

export const uploadMedia = async (
  file: File,
  type: 'media' | 'avatar' | 'document' | 'companyLogo' | 'companyIcon',
  ownerId?: string,
  setProgress?: (progress: number) => void,
): Promise<PostContentMedia> => {
  let path = '';
  switch (type) {
    case 'media':
      if (!ownerId) {
        throw new Error('Owner id is required for media upload');
      }
      path = `media/${ownerId}/${Date.now()}_${file.name}`;
      break;
    case 'avatar':
      path = `avatars/${Date.now()}_${file.name}`;
      break;
    case 'document':
      path = `documents/${Date.now()}_${file.name}`;
      break;
    case 'companyLogo':
      path = `companyLogos/${Date.now()}_${file.name}`;
      break;
    case 'companyIcon':
      path = `companyIcons/${Date.now()}_${file.name}`;
      break;
  }

  const storageRef = ref(storage, path);
  return new Promise((resolve, reject) => {
    const metadata = {
      contentType: file.type,
    };

    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (setProgress) setProgress(progress);
      },
      (error) => {
        // Handle unsuccessful uploads
        reject(error);
      },
      () => {
        // Handle successful uploads on complete
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          resolve({
            storagePath: uploadTask.snapshot.ref.fullPath,
            url,
            type: file.type,
            alt: file.name.replace(/\.[^/.]+$/, '').replace(/[_-]/g, ' '),
          });
        });
      },
    );
  });
};

export const uploadThumbnailMedia = async (imageBlobBase64: string) => {

  const tokenRandom = Math.random().toString(36).substring(7);
  try {

    const byteCharacters = atob(imageBlobBase64.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const fileBlob = new Blob([byteArray], { type: 'image/png' });

    if (!fileBlob.size) {
      throw new Error('La imagen descargada está vacía o es inválida.');
    }

    const fileImage = new File([fileBlob], `thumbnail-${tokenRandom}.png`, { type: 'image/png' });

    // Opcional: Verificar que el archivo tiene el tipo MIME correcto
    if (fileImage.type !== 'image/png' && fileImage.type !== 'image/jpeg') {
      throw new Error('La imagen descargada no es un PNG o JPEG válido.');
    }

    const thumbnailMedia = await uploadMedia(fileImage, "media", tokenRandom);

    return thumbnailMedia;
  } catch (error) {
    console.error('Error uploading thumbnail media:', error);
    throw error; // Rethrow the error to be handled by the calling function
  }
};

export const fetchImageAsBlob = async (url: string) => {
  try {
    const response = await fetch(url);

    // Verifica que la respuesta sea exitosa
    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    // Verifica que el tipo MIME de la respuesta sea una imagen
    const contentType = response.headers.get('content-type');
    if (!contentType || !contentType.startsWith('image/')) {
      throw new Error('URL does not point to an image.');
    }

    return await response.blob();
  } catch (error) {
    console.error('Error fetching image:', error);
    throw error;
  }
};


export const deleteMedia = async (storagePath: string) => {
  const storage = getStorage(firebaseApp);
  const storageRef = ref(storage, storagePath);
  return deleteObject(storageRef);
};

export const dateString = (date: Date) =>
  date.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

export const timeString = (date: Date) =>
  date.toLocaleTimeString('en-US', {
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
  });

export const getNextMonday9amMadrid = () => {
  const today = new Date();
  const daysUntilMonday = (8 - today.getDay()) % 7 || 7;
  const nextMonday = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + daysUntilMonday,
  );

  nextMonday.setHours(9, 0, 0, 0); // Set to 9am local time

  const localTime = new Date(nextMonday.getTime());
  const madridTime = new Date(
    localTime.toLocaleString('en-US', { timeZone: 'Europe/Madrid' }),
  );

  const hourDifference = Math.round(
    (localTime.getTime() - madridTime.getTime()) / (1000 * 60 * 60),
  );

  nextMonday.setHours(nextMonday.getHours() + hourDifference);
  return nextMonday;
};

export const onlyNumbersInput = (e: React.KeyboardEvent) => {
  if (!/[0-9]/.test(e.key)) {
    e.preventDefault();
  }
};

export const nameById = (items: { id: string; name: string }[], id: string) => {
  const item = items.find((item) => item.id === id);
  return item?.name;
};

export const getShellNameById = (shell: Shell[], shellId: string) => {
  const shellName = shell.find((shell) => shell._id === shellId);
  return shellName?.name || '';
};

export const getUserNameById = (users: User[], userId: string) => {
  const user = users.find((user) => user._id === userId);
  return getFullName(user!);
};

export const toTitleCase = (input: string): string => {
  if (!input) return input;

  return input
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const calculatePostsCountDifference = (currentCount: number, previousCount: number) => {
  if (currentCount !== undefined && previousCount !== undefined) {
    const difference = currentCount - previousCount;
    const percentage = ((difference / previousCount) * 100).toFixed(2);

    return {
      countDifference: difference,
      percentageDifference: percentage,
    };
  }

  return {
    countDifference: 0,
    percentageDifference: 0,
  };
};

export const formatPostsCountDifference = (
  currentCount: number,
  previousCount: number,
) => {
  const { countDifference, percentageDifference } = calculatePostsCountDifference(
    currentCount,
    previousCount,
  );

  if (!isFinite(Number(percentageDifference))) {
    return 'N/A';
  }

  const countDifferenceFixed = Number.isInteger(countDifference)
    ? countDifference
    : countDifference.toFixed(2);

  return `${countDifference > 0 ? '+' : ''
    }${countDifferenceFixed} (${percentageDifference}%)`;
};

export enum TYPES_OPTIONS {
  ALL_MEMBERS = 'All Members',
  COMPANIES = 'Companies',
  GROUPS = 'Groups',
}

export const formatNumber = (value: number) => {
  return value.toLocaleString('en-US', {
    maximumFractionDigits: 2,
  });
};

export const calculateScale = (value: number) => {
  const digitCount = Math.floor(Math.log10(value)) + 1;

  if (digitCount <= 2) {
    return value / 10;
  }
  if (digitCount === 3) {
    return value / 100;
  } else {
    const divisor = Math.pow(10, digitCount - 2);
    let scale = value / divisor;
    return scale;
  }
};

export function formatDateMonths(date: string) {
  const parts = date.split('-');
  const year = parts[0];
  const month = parts[1];
  const day = parts[2];

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const formattedDate = `${parseInt(day, 10)} ${months[parseInt(month, 10) - 1]} ${year}`;

  return formattedDate;
}

export function convertUnixTimeToDate(unixTime: number) {
  const date = new Date(unixTime);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

export function formatFullDate(dateISO: Date) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const date = new Date(dateISO);

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${month} ${day}, ${year} at ${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
}

export function formatFullDateShort(dateInput: Date) {
  const date = new Date(dateInput);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${day}/${month}/${year}`;
}

export function capitalize(text: string): string {
  if (text.length === 0) {
    return text; // Return the original string if it's empty
  }

  const firstLetterInUpperCase = text.charAt(0).toUpperCase();
  const restOfText = text.slice(1);

  return firstLetterInUpperCase + restOfText;
}

export const isValidContent = (file: File) => {
  const maxSizeInBytes = 200000; // 200KB
  return file.size <= maxSizeInBytes;
};

export function displayUserPlan(userPlan: UserPlan[]): string {
  const planMapping: { [key in UserPlan]?: string } = {
    [UserPlan.Advanced]: 'Opinion Leader',
    [UserPlan.Advocacy]: 'Employee Creator',
    [UserPlan.Corporate]: 'Corporate Brand on LinkedIn',
    [UserPlan.Clevel]: 'C-Level',
    [UserPlan.Inactive]: ' ',
  };

  return userPlan.map((p) => planMapping[p] || toTitleCase(p)).join(', ');
}

export function getCookie(name: string): string | null {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    const cookieValue = parts.pop();
    if (cookieValue !== undefined) {
      return cookieValue.split(';').shift() || null;
    }
  }
  return null;
}

export const convertToBold = (str: string) => {
  const boldOffsetLowercase = 0x1d5ee - 0x61; // Offset for lowercase Latin characters
  const boldOffsetUppercase = 0x1d5d4 - 0x41; // Offset for uppercase Latin characters

  // Add specific offsets for special characters
  const specialChars: Record<string, string> = {
    // Lowercase
    à: '𝗮̀',
    á: '𝗮́',
    â: '𝗮̂',
    ã: '𝗮̃',
    ä: '𝗮̈',
    å: '𝗮̊',
    æ: 'æ',
    ç: '𝗰̧',
    è: '𝗲̀',
    é: '𝗲́',
    ê: '𝗲̂',
    ë: '𝗲̈',
    ì: '𝗶̀',
    í: '𝗶́',
    î: '𝗶̂',
    ï: '𝗶̈',
    ð: 'ð',
    ñ: '𝗻̃',
    ò: '𝗼̀',
    ó: '𝗼́',
    ô: '𝗼̂',
    õ: '𝗼̃',
    ö: '𝗼̈',
    ø: 'ø',
    ù: '𝘂̀',
    ú: '𝘂́',
    û: '𝘂̂',
    ü: '𝘂̈',
    ý: '𝐲́',
    ÿ: '𝘆̈',

    // Uppercase
    À: '𝗔̀',
    Á: '𝗔́',
    Â: '𝗔̂',
    Ã: '𝗔̃',
    Ä: '𝗔̈',
    Å: '𝗔̊',
    Æ: 'Æ',
    Ç: '𝗖̧',
    È: '𝗘̀',
    É: '𝗘́',
    Ê: '𝗘̂',
    Ë: '𝗘̈',
    Ì: '𝗜̀',
    Í: '𝗜́',
    Î: '𝗜̂',
    Ï: '𝗜̈',
    Ð: 'Ð',
    Ñ: '𝗡̃',
    Ò: '𝗢̀',
    Ó: '𝗢́',
    Ô: '𝗢̂',
    Õ: '𝗢̃',
    Ö: '𝗢̈',
    Ø: 'Ø',
    Ù: '𝗨̀',
    Ú: '𝗨́',
    Û: '𝗨̂',
    Ü: '𝗨̈',
    Ý: '𝐘́',
  };

  const boldNumbers: Record<string, string> = {
    '0': '𝟬',
    '1': '𝟭',
    '2': '𝟮',
    '3': '𝟯',
    '4': '𝟰',
    '5': '𝟱',
    '6': '𝟲',
    '7': '𝟳',
    '8': '𝟴',
    '9': '𝟵',
  };

  let boldStr = '';

  for (let i = 0; i < str.length; i++) {
    const char = str[i];
    const code = char.codePointAt(0)!;

    // For lowercase a-z
    if (code >= 0x61 && code <= 0x7a) {
      boldStr += String.fromCodePoint(code + boldOffsetLowercase);
    }
    // For uppercase A-Z
    else if (code >= 0x41 && code <= 0x5a) {
      boldStr += String.fromCodePoint(code + boldOffsetUppercase);
    }
    // For numbers 0-9
    else if (boldNumbers[char]) {
      boldStr += boldNumbers[char];
    }
    // For special characters
    else if (specialChars[char]) {
      boldStr += specialChars[char];
    } else {
      boldStr += char;
    }
  }

  return boldStr;
};

export function formatDateToCustomFormat(dateString: string): string {
  const options: any = { year: 'numeric', month: 'long', day: 'numeric' };
  const date = new Date(dateString);
  return date.toLocaleDateString('es-ES', options);
}

export function getMimeType(url: string): string {
  const basePath = url.split('?')[0]; // Split the URL at the '?' and take the first part
  const extension = basePath.split('.').pop()?.toLowerCase();

  const mimeTypes: { [key: string]: string } = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    webp: 'image/webp',
    pdf: 'application/pdf',
    html: 'text/html',
    txt: 'text/plain',
    // Add more mappings as needed
  };

  return mimeTypes[extension || ''] || 'application/octet-stream';
}

export const past30Days = (dateString: string): boolean => {
  if (!dateString) {
    return false;
  }
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return false;
  }

  const today = new Date();
  const thirtyDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);

  return date >= thirtyDaysAgo;
};

export const getRandomHexColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const sliceText = (text: string, maxLength: number) => {
  if (text?.length > maxLength) {
    return text?.slice(0, maxLength) + '...';
  }
  return text;
}

export const cleanAndSliceText = (input: string, sliceLength?: number): string => {
  const cleanedText = input.replace(/<\/?[^>]+(>|$)/g, '');

  if (sliceLength !== undefined && cleanedText.length > sliceLength) {
    return cleanedText.slice(0, sliceLength) + '...';
  }

  return cleanedText;
}
