import { Delete, Edit, ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Chip,
  Collapse,
  IconButton,
  Paper,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import {
  OrderByDirection,
  OrderByField,
  Post,
  PostLifecycleState,
} from '../../__generated__/graphql';
import { OrderBy } from '../../types';
import { timeString } from '../../utils';
import { DiscardPost } from '../PostComponents/DiscardPost';

const HeaderCell = ({
  text,
  isOrderable = false,
  active = false,
  direction = OrderByDirection.Asc,
  onClick = () => {},
  sx = {},
  children,
}: {
  text?: string;
  isOrderable?: boolean;
  active?: boolean;
  direction?: OrderByDirection;
  onClick?: () => void;
  sx?: SxProps;
  children?: any;
}) => (
  <TableCell sx={{ color: 'primary.main', fontWeight: 'bold', ...sx }}>
    {isOrderable ? (
      <TableSortLabel
        active={active}
        direction={direction === OrderByDirection.Asc ? 'asc' : 'desc'}
        onClick={onClick}
        sx={{ color: 'primary.main', fontWeight: 'bold' }}
      >
        {children ? (
          children
        ) : (
          <Typography fontSize="14px" fontWeight="bold">
            {text}
          </Typography>
        )}
      </TableSortLabel>
    ) : (
      <Typography fontSize="14px" fontWeight="bold">
        {text}
      </Typography>
    )}
  </TableCell>
);

const Cell = ({
  text,
  sx,
  children,
  colSpan = 1,
  onClick = () => {},
}: {
  text?: string;
  sx?: SxProps;
  children?: React.ReactNode;
  colSpan?: number;
  onClick?: () => void;
}) => (
  <TableCell sx={sx} onClick={onClick} colSpan={colSpan}>
    {children || (
      <Typography fontSize="12px" fontWeight="bold">
        {text}
      </Typography>
    )}
  </TableCell>
);

const TableRowComponent = ({
  post,
  handleEditPost,
  handleDeletePost,
}: {
  post: Post;
  handleEditPost: (postId: string, userOrBrandId?: string) => void;
  handleDeletePost: (postId: string) => void;
}) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const printDate = (date: string, time: boolean = false) => {
    return new Date(date).toLocaleDateString('es-ES', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: time ? '2-digit' : undefined,
      minute: time ? '2-digit' : undefined,
      // 24 hour time
      hour12: false,
    });
  };

  const contentPreview = (post: Post) => {
    let preview = (
      post.content?.preview ||
      post.advocacy?.parentPost?.content?.preview ||
      ''
    )
      .replace(/<br>/g, '\n')
      .replace(/<[^>]*>/g, '');

    return preview.length > 50 ? preview.substring(0, 50) + '...' : preview;
  };

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <TableRow
        key={post._id}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
          borderLeft: '5px solid',
          borderColor: 'primary.main',
        }}
        data-testid="post-row"
      >
        <Cell
          sx={{ cursor: 'pointer' }}
          text={post.brandName || 'Unknown'}
          onClick={handleClick}
        />
        <Cell
          sx={{ cursor: 'pointer' }}
          text={(() => {
            let dateText = '';
            if (post.lifecycleState === PostLifecycleState.Posted) {
              dateText = `${printDate(post.postedAt)}, ${timeString(
                new Date(post.postedAt),
              )}`;
            } else if (post.schedule) {
              dateText = `${printDate(post.schedule)}, ${timeString(
                new Date(post.schedule),
              )}`;
            }
            return dateText;
          })()}
          onClick={handleClick}
        />
        <Cell
          sx={{ cursor: 'pointer' }}
          text={
            post.publishedOn ? printDate(post.publishedOn) : printDate(post.createdOn)
          }
          onClick={handleClick}
        />
        <Cell sx={{ cursor: 'pointer' }} onClick={handleClick}>
          <Chip
            size="small"
            sx={{ backgroundColor: post.lifecycleStateDisplay?.color }}
            label={post.lifecycleStateDisplay?.name}
          ></Chip>
          {post.isIdea && (
            <Chip
              size="small"
              sx={{
                background: '#FBBF253D',
              }}
              label="Idea"
            />
          )}
        </Cell>
        <Cell sx={{ cursor: 'pointer' }} onClick={handleClick}>
          <Stack direction="row" gap={1} flexWrap="wrap">
            {post.tags?.map((tag, index) => (
              <Tooltip title={tag?.name}>
                <Chip
                  key={index}
                  sx={{
                    backgroundColor: tag?.color,
                    borderRadius: '100px',
                    height: '8px',
                    width: '30px',
                  }}
                />
              </Tooltip>
            ))}
          </Stack>
        </Cell>
        <Cell
          sx={{ cursor: 'pointer' }}
          text={contentPreview(post)}
          onClick={handleClick}
        />
        <Cell sx={{ cursor: 'pointer' }} onClick={handleClick}>
          {post.unreadMessagesCount !== 0 && (
            <Typography
              sx={{
                width: 24,
                height: 24,
                borderRadius: '100%',
                bgcolor: theme.palette.primary.main,
                color: 'white',
                margin: 'auto',
                textAlign: 'center',
              }}
            >
              {post.unreadMessagesCount}
            </Typography>
          )}
        </Cell>
        <Cell>
          <Stack direction={'row'} justifyContent={'center'}>
            <IconButton
              onClick={() => {
                handleEditPost(post._id, post.brandId || undefined);
              }}
              data-testid="edit-post-button"
            >
              <Edit />
            </IconButton>
            <IconButton onClick={() => handleDeletePost(post._id)}>
              <Delete />
            </IconButton>
            <IconButton onClick={() => setOpen(!open)}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Stack>
        </Cell>
      </TableRow>
      <TableRow sx={{}}>
        <Cell
          colSpan={7}
          sx={{
            paddingBottom: 0,
            paddingTop: 0,
            maxWidth: '100px',
            ...(open ? {} : { border: 'none' }),
          }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit sx={{}}>
            <Box py={2}>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    post?.content?.body ||
                    post?.advocacy?.parentPost?.content?.body ||
                    '',
                }}
              />
            </Box>
          </Collapse>
        </Cell>
      </TableRow>
    </>
  );
};

interface Props {
  posts: Post[];
  page?: number;
  limit?: number;
  setLimit?: (limit: number) => void;
  orderBy?: OrderBy;
  setOrderBy?: (orderBy: OrderBy) => void;
  onPageChange?: (page: number) => void;
  total?: number;
  handleEditPost?: (postId: string, userOrBrandId?: string) => void;
}

const PostsTeamTable = ({
  posts,
  page = 0,
  limit = 15,
  setLimit = () => {},
  orderBy = { field: OrderByField.AllDates, direction: OrderByDirection.Desc },
  setOrderBy = () => {},
  onPageChange = () => {},
  total = -1,
  handleEditPost = () => {},
}: Props) => {
  const [deletePostId, setDeletePostId] = useState<string | null>(null);

  const onOrderByChange = (field: OrderByField) => {
    setOrderBy({
      field,
      direction:
        orderBy?.field === field && orderBy.direction === OrderByDirection.Desc
          ? OrderByDirection.Asc
          : OrderByDirection.Desc,
    });
  };

  return (
    <>
      {deletePostId && (
        <DiscardPost postId={deletePostId} onCloseModal={() => setDeletePostId(null)} />
      )}
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: '20px',
          height: '100%',
          paddingBottom: { xs: 3, sm: 0 },
        }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <HeaderCell
                text="Brand"
                isOrderable
                active={orderBy?.field === OrderByField.UserFullName}
                direction={orderBy?.direction}
                onClick={() => onOrderByChange(OrderByField.UserFullName)}
              />
              <HeaderCell
                children="Date"
                isOrderable
                active={orderBy.field === OrderByField.AllDates}
                direction={orderBy.direction}
                onClick={() => onOrderByChange(OrderByField.AllDates)}
              />
              <HeaderCell
                children="Created on"
                isOrderable
                active={orderBy.field === OrderByField.CreatedOn}
                direction={orderBy.direction}
                onClick={() => onOrderByChange(OrderByField.CreatedOn)}
              />
              <HeaderCell
                text="Status"
                isOrderable
                active={orderBy?.field === OrderByField.LifecycleState}
                direction={orderBy?.direction}
                onClick={() => onOrderByChange(OrderByField.LifecycleState)}
                sx={{ maxWidth: '140px' }}
              />
              <HeaderCell text="Label" />
              <HeaderCell text="Post" />
              <HeaderCell
                text="Messages"
                isOrderable
                active={orderBy?.field === OrderByField.UnreadMessagesCount}
                direction={orderBy?.direction}
                onClick={() => onOrderByChange(OrderByField.UnreadMessagesCount)}
                sx={{ textAlign: 'center' }}
              />
              <HeaderCell text="Actions" sx={{ textAlign: 'center' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {posts.map((post) => (
              <TableRowComponent
                key={post._id}
                post={post}
                handleEditPost={(postId, userOrBrandId) =>
                  handleEditPost(postId, userOrBrandId)
                }
                handleDeletePost={(postId) => setDeletePostId(postId)}
              />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={total}
                rowsPerPageOptions={[15, 25, 50]}
                rowsPerPage={limit}
                page={page}
                onPageChange={(_, page) => onPageChange(page)}
                onRowsPerPageChange={(event) => {
                  onPageChange(0);
                  setLimit(+event.target.value);
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default PostsTeamTable;
