import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { BrandInput, goalsBrandOptions } from './BrandData';

interface BrandCorporateInsightsFormProps {
  control: Control<BrandInput>;
  inputStyle: any;
  errors: FieldErrors<BrandInput>;
  watchGoalsBrand: string[] | undefined;
}

const BrandCorporateInsightsForm = ({
  control,
  inputStyle,
  errors,
  watchGoalsBrand,
}: BrandCorporateInsightsFormProps) => {
  return (
    <Box display="flex" flexDirection="column" gap={1} fontSize="13px">
      <Typography fontSize="inherit">
        What topics would like your company talk about?
      </Typography>
      <Controller
        name="corporateIdeas"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField
            required
            value={value}
            onChange={onChange}
            sx={inputStyle}
            error={!!errors.competitors}
            helperText={errors.competitors?.message}
          />
        )}
      />

      <Typography fontSize="inherit">What is your company's target audience?</Typography>
      <Controller
        name="corporateTarget"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField
            required
            multiline
            rows={3}
            value={value}
            onChange={onChange}
            sx={inputStyle}
            error={!!errors.competitors}
            helperText={errors.competitors?.message}
          />
        )}
      />
      
      <Typography fontSize="inherit">
        What are your objectives in developing personal brands within your company?
      </Typography>
      <Controller
        name="goalsBrand"
        control={control}
        render={({ field: { value, onChange } }) => (
          <FormControl error={!!errors.targetBrand} sx={inputStyle}>
            <Select
              required
              value={value || ''}
              onChange={onChange}
              sx={inputStyle}
              multiple
              error={!!errors.targetBrand}
            >
              {goalsBrandOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.targetBrand?.message}</FormHelperText>
          </FormControl>
        )}
      />
      {watchGoalsBrand?.includes('Other') && (
        <Controller
          name="otherGoalsBrand"
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextField
              required
              value={value}
              // disabled={!watchGoalsBrand?.includes('Other')}
              onChange={onChange}
              sx={inputStyle}
              placeholder="Other (please specify)"
            />
          )}
        />
      )}

      <Typography fontSize="inherit">
        Are there specific elements, values, or emotions that you aim to evoke through how
        your company communicates?
      </Typography>
      <Controller
        name="corporateValues"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField
            required
            multiline
            rows={3}
            value={value}
            onChange={onChange}
            sx={inputStyle}
            error={!!errors.competitors}
            helperText={errors.competitors?.message}
          />
        )}
      />
    </Box>
  );
};

export default BrandCorporateInsightsForm;
