import {
  Alert,
  Button,
  Container,
  Grid,
  Snackbar,
  Stack,
  Typography,
  Zoom,
} from '@mui/material';
import { getAuth, confirmPasswordReset } from 'firebase/auth';
import { useContext, useEffect, useState } from 'react';
import { FormContainer, PasswordElement } from 'react-hook-form-mui';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

const ResetPassword = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const auth = getAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const oobCode = new URLSearchParams(location.search).get('oobCode');
  const { user, loading } = useContext(AuthContext);

  useEffect(() => {
    if (!loading && user) {
      navigate('/');
      return;
    }

    if (!loading && !oobCode) navigate('/login');
  }, [loading, navigate, oobCode, user]);

  useEffect(() => {}, [loading, navigate, user]);

  const handleSubmit = async ({ password }: { password: string }) => {
    if (!oobCode) {
      setErrorMsg('Error: No code provided.');
      return;
    }

    confirmPasswordReset(auth, oobCode, password)
      .then(() => {
        navigate('/login');
      })
      .catch((error) => {
        setErrorMsg(error.message);
      });
  };

  const onCloseSnackbar = () => {
    setErrorMsg('');
  };

  return (
    <Container maxWidth="md" sx={{ marginY: '25px' }}>
      <img src="/images/magnettu_logo.svg" alt="magnettu logo" width={145} />
      <FormContainer onSuccess={handleSubmit}>
        <Grid container justifyContent="center">
          <Grid item>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{ marginTop: '50px', maxWidth: '350px' }}
            >
              <Typography variant="h4" sx={{ fontWeight: 700, fontSize: '32px' }}>
                Reset Password
              </Typography>
              <PasswordElement
                label="New Password"
                name="password"
                autoComplete="new-password"
                required
                sx={{ minWidth: '100%' }}
                data-cy="password-input"
                validation={{
                  required: 'Password is required',
                  minLength: {
                    value: 6,
                    message: 'Password must have at least 6 characters',
                  },
                }}
              />
              <Button
                variant="contained"
                type="submit"
                sx={{ minWidth: '100%' }}
                data-cy="reset-password-button"
              >
                <Typography fontWeight="600">Reset Password</Typography>
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </FormContainer>
      <Snackbar
        open={!!errorMsg}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        TransitionComponent={Zoom}
        onClose={onCloseSnackbar}
        data-cy="error-snackbar"
      >
        <Alert severity="error" sx={{ width: '100%' }} onClose={onCloseSnackbar}>
          {errorMsg}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ResetPassword;
