import { AutoAwesome, EditOutlined, PlaylistAdd, ReadMore } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { Editor } from '@tiptap/react';
import { useContext, useRef, useState } from 'react';
import { DeviceContext } from '../../context/DeviceContext';
import { PostContext } from '../../context/PostContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { useStreamedCompletion } from '../../hooks/useStreamedCompletion';
import AIAssistantModal from './AIAssistantModal';
import { AuthContext } from '../../context/AuthContext';
import { Tag } from '../../__generated__/graphql';

export const AIAssistantActions: Tag[] = [
  {
    name: 'GENERATE_CONTENT',
    color: '#FFFFFF',
    internal: true,
  },
  {
    name: 'COMPLETE_CONTENT',
    color: '#FFFFFF',
    internal: true,
  },
  {
    name: 'REWRITE_CONTENT',
    color: '#FFFFFF',
    internal: true,
  },
  {
    name: 'ASK_MAG',
    color: '#FFFFFF',
    internal: true,
  },
];

interface Props {
  postId?: string;
  editor: Editor;
  onLoading: (loading: boolean) => void;
  onChange: (content: string, json: any, action?: Tag) => void;
}

const AIAssistantMenu = ({ postId, editor, onChange, onLoading = () => {} }: Props) => {
  const { isMobile } = useContext(DeviceContext);
  const { setErrorMessage } = useContext(SnackbarContext);
  const { postState } = useContext(PostContext);
  const { user } = useContext(AuthContext);

  const [aiAssistOpen, setAiAssistOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [anchorElAiMenu, setAnchorElAiMenu] = useState<HTMLButtonElement | null>(null);

  const cursorPositionRef = useRef<number>(editor.state.selection.anchor);

  const menuOpen = Boolean(anchorElAiMenu);
  const content = editor.getText({ blockSeparator: '\n' });

  const [fetchDataCompleteContent] = useStreamedCompletion(
    (content) => {
      if (content) {
        editor.commands.insertContentAt(
          cursorPositionRef.current,
          content.replaceAll('\n', '<br>') || '',
        );
        onChange(editor.getHTML(), editor.getJSON(), AIAssistantActions[1]);
        cursorPositionRef.current = editor.state.selection.anchor;
      }
    },
    (error) => {
      setErrorMessage(error);
      setLoading(false);
    },
    () => {
      onLoading(false);
      setLoading(false);
    },
  );

  const [fetchDataRewriteContent] = useStreamedCompletion(
    (content) => {
      if (content) {
        editor.commands.insertContentAt(
          cursorPositionRef.current,
          content.replaceAll('\n', '<br>') || '',
        );
        onChange(editor.getHTML(), editor.getJSON(), AIAssistantActions[2]);
        cursorPositionRef.current = editor.state.selection.anchor;
      }
    },
    (error) => {
      setErrorMessage(error);
      setLoading(false);
    },
    () => {
      onLoading(false);
      setLoading(false);
    },
  );

  const handleCompleteContent = async () => {
    onLoading(true);
    setLoading(true);
    editor.commands.focus('end');
    cursorPositionRef.current = editor.state.selection.anchor;
    fetchDataCompleteContent({
      input: content,
      chainId: '66310b7bf1de1c64651db3a1',
      brandId: postState?.post.brandId || undefined,
      userId: user?._id || undefined,
    });
  };

  const handleRewriteContent = async () => {
    setLoading(true);
    onLoading(true);

    cursorPositionRef.current = 0;
    editor.commands.setContent('');
    fetchDataRewriteContent({
      input: content,
      brandId: postState?.post.brandId || undefined,
      chainId: '66310b98f1de1c64651db3a3',
      userId: user?._id || undefined,
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    editor.setOptions({
      editable: false,
    });

    setAnchorElAiMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElAiMenu(null);

    editor.setOptions({
      editable: true,
    });
  };

  const text = {
    fontSize: '14px',
  };

  return (
    <>
      <AIAssistantModal
        open={aiAssistOpen}
        onClose={() => setAiAssistOpen(false)}
        onAddText={(content) => {
          editor.commands.insertContent(
            '<p>' + content.replaceAll('\n', '<br>') + '</p>',
          );
          editor.setEditable(true);
          onChange(
            editor.getHTML(),
            editor.getJSON(),
            // AIAssistantActions.GENERATE_CONTENT,
            AIAssistantActions[0],

          );
        }}
      />
      {isMobile ? (
        <IconButton
          aria-controls={menuOpen ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? 'true' : undefined}
          onClick={handleClick}
        >
          <AutoAwesome />
        </IconButton>
      ) : (
        <LoadingButton
          variant="outlined"
          sx={{ backgroundColor: 'white', ':hover': { backgroundColor: 'white' } }}
          startIcon={<AutoAwesome />}
          aria-controls={menuOpen ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? 'true' : undefined}
          onClick={handleClick}
          loadingPosition="start"
          loading={loading}
        >
          Use AI
        </LoadingButton>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorElAiMenu}
        open={menuOpen}
        onClose={handleClose}
        elevation={1}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            setAiAssistOpen(true);
            handleClose();
          }}
          disableRipple
        >
          <ListItemIcon>
            <PlaylistAdd fontSize="small" sx={{ mr: 1 }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }}>
            Generate content
          </ListItemText>
        </MenuItem>
        <MenuItem
          disabled={content.length === 0}
          onClick={() => {
            handleCompleteContent();
            handleClose();
          }}
          disableRipple
        >
          <ListItemIcon>
            <ReadMore fontSize="small" sx={{ mr: 1 }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }}>
            Complete content
          </ListItemText>
        </MenuItem>
        <MenuItem
          disabled={content.length === 0}
          onClick={() => {
            handleRewriteContent();
            handleClose();
          }}
          disableRipple
        >
          <ListItemIcon>
            <EditOutlined fontSize="small" sx={{ mr: 1 }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }}>
            Rewrite content
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default AIAssistantMenu;
