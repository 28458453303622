// import * as React from 'react';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { PerformingContent, Tag } from '../../__generated__/graphql';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  TextField,
  IconButton,
  Stack,
  Chip,
  Typography,
  Menu,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { formatNumber } from '../../utils';
import { Add, Close, MoreHoriz } from '@mui/icons-material';
import TagModal from '../PostComponents/TagModal';
import { UPDATE_TAGS } from '../../graphql/mutations';
import { useMutation } from '@apollo/client';
import { SnackbarContext } from '../../context/SnackbarContext';

interface Data {
  post: string;
  date: string;
  link: string;
  profile: string;
  interactions: number;
  reactions: number;
  comments: number;
  shares: number;
  followers: number;
  numViews: number;
  engagementRate: number;
  tags: Tag[];
  postId: string;
  shellId: string;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof Data>(
  order: Order,
  orderBy: Key,
): (a: Data, b: Data) => number {
  return (a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    const isNumericValue = typeof aValue === 'number' && typeof bValue === 'number';
    const isArrayOfTags = Array.isArray(aValue) && Array.isArray(bValue);

    if(isArrayOfTags){
      return order === 'desc' ? bValue.length - aValue.length : aValue.length - bValue.length;
    }


     if (aValue === 'N/A' && bValue === 'N/A') {
      return 0;
     }

    if (aValue === 'N/A') {
      return 1;
    }

    if (bValue === 'N/A') {
      return -1;
    }

    if (isNumericValue) {
      return order === 'desc' ? bValue - aValue : aValue - bValue;
    }

    // @ts-ignore
    return order === 'desc' ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
  };
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const hasNA = (value: any) => value === 'N/A';


  const sortedArray = [...array];
  sortedArray.sort((a, b) => {
    const aIsNA = hasNA(a);
    const bIsNA = hasNA(b);

    if (aIsNA && bIsNA) {
      return 0;
    }

    if (aIsNA) {
      return 1;
    }

    if (bIsNA) {
      return -1;
    }

    return comparator(a, b);
  });

  return sortedArray;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  hide?: boolean;
}

interface AnalitysTableProps {
  data: PerformingContent[] | [];
  dateFilter: (dateInit: string, dateEnd: string) => void;
  loadingButton: boolean;
  userOrShell: 'user' | 'shell';
  reactionsRange?: number;
  commentsRange?: number;
  sharesRange?: number;
  impressionsRange?: number;
  isBrandCompany?: boolean;
  dateInit?: Date;
  dateEnd?: Date;
  updateMetricsBrandWithTags: (tags: Tag[], postId: string) => void;
}

const PerformingContentTable: FC<AnalitysTableProps> = ({
  data,
  dateFilter,
  loadingButton,
  userOrShell,
  reactionsRange = 0,
  commentsRange = 0,
  sharesRange = 0,
  impressionsRange = 0,
  isBrandCompany = false,
  dateInit = new Date(),
  dateEnd = new Date(),
  updateMetricsBrandWithTags,
}) => {
  const headCells: readonly HeadCell[] = [
    {
      id: 'post',
      numeric: false,
      disablePadding: true,
      label: 'Post',
    },
    {
      id: 'tags',
      numeric: false,
      disablePadding: true,
      label: 'Labels',
    },
    {
      id: 'date',
      numeric: true,
      disablePadding: false,
      label: 'Date',
    },
    {
      id: 'profile',
      numeric: true,
      disablePadding: false,
      label: 'Profile',
      hide: userOrShell === 'user',
    },
    {
      id: 'numViews',
      numeric: true,
      disablePadding: false,
      label: 'Impressions',
    },
    {
      id: 'interactions',
      numeric: true,
      disablePadding: false,
      label: 'Interactions',
    },
    {
      id: 'reactions',
      numeric: true,
      disablePadding: false,
      label: 'Reactions',
    },
    {
      id: 'comments',
      numeric: true,
      disablePadding: false,
      label: 'Comments',
    },
    {
      id: 'shares',
      numeric: true,
      disablePadding: false,
      label: 'Shares',
    },
    {
      id: 'followers',
      numeric: true,
      disablePadding: false,
      label: 'Followers',
      hide: isBrandCompany,
    },
    {
      id: 'engagementRate',
      numeric: true,
      disablePadding: false,
      label: 'Engagement Rate',
    },
  ];

  interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
  }

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell, index) => (
            <TableCell
              key={index}
              align="left"
              sortDirection={orderBy === headCell.id ? order : false}
              style={{
                fontWeight: 'bold',
                backgroundColor: '#FFE4F1',
                display: headCell.hide ? 'none' : 'table-cell',
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');
  const [openTagModal, setOpenTagModal] = useState(false);
  const [postSelected, setPostSelected] = useState<Data | null>(null);
  const [updateTags] = useMutation(UPDATE_TAGS);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement | SVGSVGElement>(null);
  const {setSuccessMessage, setErrorMessage} = useContext(SnackbarContext);


  let rows: Data[] | any = data.map((item: PerformingContent) => {
    const interactions =
      item.metrics!!.numComments!! +
      item.metrics!!.numLikes!! +
      item.metrics!!.totalShares!!;
    const engagementRate = (interactions / item.metrics!!.numViews!!) * 100;
    return {
      comments: item.metrics!!.numComments ?? 'N/A',
      date: item.postedAt,
      followers: item.metrics!!.followers ?? 'N/A',
      interactions: item.metrics!!.numComments!! !== null ? interactions : 'N/A',
      numViews: item.metrics!!.numViews ?? 'N/A',
      link: `https://www.linkedin.com/feed/update/${item.postUrn}`,
      engagementRate:
        engagementRate || engagementRate === 0
          ? Number(engagementRate.toFixed(2))
          : 'N/A',
      post: item.content!!.slice(0, 80 - 3) + '...',
      profile: item.brandName,
      reactions: item.metrics!!.numLikes ?? 'N/A',
      shares: item.metrics!!.totalShares ?? 'N/A',
      tags: item.tags,
      postId: item.postId,
    };
  });

  const [filteredRows, setFilteredRows] = useState<Data[]>(rows);

  const formatDate = (date: string) => {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof Data>('interactions');

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = useMemo(
    () =>
      stableSort(filteredRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, filteredRows],
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const defaultDateInit = dateInit.toISOString().substring(0, 10);
  const defaultDateEnd = dateEnd.toISOString().substring(0, 10);

  const filterData = useCallback(
    (searchTerm: string) => {
      const filteredData = rows.filter(
        (row: Data) =>
          row.profile && row.profile.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setFilteredRows(filteredData);
    },
    [rows],
  );

  useEffect(() => {
    filterData(searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, data]);

  const { register, control, watch } = useForm({
    defaultValues: {
      dateInit: defaultDateInit,
      dateEnd: defaultDateEnd,
    },
  });

  const totalStaticsPosts = rows.reduce(
    (totals: any, post: any) => {
      totals.totalComments += post.comments !== 'N/A' ? post.comments : 0;
      totals.totalReactions += post.reactions !== 'N/A' ? post.reactions : 0;
      totals.totalShares += post.shares !== 'N/A' ? post.shares : 0;
      totals.totalViews += post.numViews !== 'N/A' ? post.numViews : 0;
      return totals;
    },
    {
      totalComments: 0,
      totalReactions: 0,
      totalShares: 0,
      totalViews: 0,
    },
  );

  const handleAddTags = (tags: Tag[]) => {
    const oldTags = postSelected?.tags || [];

    const newTags = tags.map((tag) => ({
      _id: tag._id,
      name: tag.name,
      color: tag.color,
      internal: tag.internal || false,
    }));

    setFilteredRows(
      filteredRows.map((row: Data) => {
        if (row.postId === postSelected?.postId) {
          return {
            ...row,
            tags: newTags,
          };
        }
        return row;
      }),
    );

    updateMetricsBrandWithTags(newTags, postSelected?.postId as string);

    updateTags({
      variables: {
        postId: postSelected?.postId,
        tags: newTags, // Usamos newTags directamente
      },
      onCompleted: (data) => {
        if (!data.updateTags.success) {
          setFilteredRows(
            filteredRows.map((row: Data) => {
              if (row.postId === postSelected?.postId) {
                return {
                  ...row,
                  tags: oldTags,
                };
              }
              return row;
            }),
          );
          updateMetricsBrandWithTags(oldTags, postSelected?.postId as string);
          return setErrorMessage('Error adding tags');
        }
        setSuccessMessage('Tag added successfully');
      },
    });
  };

  const handleRemoveTag = (tag: Tag, row: Data) => {
    const oldTags = row?.tags;

    const tags = row?.tags
      ?.filter((t) => t?._id !== tag._id)
      .map((tag) => ({
        _id: tag?._id,
        name: tag?.name,
        color: tag?.color,
        internal: tag?.internal || false,
      }));

    updateMetricsBrandWithTags(tags, row.postId);

    setFilteredRows(
      filteredRows.map((r: Data) => {
        if (r.postId === (postSelected?.postId || row.postId)) {
          return {
            ...r,
            tags: tags,
          };
        }
        return r;
      }),
    );

    updateTags({
      variables: {
        postId: postSelected?.postId || row.postId,
        tags: tags,
      },
      onCompleted: (data) => {
        if (!data.updateTags.success) {
          updateMetricsBrandWithTags(oldTags, row.postId);
          setFilteredRows(
            filteredRows.map((r: Data) => {
              if (r.postId === postSelected?.postId) {
                return {
                  ...r,
                  tags: oldTags,
                };
              }
              return r;
            }),
          );
          return setErrorMessage('Error removing tag');
        }
        setSuccessMessage('Tag removed successfully');
      },
    });

    if (tags.length === 0) {
      setAnchorEl(null);
    }

    return;
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement> | React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        sx={{
          transform: 'translate(25px, 10px)',
          '& .MuiPaper-root': {
            borderRadius: '8px',
          },
        }}
        elevation={10}
        MenuListProps={{
          sx: {
            height: 'auto',
            maxHeight: 410,
            width: 220,
            minHeight: 200,
            paddingLeft: 2,
            // paddingBottom: 1,
          },
        }}
      >
        <Typography
          sx={{
            // paddingLeft: '20px',
            color: 'rgba(194, 11, 99, 1)',
            marginY: '0.5rem',
            fontSize: '14px',
            fontWeight: '700',
          }}
        >
          All labels
        </Typography>
        <Stack
          flexDirection={'column'}
          gap={1}
          sx={{
            paddingRight: 1,
            paddingBottom: 1,
          }}
        >
          {filteredRows
            ?.find((row) => row.postId === postSelected?.postId)
            ?.tags.map((tag, i) => (
              <Box
                key={i}
                className={`tag-chip-${i}`}
                sx={{
                  background: 'transparent',
                  border: `0.5px solid ${tag?.color}`,
                  color: tag?.color,
                  borderRadius: '4px',
                  marginBottom: 0.5,
                  padding: '0 5px',
                  height: '24px',
                  width: 'auto',
                  // maxWidth: '200px',
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  // width="100%"
                  // width="auto"
                >
                  <Typography
                    fontSize="13px"
                    sx={{
                      flexGrow: 1,
                      textAlign: 'center',
                    }}
                  >
                    {tag?.name}
                  </Typography>
                  {/* {handleRemoveTag && ( */}
                  <IconButton
                    sx={{
                      borderRadius: '50%',
                      background: '#bdbdbd',
                      color: '#fff',
                      padding: 0.1,
                      // marginLeft: 1,
                      // marginRight: -0.8,
                    }}
                    onClick={() => {
                      // setPostSelected(row);
                      handleRemoveTag(
                        tag,
                        filteredRows.find(
                          (row) => row.postId === postSelected?.postId,
                        ) as Data,
                      );
                    }}
                  >
                    <Close
                      sx={{
                        fontSize: '13px',
                      }}
                    />
                  </IconButton>
                </Stack>
              </Box>
            ))}
        </Stack>
      </Menu>
      {postSelected && (
        <TagModal
          openTagModal={openTagModal}
          setOpenTagModal={setOpenTagModal}
          tags={postSelected?.tags as Tag[]}
          handleAddTags={handleAddTags}
          key={postSelected?.tags.length}
        />
      )}
      <Box sx={{ width: '100%', marginTop: '2rem' }}>
        <Box display="flex" gap={3} justifyContent="end" marginBottom="2rem">
          {userOrShell === 'shell' && (
            <TextField
              size="small"
              label="Search brand"
              variant="standard"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ marginBottom: '1rem' }}
            />
          )}

          <Controller
            name={'dateInit'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                size="small"
                label="From"
                type="date"
                variant="standard"
                sx={{
                  '& label': {
                    marginTop: value ? '0px' : '-20px',
                  },
                  '& label.Mui-focused': {
                    marginTop: '0px',
                  },
                  '& input': {
                    pl: '0!important',
                  },
                }}
                {...register('dateInit')}
              />
            )}
          />

          <Controller
            name={'dateEnd'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                size="small"
                label="To"
                type="date"
                variant="standard"
                sx={{
                  '& label': {
                    marginTop: value ? '0px' : '-20px',
                  },
                  '& label.Mui-focused': {
                    marginTop: '0px',
                  },
                  '& input': {
                    pl: '0!important',
                  },
                }}
                {...register('dateEnd')}
              />
            )}
          />

          <LoadingButton
            variant="contained"
            disabled={watch('dateInit') === '' || watch('dateEnd') === ''}
            onClick={() => dateFilter(watch('dateInit'), watch('dateEnd'))}
            loading={loadingButton}
          >
            Filter
          </LoadingButton>
        </Box>

        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {isBrandCompany && !loadingButton && !page && (
                  <TableRow hover>
                    <TableCell component="th" scope="row">
                      <b>
                        Statistics of older posts and posts published from outside the
                        application
                      </b>
                    </TableCell>
                    <TableCell >N/A</TableCell>
                    <TableCell >
                      {impressionsRange - totalStaticsPosts.totalViews}
                    </TableCell>
                    <TableCell >
                      {commentsRange +
                        reactionsRange +
                        sharesRange -
                        (totalStaticsPosts.totalComments +
                          totalStaticsPosts.totalReactions +
                          totalStaticsPosts.totalShares)}
                    </TableCell>
                    <TableCell >
                      {reactionsRange - totalStaticsPosts.totalReactions}
                    </TableCell>
                    <TableCell >
                      {commentsRange - totalStaticsPosts.totalComments}
                    </TableCell>
                    <TableCell >
                      {sharesRange - totalStaticsPosts.totalShares}
                    </TableCell>
                    <TableCell >
                      {(
                        ((commentsRange +
                          reactionsRange +
                          sharesRange -
                          (totalStaticsPosts.totalComments +
                            totalStaticsPosts.totalReactions +
                            totalStaticsPosts.totalShares)) /
                          (impressionsRange - totalStaticsPosts.totalViews) || 0) * 100
                      ).toFixed(2) + '%'}
                    </TableCell>
                  </TableRow>
                )}

                {visibleRows.map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell component="th" scope="row">
                        <a
                          href={row.link as string}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: 'none',
                            color: '#CD0061',
                          }}
                        >
                          <Box dangerouslySetInnerHTML={{ __html: row.post }} />
                        </a>
                      </TableCell>
                      <TableCell>
                        <Stack flexDirection="row" gap={0.5}>
                        <Chip
                            sx={{
                              background: 'transparent',
                              border: `0.5px solid #bdbdbd`,
                              color: '#bdbdbd',
                              borderRadius: '4px',
                              marginBottom: 0.5,
                              height: '24px',
                              width: '30px',
                            }}
                            label={
                              <Add
                                sx={{
                                  marginTop: '4px',
                                  marginLeft: '-10px',
                                }}
                              />
                            }
                            onClick={() => {
                              setPostSelected(row);
                              setOpenTagModal(true);
                            }}
                          />
                          {!!row.tags.length &&
                            row.tags.slice(0, 2).map((tag, i) => (
                              <Chip
                                key={i}
                                className={`tag-chip-${i}`}
                                label={
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    width="100%"
                                  >
                                    <Typography
                                      fontSize="13px"
                                      sx={{
                                        flexGrow: 1,
                                        textAlign: 'center',
                                      }}
                                    >
                                      {tag?.name}
                                    </Typography>
                                    {/* {handleRemoveTag && ( */}
                                    <IconButton
                                      sx={{
                                        borderRadius: '50%',
                                        background: '#bdbdbd',
                                        color: '#fff',
                                        padding: 0.1,
                                        marginLeft: 1,
                                        marginRight: -0.8,
                                      }}
                                      onClick={() => {
                                        // setPostSelected(row);
                                        handleRemoveTag(tag, row);
                                      }}
                                    >
                                      <Close
                                        sx={{
                                          fontSize: '13px',
                                        }}
                                      />
                                    </IconButton>
                                  </Stack>
                                }
                                sx={{
                                  background: 'transparent',
                                  border: `0.5px solid ${tag?.color}`,
                                  color: tag?.color,
                                  borderRadius: '4px',
                                  marginBottom: 0.5,
                                  padding: '0',
                                  height: '24px',
                                }}
                              />
                            ))}

                          {(row.tags.length > 2 || anchorEl?.id === `more-${index}`) && (
                            <MoreHoriz
                              id={`more-${index}`}
                              onClick={(e) => {
                                setPostSelected(row);
                                handleClick(e);
                              }}
                              sx={{
                                color: '#bdbdbd',
                                cursor: 'pointer',
                                marginLeft: 1,
                              }}
                            />
                          )}

                        </Stack>
                      </TableCell>
                      <TableCell >
                        {formatDate(row.date as string)}
                      </TableCell>
                      {userOrShell === 'shell' && (
                        <TableCell >{row.profile}</TableCell>
                      )}
                      <TableCell >{formatNumber(row.numViews)}</TableCell>
                      <TableCell >{row.interactions}</TableCell>
                      <TableCell >{row.reactions}</TableCell>
                      <TableCell >{row.comments}</TableCell>
                      <TableCell >{row.shares}</TableCell>
                      {!isBrandCompany && (
                        <TableCell >
                          {formatNumber(row.followers)}
                        </TableCell>
                      )}
                      <TableCell >
                        {typeof row.engagementRate === 'number' &&
                        isFinite(row.engagementRate)
                          ? `${row.engagementRate.toFixed(2) || 0} %`
                          : row.engagementRate === Infinity
                          ? 'N/A'
                          : row.engagementRate}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
};

export default PerformingContentTable;
