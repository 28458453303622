import {
  ChevronLeft,
  ChevronRight,
  Close,
  Delete,
  Edit,
  Menu,
} from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  CardMedia,
  CircularProgress,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@mui/material';
import { useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { Maybe, PostContentMedia } from '../../__generated__/graphql';

interface FullDisplayMediaProps {
  openModal: boolean;
  handleCloseModalFullScreen: () => void;
  setOpenModalTitle: React.Dispatch<React.SetStateAction<boolean>>;
  isPdf: boolean | undefined;
  isMobile: boolean;
  isImage: boolean | undefined;
  isVideo: boolean | undefined;
  handleDelete: () => void;
  selectedImage: PostContentMedia | null;
  mediaPdf: PostContentMedia[] | undefined;
  postMedia: Maybe<PostContentMedia[]> | undefined;
  numPages: number;
  setNumPages: React.Dispatch<React.SetStateAction<number>>;
}

const FullDisplayMediaDialog: React.FC<FullDisplayMediaProps> = ({
  openModal,
  isMobile,
  handleCloseModalFullScreen,
  setOpenModalTitle,
  handleDelete,
  isImage,
  selectedImage,
  isPdf,
  mediaPdf,
  postMedia,
  numPages,
  setNumPages,
  isVideo,
}) => {
  const [open, setOpen] = useState(false);
  const [pagePdf, setPagePdf] = useState(0);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleCloseModal = () => {
    setOpen(false);
    handleCloseModalFullScreen();
    setPagePdf(0);
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleCloseModal}
      maxWidth="lg"
      fullScreen
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
      }}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          overflow: 'hidden',
        },
      }}
    >
      <DialogActions
        sx={{
          flexDirection: 'row',
          justifyContent: 'end',
          alignSelf: 'end',
          gap: 2,
        }}
      >
        {isMobile && (
          <>
            <ButtonGroup ref={anchorRef}>
              <IconButton
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                <Menu
                  sx={{
                    color: 'white',
                  }}
                />
              </IconButton>
            </ButtonGroup>

            <Popper
              sx={{
                zIndex: 999,
              }}
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener
                      onClickAway={() => {
                        setOpen(false);
                      }}
                    >
                      <MenuList id="split-button-menu" autoFocusItem>
                        {!isImage && (
                          <MenuItem
                            sx={{
                              gap: '10px',
                            }}
                            onClick={() => {
                              setOpenModalTitle(true);
                            }}
                          >
                            <Edit />
                            Edit title
                          </MenuItem>
                        )}

                        <MenuItem
                          sx={{
                            gap: '10px',
                          }}
                          onClick={() => {
                            handleDelete();
                            handleCloseModal();
                          }}
                        >
                          <Delete />
                          Delete
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </>
        )}

        <IconButton
          edge="end"
          onClick={handleCloseModal}
          aria-label="close"
          sx={{
            marginRight: { xs: '0', sm: '1rem' },
          }}
        >
          <Close
            sx={{
              color: 'white',
            }}
          />
        </IconButton>
      </DialogActions>
      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 0,
          overflow: 'hidden',
        }}
      >
        {isImage && (
          <img
            src={selectedImage?.url || ''}
            alt="Selected"
            style={{
              width: '100%',
              objectFit: 'contain',
              maxWidth: '80%',
              maxHeight: '100%',
            }}
          />
        )}

        {isPdf && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 0,
              width: '100%',
            }}
          >
            <Document
              file={!!mediaPdf ? mediaPdf[0]?.url : postMedia![0]?.url}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              loading={<CircularProgress />}
            >
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                gap={1}
                sx={{
                  width: '100%',
                  flexWrap: 'nowrap',
                  overflowX: 'auto',
                }}
              >
                {!isMobile && (
                  <IconButton
                    onClick={() => setPagePdf(pagePdf - 3)}
                    disabled={pagePdf < 1}
                    sx={{
                      color: 'white',
                      cursor: 'pointer',
                      marginY: 'auto',
                    }}
                  >
                    <ChevronLeft
                      sx={{
                        fontSize: '40px',
                      }}
                    />
                  </IconButton>
                )}

                {Array.from(
                  new Array(numPages).slice(pagePdf, pagePdf + (isMobile ? 1 : 3)),
                  (el, index) => (
                    <Box
                      key={`page_${index + 1}`}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography
                        marginBottom="5px"
                        fontSize="20px"
                        color="white"
                      >{`Page ${index + (pagePdf + 1)}`}</Typography>
                      <Page pageNumber={index + (pagePdf + 1)} scale={1} />
                    </Box>
                  ),
                )}
                {!isMobile && (
                  <IconButton
                    onClick={() => setPagePdf(pagePdf + 3)}
                    disabled={pagePdf + 1 >= numPages - 2}
                    sx={{
                      color: 'white',
                      cursor: 'pointer',
                      marginY: 'auto',
                    }}
                  >
                    <ChevronRight
                      sx={{
                        fontSize: '40px',
                      }}
                    />
                  </IconButton>
                )}
                {isMobile && (
                  <Stack
                    flexDirection="row"
                    justifyContent="space-between"
                    paddingX="1rem"
                  >
                    <Button
                      variant="contained"
                      disabled={pagePdf < 1}
                      onClick={() => setPagePdf(pagePdf - 1)}
                      sx={{
                        width: '120px',
                      }}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="contained"
                      disabled={pagePdf + 1 >= numPages}
                      onClick={() => setPagePdf(pagePdf + 1)}
                      sx={{
                        width: '120px',
                      }}
                    >
                      Next
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Document>
          </Box>
        )}

        {isVideo && (
          <CardMedia
            component={'video'}
            image={selectedImage?.url || ''}
            controls
            autoPlay
            playsInline
            loop
            style={{
              position: 'absolute',
              width: '100%',
              objectFit: 'contain',
              maxWidth: '80%',
              maxHeight: '90%',
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default FullDisplayMediaDialog;
