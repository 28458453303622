import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

const COLORS = ['#CD0061', '#FF007A', '#E16B9D', '#E993B8' , '#F8C9E3', '#FDE7F5'];

const limitToShow = 5;
interface Props {
  data: { name: string; value: number }[];
  name: string;
}

const GraphicPieChart:FC<Props> = (data) => {

  const namePages = [{
    id: "pageStatisticsByCountry",
    name: "Views by Country"
  },
  {
    id: "pageStatisticsByFunction",
    name: "Views by Function"
  },
  {
    id: "pageStatisticsByIndustry",
    name: "Views by Industry"
  },
  {
    id: "pageStatisticsByRegion",
    name: "Views by  Region"
  },
  {
    id: "pageStatisticsBySeniority",
    name: "Views by  Seniority"
  },
  {
    id: "pageStatisticsByStaffCountRange",
    name: "Views by Staff Count Range"
  }]

 const {data: dataViews, name} = data;

 const dataMaped = dataViews.map((item) => {
  return {
    name : item.name,
    value: item.value
  }

  })

  // const limitToShow = dataMaped.length
  const sortedData = dataMaped.sort((a, b) => b.value - a.value);

  let processedData = sortedData.slice(0, limitToShow);

  const totalValue = sortedData.reduce((total, item) => total + item.value, 0);
  const totalValueTop = processedData.reduce((total, item) => total + item.value, 0);
  const totalViewsOther = totalValue - totalValueTop;

  if (totalValue > 0) {
    processedData = [
      ...processedData,
      { name: 'Other', value: totalViewsOther },
    ];
  }

  return (
    <Box>
      <Typography variant="h6" >
        {namePages.find((item) => item.id === name)?.name}
      </Typography>

    <ResponsiveContainer width="100%" height={250} >
      <PieChart>
        <Pie
          data={processedData}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={50}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={2}
          isAnimationActive={false}
        >
          {processedData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
    </Box>

  );
};

export default GraphicPieChart;
