import {
  Button,
  ButtonGroup,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useContext } from 'react';
import { UserRole } from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import { ContentBoardTabs } from '../../types';
import { InfoOutlined } from '@mui/icons-material';

const buttonGroupStyles = {
  backgroundColor: '#F8F8F8',
  width: 'fit-content',
  gap: 0.5,
  border: '1px solid #DCDCDC',
  padding: 1,
};

const buttonStyles = (isMobile: boolean) => ({
  fontWeight: 'bold',
  flex: 1,
  zIndex: 1,
  minHeight: isMobile ? '30px !important' : '15px !important',
  height: isMobile ? '40px !important' : '37px !important',
  borderRadius: '9px',
  textTransform: 'none',
  backgroundColor: '#FFFFFF',
  display: 'flex',
  alignItems: 'center', // Vertical align center
  '&.Mui-selected': {
    backgroundColor: '#FFD8EB',
  },
  whiteSpace: 'nowrap',
  fontSize: isMobile ? '12px' : '14px',
  paddingX: isMobile ? 1 : 2,
  minWidth: isMobile ? '25%' : '80px',
  maxWidth: isMobile ? 'none' : '100px',
});

interface Props {
  selectedTabs: ContentBoardTabs[];
  setSelectedTabs: (tabs: ContentBoardTabs[]) => void;
  showTabApproved?: boolean;
}

const Tabs = ({ selectedTabs, setSelectedTabs, showTabApproved = false }: Props) => {
  const { user } = useContext(AuthContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTabClick = (tab: ContentBoardTabs) => {
    if (selectedTabs.includes(tab)) {
      setSelectedTabs(selectedTabs.filter((t) => t !== tab));
    } else {
      setSelectedTabs([...selectedTabs, tab]);
    }
  };

  const handleTabOnlyClick = (tab: ContentBoardTabs) => {
    if (selectedTabs.length === 1 && selectedTabs[0] === tab) {
      setSelectedTabs([]);
    } else {
      setSelectedTabs([tab]);
    }
  };

  const CustomButton = ({ tab, label }: { tab: ContentBoardTabs; label: string }) => (
    <Button
      sx={buttonStyles(isMobile)}
      variant="outlined"
      className={selectedTabs.includes(tab) ? 'Mui-selected' : ''}
      onContextMenu={(e) => {
        e.preventDefault();
        handleTabClick(tab);
      }}
      onClick={() => {
        handleTabOnlyClick(tab);
      }}
    >
      {label}
    </Button>
  );

  const ButtonsRender = () => (
    <>
      <CustomButton
        tab={ContentBoardTabs.drafts}
        label={
          user?.role.includes(UserRole.LimitedBrandManager) ||
          user?.role.includes(UserRole.BrandManager)
            ? 'Drafts'
            : 'My drafts'
        }
      />
      <CustomButton tab={ContentBoardTabs.inbox} label="Inbox" />
      {showTabApproved && (
        <CustomButton tab={ContentBoardTabs.approved} label="Approved" />
      )}

      <CustomButton tab={ContentBoardTabs.scheduled} label="Scheduled" />
      <CustomButton tab={ContentBoardTabs.posted} label="Posted" />
      <CustomButton tab={ContentBoardTabs.discarded} label="Discarded" />
    </>
  );

  return (
    <Stack
      direction={'row'}
      flexWrap="wrap"
      sx={{
        ...buttonGroupStyles,
        border: isMobile ? '1px solid #DCDCDC' : 'none',
        borderRadius: '10px',
      }}
    >
      {!isMobile ? (
        <>
          <ButtonGroup size={isMobile ? 'small' : 'medium'}>
            <ButtonsRender />
          </ButtonGroup>
          <Tooltip title="Use Right click to select multiple status">
            <InfoOutlined color="primary" sx={{ width: '15px' }} />
          </Tooltip>
        </>
      ) : (
        <ButtonsRender />
      )}
    </Stack>
  );
};

export default Tabs;
