import { useMutation, useQuery } from '@apollo/client';
import { LinkedIn } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControlLabel,
  List,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { TypeBrand, UserRole } from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import { BrandContext } from '../../context/BrandContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { CREATE_BRAND, UPDATE_BRAND } from '../../graphql/mutations';
import { GET_USERS_BY_COMPANY } from '../../graphql/queries';
import useBrandForm from '../../hooks/useBrandForm';
import Avatar from '../Avatar/Avatar';
import { TabPanel } from '../Tabs/TabPanel';
import BrandCorporateForm from './BrandCorporateForm';
import { BrandInput } from './BrandData';
import BrandPersonalForm from './BrandPersonalForm';

const styleChip = {
  color: '#BBBBBB',
  background: 'white',
  height: '30px',
  border: '1px solid rgba(255, 0, 122, 1)',
  padding: '0px 5px',
  '& .MuiChip-label': {
    color: 'rgba(255, 0, 122, 1)',
  },
  '&:hover': {
    backgroundColor: 'rgba(255, 0, 122, 0.1)',
  },
};

const inputStyle = {
  width: '100%',
  borderRadius: '8px',
  '&.MuiInputBase-root': {
    borderRadius: '8px',
  },
};

const buttonStyle = {
  width: '170px',
};

const tabStyle = {
  textTransform: 'none',
  fontSize: 14,
  fontWeight: 'bold',
  color: 'text.primary',
};

const BrandModal = () => {
  const {
    dataBrands,
    handleConnectNewAccount,
    tabModal,
    setTabModal,
    newBrandData,
    pages,
    handleSaveLinkedinCompanyCredentials,
    handleCloseModal,
    handleBrandSelected,
  } = useContext(BrandContext);
  const { setErrorMessage } = useContext(SnackbarContext);
  const [typeBrand, setTypeBrand] = useState<TypeBrand | null>(
    newBrandData?.typeBrand || null,
  );

  const { user } = useContext(AuthContext);
  const { data: dataUsers } = useQuery(GET_USERS_BY_COMPANY, {
    variables: {
      filter: {
        deleted: true,
      },
    },
    fetchPolicy: 'network-only',
    skip:
      !user?.role.includes(UserRole.BrandManager) &&
      !user?.role.includes(UserRole.LimitedBrandManager),
  });

  const users = dataUsers?.colleagueUsers || [];

  const shellUsersNotAsignedOrSelected = users?.filter(
    (user) =>
      !dataBrands.find(
        (brand) => brand.userId === user._id && brand.userId !== newBrandData?.userId,
      ) || user._id === newBrandData?.userId,
  );

  const [createBrand, { loading: createLoading }] = useMutation(CREATE_BRAND, {
    onCompleted: (data) => {
      if (data.createBrand?.success && data.createBrand.brand) {
        localStorage.setItem('newBrandId', data!.createBrand!.brand?._id!);
        handleBrandSelected(data.createBrand?.brand);
        setTabModal(2);
        return;
      }

      setErrorMessage('There was an error creating your brand.');
    },
    onError: (error) => {
      setErrorMessage('There was an error creating your brand.');
    },
  });

  const [updateBrand, { loading: updateLoading }] = useMutation(UPDATE_BRAND, {
    onCompleted: (data) => {
      if (data.updateBrand?.success) {
        setTabModal(2);
      }
    },
  });

  const schema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    associatedBrand: Yup.string().required('Associated Brand is required'),
    userBrand: Yup.object().shape({
      id: Yup.string().test('required', 'User is required', (value) => {
        if (
          watchAssociatedBrand &&
          !value &&
          tabModal === 0 &&
          typeBrand === TypeBrand.Personal
        ) {
          return false;
        }
        return true;
      }),
    }),
    companyBrandId: Yup.object().shape({
      id: Yup.string().test('required', 'Company Brand is required', (value) => {
        if (
          watchAssociatedCorporateBrand &&
          !value &&
          tabModal === 0 &&
          typeBrand === TypeBrand.Personal
        ) {
          return false;
        }
        return true;
      }),
    }),
    position: Yup.string().test('required', 'Position is required', (value) => {
      if (tabModal === 1 && !value && typeBrand === TypeBrand.Personal) {
        return false;
      }
      return true;
    }),
    targetBrand: Yup.array().test('required', 'Target Brand is required', (value) => {
      if (tabModal === 1 && value!.length === 0 && typeBrand === TypeBrand.Personal) {
        return false;
      }
      return true;
    }),
    targetPublic: Yup.string().test('required', 'Target Public is required', (value) => {
      if (tabModal === 1 && !value && typeBrand === TypeBrand.Personal) {
        return false;
      }
      return true;
    }),
    themeTalk: Yup.string().test('required', 'Theme Talk is required', (value) => {
      if (tabModal === 1 && !value && typeBrand === TypeBrand.Personal) {
        return false;
      }
      return true;
    }),
    toneVoice: Yup.array().test('required', 'Tone of Voice is required', (value) => {
      if (tabModal === 1 && value!.length === 0 && typeBrand === TypeBrand.Personal) {
        return false;
      }
      return true;
    }),
    corporateVoice: Yup.array().test('required', 'Tone of Voice is required', (value) => {
      if (tabModal === 1 && value!.length === 0 && typeBrand === TypeBrand.Corporate) {
        return false;
      }
      return true;
    }),
    industry: Yup.string().test('required', 'Industry is required', (value) => {
      if (tabModal === 1 && !value && typeBrand === TypeBrand.Corporate) {
        return false;
      }
      return true;
    }),
    subIndustry: Yup.string().test('required', 'Sub Industry is required', (value) => {
      if (
        tabModal === 1 &&
        !value &&
        typeBrand === TypeBrand.Corporate &&
        watchIndustry !== 'Other industry'
      ) {
        return false;
      }
      return true;
    }),
    otherIndustries: Yup.string().test(
      'required',
      'Other Industries is required',
      (value) => {
        if (
          tabModal === 1 &&
          !value &&
          (watchSubIndustry === 'Others' || watchIndustry === 'Other industry') &&
          typeBrand === TypeBrand.Corporate
        ) {
          return false;
        }
        return true;
      },
    ),
    competitors: Yup.string().test('required', 'Competitors is required', (value) => {
      if (tabModal === 1 && !value && typeBrand === TypeBrand.Corporate) {
        return false;
      }
      return true;
    }),
    negatives: Yup.string().test('required', 'Negatives is required', (value) => {
      if (tabModal === 1 && !value && typeBrand === TypeBrand.Corporate) {
        return false;
      }
      return true;
    }),
    competitiveAdvantage: Yup.string().test(
      'required',
      'Competitive Advantage is required',
      (value) => {
        if (tabModal === 1 && !value && typeBrand === TypeBrand.Corporate) {
          return false;
        }
        return true;
      },
    ),
    hashtags: Yup.string().test('required', 'Hashtags is required', (value) => {
      if (tabModal === 1 && !value && typeBrand === TypeBrand.Corporate) {
        return false;
      }
      return true;
    }),
  });

  const { control, errors, handleSubmit, watchAllFields, setValue } = useBrandForm({
    schema,
    brandData: newBrandData,
  });

  const {
    watchAssociatedBrand,
    watchAssociatedCorporateBrand,
    watchConnectLinkedin,
    wathcPageUrn,
    watchIndustry,
    watchSubIndustry,
  } = watchAllFields;

  const onSuccess = (input: BrandInput) => {
    const newBrandId = localStorage.getItem('newBrandId');
    const {
      name,
      language,
      competitors,
      hashtags,
      negatives,
      industry,
      subIndustry,
      competitiveAdvantage,
      companyBrandId,
      userBrand,
      otherIndustries,
      toneVoice,
      corporateVoice,
      position,
      targetBrand,
      targetPublic,
      themeTalk,
    } = input;
    if (tabModal === 0) {
      setTabModal(tabModal + 1);
    }

    if (tabModal === 1 && !newBrandData && !newBrandId) {
      createBrand({
        variables: {
          input: {
            companyBrandId: watchAssociatedCorporateBrand ? companyBrandId.id : null,
            typeBrand: typeBrand as TypeBrand,
            name: name,
            contentCreation: {
              language: language,
              industry: industry,
              subIndustry: subIndustry,
              otherIndustries: otherIndustries,
              competitiveAdvantage: competitiveAdvantage,
              toneVoice: toneVoice,
              corporateVoice: corporateVoice,
              hashtags: hashtags,
              competitors: competitors,
              negatives: negatives,
              position: position,
              targetBrand: targetBrand,
              targetPublic: targetPublic,
              themeTalk: themeTalk,
            },
            shellId: user?.shellId as string,
            userId: watchAssociatedBrand ? userBrand.id : null,
          },
        },
      });
    }

    if (tabModal === 1 && (newBrandData || newBrandId)) {
      updateBrand({
        variables: {
          input: {
            name: name,
            contentCreation: {
              industry: industry,
              subIndustry: subIndustry,
              otherIndustries: otherIndustries,
              competitiveAdvantage: competitiveAdvantage,
              toneVoice: toneVoice,
              corporateVoice: corporateVoice,
              hashtags: hashtags,
              competitors: competitors,
              negatives: negatives,
              position: position,
              targetBrand: targetBrand,
              targetPublic: targetPublic,
              themeTalk: themeTalk,
              language: language,
            },
          },
          brandId: !!newBrandData ? newBrandData?._id : newBrandId!,
        },
      });
    }

    if (tabModal === 2 && typeBrand === TypeBrand.Corporate && watchConnectLinkedin) {
      handleSaveLinkedinCompanyCredentials(
        pages.find((page) => page.urn === input.pageUrn),
      );
    }

    if (tabModal === 2 && (typeBrand === TypeBrand.Personal || !watchConnectLinkedin)) {
      handleCloseModal();
    }
  };

  return (
    <>
      {!typeBrand ? (
        <Box padding={4}>
          <Typography fontSize="25px" fontWeight={700} marginBottom={2}>
            What type of brand are you going to incorporate?
          </Typography>
          <Stack flexDirection="row" gap={1}>
            <Stack
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              gap={2}
              onClick={() => setTypeBrand(TypeBrand.Personal)}
              sx={{
                cursor: 'pointer',
                ':hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                },
                borderRadius: '10px',
              }}
            >
              <img
                src="./images/icons/campaign.svg"
                alt="brand"
                style={{
                  width: '72px',
                  height: '72px',
                  objectFit: 'cover',
                }}
              />
              <Box>
                <Typography fontSize="15px" fontWeight={700} marginBottom={1}>
                  Personal Brand
                </Typography>
                <Typography fontSize="14px">
                  Highlights and amplifies a leader's unique identity
                </Typography>
              </Box>
            </Stack>
            <Stack
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              gap={2}
              onClick={() => setTypeBrand(TypeBrand.Corporate)}
              sx={{
                cursor: 'pointer',
                ':hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                },
                borderRadius: '10px',
              }}
            >
              <img
                src="./images/icons/portafolio.svg"
                alt="portafolio"
                style={{
                  width: '72px',
                  height: '72px',
                  objectFit: 'cover',
                }}
              />
              <Box>
                <Typography fontSize="15px" fontWeight={700} marginBottom={1}>
                  Corporate Brand
                </Typography>
                <Typography fontSize="14px">
                  Raises and broadens the company's market presence
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Box>
      ) : (
        <Box padding={2}>
          <Typography fontSize="25px" fontWeight={700}>
            Add new {typeBrand === TypeBrand.Corporate && 'Corporate'} brand
          </Typography>

          <form onSubmit={handleSubmit(onSuccess)} style={inputStyle}>
            <Stack direction="column">
              <Typography>
                Enter the data of the brand you'll be working with and connect its
                LinkedIn to start publishing. Choose how to communicate with and work with
                your brand.
              </Typography>
              <Divider
                sx={{
                  marginY: 2,
                }}
              />
              <Tabs
                value={tabModal}
                sx={{
                  backgroundColor: 'white',
                  width: '100%',
                  marginBottom: 2,
                  '.MuiTabs-flexContainer': {
                    justifyContent: 'space-between',
                  },
                }}
              >
                <Tab label="Brand Information" sx={tabStyle} />
                <Tab label="Essential Information" sx={tabStyle} />
                <Tab label="Connect with LinkedIn" sx={tabStyle} />
              </Tabs>
              <TabPanel value={tabModal} index={0} sx={{ p: 0 }}>
                <Typography fontSize="13px" fontWeight="bold">
                  {typeBrand === TypeBrand.Corporate && 'Corporate'} Brand Name
                </Typography>
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      required
                      value={value}
                      onChange={onChange}
                      sx={inputStyle}
                      error={!!errors.name}
                      helperText={errors.name?.message}
                    />
                  )}
                />

                <Divider
                  sx={{
                    marginY: 2,
                  }}
                />
                <Stack
                  flexDirection="row"
                  justifyContent="flex-end"
                  gap={2}
                  paddingRight={1}
                >
                  <Typography fontWeight={700}>Yes</Typography>
                  <Typography fontWeight={700}>No</Typography>
                </Stack>
                {typeBrand === TypeBrand.Personal && (
                  <>
                    <Controller
                      name="associatedBrand"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <RadioGroup
                          aria-label="collaboration"
                          value={value}
                          // disabled={!!newBrandData}
                          onChange={onChange}
                          sx={{
                            py: 1,
                            borderRadius: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Stack>
                            <Typography fontSize="13px" fontWeight={700}>
                              Do you want the brand to be associated with a magnettu
                              account?
                            </Typography>
                            <Typography fontSize="13px" fontWeight={700} color={'red'}>
                              {!shellUsersNotAsignedOrSelected?.length &&
                                '(Currently, there are no users available to associate with the brand)'}
                            </Typography>
                          </Stack>
                          <Box>
                            <Radio
                              value={true}
                              disabled={!shellUsersNotAsignedOrSelected?.length}
                            />
                            <Radio
                              value={false}
                              disabled={!shellUsersNotAsignedOrSelected?.length}
                            />
                          </Box>
                        </RadioGroup>
                      )}
                    />
                  </>
                )}

                {watchAssociatedBrand && (
                  <Stack gap={2}>
                    <Typography fontSize="13px">
                      By selecting the user, this brand will be associated with the
                      magnettu account
                    </Typography>
                    <Controller
                      name="userBrand"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          sx={{ width: '400px' }}
                          options={[
                            ...(shellUsersNotAsignedOrSelected?.map((user) => ({
                              label: user.firstName
                                ? `${user.firstName} ${user.lastName}`
                                : user.email,
                              id: user._id,
                            })) || []),
                          ]}
                          value={value}
                          getOptionLabel={(option) => option.label}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={inputStyle}
                              value={value}
                              error={!!errors.userBrand?.id}
                              helperText={errors.userBrand?.id?.message}
                            />
                          )}
                          onChange={(_, selectedOption) =>
                            onChange(
                              selectedOption ? selectedOption : { id: '', label: '' },
                            )
                          }
                        />
                      )}
                    />
                  </Stack>
                )}
                {typeBrand === TypeBrand.Personal && (
                  <Controller
                    name="associatedCorporateBrand"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <RadioGroup
                        aria-label="collaboration"
                        value={value}
                        onChange={onChange}
                        sx={{
                          py: 1,
                          borderRadius: 1,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography fontSize="13px" fontWeight={700}>
                          Do you want to associate with a Corporate Brand?
                        </Typography>
                        <Box>
                          <Radio value={true} />
                          <Radio value={false} />
                        </Box>
                      </RadioGroup>
                    )}
                  />
                )}

                {watchAssociatedCorporateBrand && (
                  <Stack gap={2} paddingLeft={2}>
                    <Typography fontSize="13px">
                      By selecting the corporate brand, this brand will be associated with
                      a Corporate Brand. (If you don't have one, it is recommended to
                      create one first
                    </Typography>
                    <Controller
                      name="companyBrandId"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          sx={{ width: '400px' }}
                          options={
                            dataBrands
                              .filter((brand) => brand.typeBrand === TypeBrand.Corporate)
                              .map((brand) => ({
                                label: brand.name,
                                id: brand._id,
                              })) || []
                          }
                          value={value}
                          getOptionLabel={(option) => option.label}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={inputStyle}
                              error={!!errors.companyBrandId?.id}
                              value={value}
                              helperText={errors.companyBrandId?.id?.message}
                            />
                          )}
                          onChange={(_, selectedOption) =>
                            onChange(
                              selectedOption ? selectedOption : { id: '', label: '' },
                            )
                          }
                        />
                      )}
                    />
                  </Stack>
                )}
              </TabPanel>

              <TabPanel value={tabModal} index={1} sx={{ p: 0 }}>
                {typeBrand === TypeBrand.Personal ? (
                  <BrandPersonalForm
                    control={control}
                    inputStyle={inputStyle}
                    styleChip={styleChip}
                    errors={errors}
                    isModal
                  />
                ) : (
                  <BrandCorporateForm
                    control={control}
                    inputStyle={inputStyle}
                    styleChip={styleChip}
                    watchIndustry={watchIndustry}
                    watchSubIndustry={watchSubIndustry}
                    errors={errors}
                    setValue={setValue}
                  />
                )}
              </TabPanel>
              <TabPanel value={tabModal} index={2} sx={{ p: 0 }}>
                <Typography fontWeight={700} marginBottom={2}>
                  Congratulations on creating your brand!
                </Typography>
                <Typography>
                  You're all set to connect it to LinkedIn and begin publishing.
                </Typography>
                <Divider sx={{ marginY: 2 }} />
                <Typography>
                  Would you like to link your brand's LinkedIn profile now or prefer to do
                  it later? <b>The choice is yours!</b>
                </Typography>
                <Controller
                  name="connectLinkedin"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <RadioGroup
                      aria-label="connectLinkedin"
                      value={value}
                      onChange={onChange}
                      sx={{
                        padding: '10px',
                        borderRadius: '10px',
                      }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        onChange={onChange}
                        label="Yes, let's connect now!"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        onChange={onChange}
                        label="No, I'll connect later."
                      />
                    </RadioGroup>
                  )}
                />
                {watchConnectLinkedin && (
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        padding: 2,
                        borderRadius: 2,
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                        marginBottom: 1,
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        handleConnectNewAccount(typeBrand, window.location.pathname, true)
                      }
                    >
                      <LinkedIn
                        sx={{
                          color: '#0e76a8',
                          borderRadius: 100,
                          backgroundColor: 'white',
                        }}
                      />
                      <Typography fontWeight={700}>Connect LinkedIn</Typography>
                    </Box>

                    {typeBrand === TypeBrand.Personal &&
                      dataBrands.find((brand) => brand._id === newBrandData?._id) && (
                        <>
                          <Typography fontWeight={700}>
                            Personal account to connect:
                          </Typography>

                          <Box
                            sx={{
                              bgcolor: 'rgba(255, 0, 122, 0.04)',
                              width: '100%',
                              height: '68px',
                              borderRadius: '10px',
                              display: 'flex',
                              alignItems: 'center',
                              padding: '0 20px',
                            }}
                          >
                            <Typography fontWeight={700}>
                              {dataBrands.find((brand) => brand._id === newBrandData?._id)
                                ?.account?.name || 'Choose other account'}
                            </Typography>
                          </Box>
                        </>
                      )}

                    <List sx={{ paddingX: 1 }}>
                      {pages && pages.length > 0 && (
                        <Controller
                          name="pageUrn"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <RadioGroup
                              aria-label="pageUrn"
                              value={value}
                              onChange={onChange}
                              sx={{
                                padding: '10px',
                                borderRadius: '10px',
                              }}
                            >
                              {pages.map((page, idx) => (
                                <Stack flexDirection="row" justifyItems="center">
                                  <FormControlLabel
                                    key={idx}
                                    value={page.urn}
                                    control={<Radio />}
                                    onChange={() => {
                                      onChange(page.urn);
                                    }}
                                    label={page.name}
                                  />
                                  <Avatar avatar={page.avatar || ''} name={page.name} />
                                </Stack>
                              ))}
                            </RadioGroup>
                          )}
                        />
                      )}
                    </List>
                  </Box>
                )}
              </TabPanel>

              <Stack
                flexDirection="row"
                justifyContent={tabModal > 0 ? 'space-between' : 'flex-end'}
                marginTop={2}
              >
                {!!tabModal && (
                  <Button
                    variant="outlined"
                    type="button"
                    sx={buttonStyle}
                    onClick={() => setTabModal(tabModal - 1)}
                    data-testid="new-user-save-button"
                  >
                    Back
                  </Button>
                )}
                {Object.keys(errors).length > 0 && (
                  <Stack sx={{ marginY: 2 }}>
                    <Typography variant="body2" color="error">
                      Please correct the following errors:
                    </Typography>
                    <List>
                      {Object.entries(errors).map(([key, error]) => error.message)}
                    </List>
                  </Stack>
                )}
                <LoadingButton
                  variant="contained"
                  type="submit"
                  sx={buttonStyle}
                  loading={createLoading || updateLoading}
                  data-testid="new-user-save-button"
                  disabled={
                    tabModal === 2 &&
                    watchConnectLinkedin &&
                    !(
                      dataBrands.find((brand) => brand._id === newBrandData?._id)
                        ?.account || wathcPageUrn
                    )
                  }
                >
                  {tabModal === 2 ? 'Finish' : tabModal === 1 ? 'Create' : 'Next'}
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Box>
      )}
    </>
  );
};

export default BrandModal;
