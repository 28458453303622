import { useMutation } from '@apollo/client';
import { Button } from '@mui/material';
import { useContext, useState } from 'react';
import { FormContainer, SwitchElement } from 'react-hook-form-mui';

import { AuthContext } from '../../context/AuthContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { UNSUBSCRIBE_USER, SUBSCRIBE_USER } from '../../graphql/mutations';
import SettingsSection from '../SettingsSection/SettingsSection';

const inputStyle = {
  width: '100%',
  maxWidth: '800px',
};

const SettingsNotifications = () => {
  const { user } = useContext(AuthContext);
  const [enableNotifications, setEnableNotifications] = useState<boolean>(
    user?.notifications?.email?.allowed !== false,
  );
  const { setSuccessMessage, setErrorMessage } = useContext(SnackbarContext);

  const [UnsubscribeUser] = useMutation(UNSUBSCRIBE_USER, {
    onCompleted: ({ unsubscribeUserNotifications }) => {
      setSuccessMessage('Notifications settings updated');
    },
    onError: (err) => {
      setErrorMessage(err.message);
    },
  });

  const [SubscribeUser] = useMutation(SUBSCRIBE_USER, {
    onCompleted: ({ subscribeUserNotifications }) => {
      setSuccessMessage('Notifications settings updated');
    },
    onError: (err) => {
      setErrorMessage(err.message);
    },
  });

  const valuesForm = {
    notifications: user?.notifications?.email?.allowed !== false,
  };

  type ValuesForm = typeof valuesForm;

  const onSuccess = (dataForm: ValuesForm) => {
    const userId = user?._id || '';
    enableNotifications
      ? SubscribeUser({ variables: { userId: userId } })
      : UnsubscribeUser({ variables: { userId: userId } });
  };

  return (
    <>
      <FormContainer onSuccess={onSuccess} values={valuesForm}>
        <SettingsSection
          title="Email"
          subtitle="Opt out from receiving email updates"
          divider={true}
        >
          <SwitchElement
            name="notifications"
            label={'All notifications'}
            value={enableNotifications}
            onClick={() => setEnableNotifications(!enableNotifications)}
            data-testid="notifications-switch"
          />
        </SettingsSection>

        <Button
          variant="contained"
          type="submit"
          sx={inputStyle}
          data-testid="save-button-content-settings"
        >
          Save
        </Button>
      </FormContainer>
    </>
  );
};

export default SettingsNotifications;
