import { useNavigate, useParams } from 'react-router-dom';
import PostEditorModal from '../../components/PostComponents/PostEditorModal';
import { AuthContext } from '../../context/AuthContext';
import { useContext } from 'react';
import { WebSocketProvider } from '../../context/WebSocketContext';
import { PostProvider } from '../../context/PostContext';

const PostEditor = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { postId: postParam } = useParams();

  const postId = postParam || null;
  return (
    <WebSocketProvider postId={postId}>
      <PostProvider
        postId={postId}
        newPostOptions={{
          brandId: user?.brandSelectedId || user?.brandId,
          brand: user?.brandSelected || user?.brand,
        }}
      >
        <PostEditorModal
          onClose={() => {
            navigate('/');
          }}
          isLink
        />
      </PostProvider>
    </WebSocketProvider>
  );
};

export default PostEditor;
