import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import {
  Bar,
  Cell,
  LabelList,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { MonthlyDataEntry } from '../../__generated__/graphql';
import { months, formatNumber } from '../../utils';

interface Metrics {
  metric?: MonthlyDataEntry[] | undefined;
}

const GraphicReportCompanyImpressions: FC<Metrics> = ({ metric }) => {
  function generateData(metricData: MonthlyDataEntry[]) {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const data = [];

    const metricsByMonth = metricData;

    for (let i = 0; i < 6; i++) {
      const monthIndex = (currentMonth - i + 12) % 12;
      const monthYear = currentDate.getFullYear() - (currentMonth < i ? 1 : 0); // Ajuste del año si estamos mirando meses del año pasado
      const monthName = months[monthIndex];
      const fullMonthYear = `${monthYear}-${('0' + (monthIndex + 1)).slice(-2)}`; // Formato YYYY-MM

      // Buscar la métrica correspondiente al mes y año actual del ciclo
      const metric = metricsByMonth.find((item) => item.month === fullMonthYear);

      // Extraer los valores necesarios de la métrica encontrada o valores predeterminados si no hay métrica
      // const count = metric ? metric?.data?.postCount : 0;
      const impressions = metric ? metric?.data?.impressionCount : 0;

      const newData = {
        name: monthName,
        // 'Company Posts': count,
        impressions: formatNumber(impressions || 0),
        'Company Impressions': impressions,
      };

      data.unshift(newData); // Agregar al inicio para mantener el orden cronológico correcto
    }

    return data;
  }

  const data = generateData(metric!!);

  return (
    <Box
      width="100%"
      style={{
        backgroundColor: '#F8F8F8',
        borderRadius: '10px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '470px',
        padding: '2rem',
      }}
    >
      <YAxis orientation="right" yAxisId="right" />
      <Box marginY="1rem" marginLeft="2rem">
        <Typography fontSize="1rem" textAlign="left">
          Impressions last 6 months
        </Typography>
      </Box>
      <ResponsiveContainer>
        <LineChart
          width={600}
          height={500}
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 50,
          }}
          style={{
            display: 'flex',
          }}
        >
          {/* isAnimationActive set to false due to a bug: https://github.com/recharts/recharts/issues/829 */}
          <Bar
            dataKey="Company Posts"
            barSize={80}
            fill="#CD0061"
            isAnimationActive={false}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill="#CD0061" />
            ))}
            <LabelList dataKey="Company Posts" position="inside" fill="white" />
          </Bar>
          <Line
            type="monotone"
            dataKey="Company Impressions"
            stroke="#E993B8"
            strokeWidth={3}
            isAnimationActive={false}
            yAxisId="right"
          >
            <LabelList dataKey="impressions" position="top" fill="black" />
          </Line>
          <XAxis dataKey="name" dy={10} />
          <Tooltip />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default GraphicReportCompanyImpressions;
