import { gql } from '../__generated__/gql';

export const GET_CURRENT_USER = gql(`
  query GetCurrentUser {
    currentUser {
      _id
      brandId
      shellId
      shellName
      shell {
        _id
        logo
        name
        title
        icon
        whiteLabel
        noSendEmail
        tags {
          _id
          color
          name
          }
      }
      email
      firstName
      lastName
      role
      plan
      position
      avatar
      numMagnets
      firstLogin
      lifecycleState
      notifications {
        email {
          allowed
        }
        messages {
          _id
          type
          message
          image
          title
          url
          show
          actionTaken
          date
        }
      }
      customMenus {
        title
        url
        icon
      }
      trialEnd
      activeTrial
      isActive
      brandSelectedId
      brandSelected {
        _id
        typeBrand
        name
        shellId
        shell {
          _id
          name
        }
        contentCreation {
        requireContent
        }
        backgroundColor
        account {
          id
          type
          name
          token
          avatar
        }
        lastMetricsUpdate
        userId
      }
      stripeCustomerId
      brand {
        _id
        name
        shellId
        email
        typeBrand
        phone
        backgroundColor
        userId
        companyBrandId
        approval {
          required
          email
          whatsapp
        }
        account {
          id
          type
          name
          token
          exp
          avatar
        }
        contentCreation {
          language
          bio
          hashtags
          signature
          toneVoice
          negatives
          otherIndustries
          industry
          subIndustry
          competitors
          competitiveAdvantage
          position
          themeTalk
          targetBrand
          targetPublic
          goalsBrand
          otherGoalsBrand
          valuesBrand
          corporateValues
          requireContent
          responsabilities
          perspective
          numberEmojis
          numberHashtags
          textLength
          themeExtra
          uniqueBrand
          themeNegative
          corporatePostFirstPart
          corporatePostSecondPart
          corporatePostThirdPart
          otherCorporatePostFirstPart
          otherCorporatePostSecondPart
          otherCorporatePostThirdPart
          contentConsumption
          otherContentConsumption
          firstPost
          secondPost
          thirdPost
          fourthPost
          worldLeaderInspiration
          industryLeaderInspiration
          hobbies
          artistsInspiration
          valuesBrand
          corporateValues
          politicalSocialActivityBrand
          trendingTopics
          trustedMedia
          corporateTarget
          corporateIdeas
          corporateVoice
        }
      
      }
    }
  }
`);

export const VALIDATE_INVITE = gql(`
  query ValidateInvite($token: String) {
    validateInvite(token: $token) {
      success
      email
    }
  }
`);

export const GET_GROUPS_BY_USER = gql(`
query GetGroupsByUser   {
  shellGroups {
    _id
    name
    companyId
    shellId
    backgroundColor

  }
}
`);

export const GET_CURRENT_BRAND_POSTS = gql(`
  query GetCurrentBrandPosts($filter: PostsBrandsFilter, $orderBy: OrderBy, $page: Int, $limit: Int) {
    currentBrandPosts(filter: $filter, orderBy: $orderBy, page: $page, limit: $limit) {
      total
      havePostsApproved
      posts {
      _id
      createdOn
      createdById
      createdBy {
        _id
        firstName
        lastName
        shellId
        email
        role
        shellName
        }
      publishedOn
      shellId
      lifecycleState
      lifecycleStateDisplay {
        name
        color
      }
      magnets
      type
      favorite
      brandId
      isIdea
      title
      brandName
      brand {
        _id
        name
        shellId
        account {
          id
          name
          avatar
          token
          type
        }
        backgroundColor
      }
      schedule
      postedAt
      tags {
      _id
      color
      name
      }
      unreadMessagesCount
      content {
        title
        preview
        hashtags
        thumbnail {
          source
          title
          description
          media {
          type
          url
          alt         
           }
          }
          media {
            type
            url
            alt
          }
        media {
          type
          url
          alt
        }
      }
      advocacy {
        editable
        parentPostId
        parentPost {
          _id
          createdOn
          lifecycleState
          type
          brandId
          content {
            preview
            media {
              type
              url
              alt
            }
          }
        }
      }
      }
    }
  }
`);

export const GET_ADVOCACY_PARENT_POSTS = gql(`
  query GetAdvocacyParentPosts {
    advocacyParentPosts {
      parentPost {
        _id
        createdOn
        type
        content {
          preview
        }
      }
      childrenPosts {
        _id
        lifecycleState
        brand {
          name
          account {
            avatar
          }
        }
      }
    }
  }
`);

export const GET_POST = gql(`
  query GetPost($postId: ObjectId!) {
    post(postId: $postId) {
      _id
      createdOn
      brandId
      isIdea
      title
      shellId
      brand {
        name
        backgroundColor
        account {
          avatar
          id
          name
          token
          exp
          type
        }
        userId
        user {
          _id
          role
          shellId
          shellName
          firstName
          lastName
          email
        }
      }
      schedule
      postedAt
      lifecycleState
      lifecycleStateDisplay {
        name
        color
        isStateChangeable
        isPostPostable
      }
      type
      tags {
        _id
        color
        name
        }
      magnets
      linkedin {
        postUrn
      }
      childrenPosts {
        _id
        lifecycleState
        brandId
      }
      advocacy {
        recipients {
          _id
          name
          account {
            avatar
          }
          user {
            _id
            shellId
            shellName
            email
            phone
            firstName
            lastName
            role
            notifications {
              email {
                allowed
              }
            }
          }
        }
        editable
        parentPostId
        parentPost {
          content {
            title
            preview
            body
            json
            hashtags
            media {
              storagePath
              type
              url
              alt
            }
          }
        }
      }
      content {
        title
        preview
        body
        json
        thumbnail {
          source
          title
          description
          media {
            type
            url
            alt
          }
        }
        hashtags
        media {
          storagePath
          type
          url
          alt
        }
      }
      unreadMessagesCount
      chat {
        content
        userId
        userOrBrand {
          ... on Brand {
            _id
            shellId
            account {
              id
              avatar
              type
            }
            name
          }
          ... on User {
            _id
            role
            shellId
            shellName
            avatar
            firstName
            lastName
            email
          }
        }
        role
        time
      }
      shareLink {
        share
        expiration
      }
    errorCode
    }
  }
`);

export const GET_EDIT_POST = gql(`
  query GetEditPost($postId: ObjectId!) {
    post(postId: $postId) {
      _id
      createdOn
      publishedOn
      lifecycleState
      isIdea
      title
      schedule
      type
      tags {
      _id
      color
      name
      }
      brandId
      brandName
      brand {
        _id
        name
        typeBrand
        shellId
        shell {
          _id
          name
        }
      }
      content {
        title
        body
        preview
        hashtags
        thumbnail {
          source
          title
          description
          media {
            type
            url
            alt
          }
        }
        media {
          type
          url
          alt
        }
      }
      advocacy {
        editable
        parentPostId
        parentPost {
          _id
          createdOn
          lifecycleState
          type
          brandId
          content {
            preview
            body
            media {
              type
              url
              alt
            }
          }
        }
      }
      magnets
    }
  }
`);

export const GET_USERS = gql(`
  query GetUsers {
    users {
      _id
      uid
      email
      firstName
      lastName
      role
      plan
      avatar
      createdOn
      deleted
      shellId
      shellName
      position
      lifecycleState
      trialStart
      trialEnd
      activeTrial
    } 
  }
`);

export const GET_USER = gql(`
  query GetUser($userId: ObjectId!) {
    user(userId: $userId) {
      _id
      uid
      email
      firstName
      lastName
      role
      plan
      avatar
      createdOn
      deleted
      shellId
      shellName
      position
      lifecycleState
      trialStart
      trialEnd
      activeTrial
    } 
  }
`);

export const GET_USERS_BY_COMPANY = gql(`
  query GetUsersByCompany($filter: ColleagueFilter){
    colleagueUsers(filter: $filter) {
      createdOn
      _id
      uid
      email
      firstName
      lastName
      plan
      role
      shellId
      shellName
      inviteSentOn
      brandsManaged {
        _id
        name
        backgroundColor
        shellId
        account {
          avatar
          id
          name
          token
          exp
          type
        }
      }
      brand {
        _id
        name
        shellId
      }
      notifications {
        email {
          allowed
        }
      }
    }
  }
`);

export const GET_ALL_BRANDS_TO_TAG = gql(`
  query GetAllBrandsToTag($search: String, $postId: ObjectId) {
    getAllBrandsToTag(search: $search, postId: $postId) {
        id
        linkedinUrn
        name
        avatar
        type     
    }
  }
`);

export const GET_POSTS = gql(`
  query GetPosts($filter: PostsBrandsFilter, $orderBy: OrderBy, $page: Int, $limit: Int) {
    posts(filter: $filter, orderBy: $orderBy, page: $page, limit: $limit) {
      total
      posts {
        _id
        createdOn
        createdBy {
          _id
          firstName
          lastName
          email
          role
          shellId
          shellName
        }
        publishedOn
        lifecycleState
        lifecycleStateDisplay {
          name
          color
        }
        type
        tags {
          _id
          color
          name
        }
        brandId
        brandName
        schedule
        content {
          body
        }
      }
    }
  }
`);

export const GET_POSTS_BY_SHELL = gql(`
  query GetPostsByShell($filter: PostsBrandsFilter, $orderBy: OrderBy, $page: Int, $limit: Int) {
    postsByShell(filter: $filter, orderBy: $orderBy, page: $page, limit: $limit) {
      total
      posts {
        _id
        createdOn
        publishedOn
        lifecycleState
        isIdea
        title
        lifecycleStateDisplay {
          name
          color
        }
        type
        tags {
          _id
          color
          name
        }
        brandId
        createdBy {
        _id
        firstName
        lastName
        shellId
        email
        role
        shellName
        }
        shellId
        brand {
          _id
          name
          shellId
          account {
            id
            name
            avatar
            token
            exp
            type
          }
          backgroundColor
        }
        brandName
        schedule
        content {
          body
          preview
          thumbnail {
          source
          title
          description
          media {
          type
          url
          alt         
           }
          }
          media {
            type
            url
            alt
          }
        }
        unreadMessagesCount
        postedAt
        advocacy {
          parentPost {
            _id
            createdOn
            lifecycleState
            type
            brandId
            content {
              body
              preview
              media {
                type
                url
                alt
              }
            }
          }
        }
      }
    }
  }
`);

export const GET_CHAINS = gql(`
  query GetChains {
    chains {
      _id
      updatedOn
      name
      description
      resultKey
      streamed
      chainA {
        messages {
          role
          content
        }
        temperature
        outputKeys {
          type
          key
          description
        }
      }
      chainB {
        messages {
          role
          content
        }
        temperature
        outputKeys {
          type
          key
          description
        }
      }
    }
  }
`);

export const GET_CHAINS_WITH_STATS = gql(`
  query GetChainsWithStats {
    chains {
      _id
      updatedOn
      name
      description
      resultKey
      winsASinceLastUpdate
      winsBSinceLastUpdate
      chainA {
        messages {
          role
          content
        }
        temperature
        outputKeys {
          type
          key
          description
        }
      }
      chainB {
        messages {
          role
          content
        }
        temperature
        outputKeys {
          type
          key
          description
        }
      }
      streamed
    }
  }
`);

export const GET_SHELLS = gql(`
query GetShells  {
  shells {
    _id
    name
    avatar
    logo
    title
    icon
    whiteLabel
    noSendEmail
    plans {
      advocacy
      advanced
      corporate
    }
  }
}`);

export const GET_USER_SHELL = gql(`
query GetUserShell {
  userShell {
    name
    _id
    plans {
      advocacy
      advanced
      corporate
    }
  }
}`);

export const GET_METRICS_TEAM = gql(`
query GetMetricsTeam($shellId: ObjectId! ) {
  getMetricsTeam(shellId: $shellId) {
      brandsWithPosts30Days
      brandsWithPostsPrevious30Days
      postsCount30Days
      postsCountPrevious30Days
      brandsShellCount
      magnetsRanking {
        brandName
        magnets
      }
      brandsMetricsWithShell {
        brandId
        brandName
        postsTotal
        magnetsTotal
        postsLast30Days
        magnetsLast30Days
        postsLast7Days
        interactions
        magnetsLast7Days
        interactions30Days
        interactions7Days
        useExtension
        
      }
    }
  }
`);

export const GET_METRICS_PERFORMING_CONTENT = gql(`
query getMetricsPerformingContent($shellId: ObjectId!, $dateInit: Date!, $dateEnd: Date! ) {
  getMetricsPerformingContent(shellId: $shellId, dateInit: $dateInit, dateEnd: $dateEnd) {
      performingContent {
        postedAt
        content
        brandName
        postUrn
        postId
        tags {
          _id
          color
          name
          }
        metrics {
          followers
          numComments
          numLikes
          numViews
          totalShares
        }
        account {
          name
        }
      }
    }
  }
`);

export const GET_METRICS_BY_BRAND = gql(`
  query GetMetricsByBrand($brandId: ObjectId!, $dateInit: Date!, $dateEnd: Date!) {
    getMetricsByBrand(brandId: $brandId, dateInit: $dateInit, dateEnd: $dateEnd) {
      followers
      countMetrics60Days
      countMetrics30Days
      followers30DaysAgo
      followers60DaysAgo
      interactions30DaysAgo
      interactions60DaysAgo
      impressions30DaysAgo
      impressions60DaysAgo
      metricsByWeek {
        startDate
        endDate
        numComments
        numLikes
        numViews
        totalShares
      }
      performingContent {
        postId
        tags {
          _id
          color
          name
          }
        postedAt
        content
        brandName
        postUrn
        metrics {
          followers
          numComments
          numLikes
          numViews
          totalShares
        }
    }
  }
  }
`);

export const GET_INSIGHTS = gql(`
query GetInsights($frequency: FrequencyFilters, $topics: [String], $languages: [Language], $favorites: Boolean, $insightTypes: [InsightType]) {
    getInsights(frequency: $frequency, topics: $topics, languages: $languages, favorites: $favorites, insightTypes: $insightTypes) {
        _id
        title
        link
        creator
        description
        content
        pubDate
        imgUrl
        videoUrl
        source
        articleId
        keywords
        type
        sentiment
        favorite
        media {
          type
          url
          alt
        }
        author {
          description
          firstName
          lastName
          fullName
          profilePicture
        }
        interactions {
          comments
          numLikes
        }
    }
  }
`);

export const GET_METRICS_TO_REPORTS = gql(`
query GetMetricsToReports($shellId: ObjectId!, $startDateOne: String!, $dateEndOne: String!, $startDateTwo: String!, $dateEndTwo: String! ) {
  getMetricsToReports(shellId: $shellId, startDateOne: $startDateOne, dateEndOne: $dateEndOne, startDateTwo: $startDateTwo, dateEndTwo: $dateEndTwo) {
    selectorOne {
    totalShares
    numComments
    numImpressions
    numLikes
    timeRange
    }
    totalViews
    selectorTwo {
      totalShares
    numComments
    numImpressions
    numLikes
    timeRange
    }
    pieData {
      id
      statsArray {
        name
        value
      }
    }

  }
}
`);

export const GET_RUNS = gql(`
  query GetPrompterRuns($filter: RunsFilter, $orderBy: OrderBy, $page: Int, $limit: Int) {
    runs(filter: $filter, orderBy: $orderBy, page: $page, limit: $limit) {
      total
      runs {
        _id
        brandId
        brandName
        createdById
        createdByFullName
        createdOn
        runOn
        lifecycleState
        chainId
        chainName
        topicContext
      }
    }
  }
`);

export const GET_RUN = gql(`
  query GetPrompterRun($runId: ObjectId!) {
    run(runId: $runId) {
        _id
      createdOn
      createdById
      createdByFullName
      finishedOn
      lifecycleState
      brandId
      brandName
      brand {
        _id
        name
        shellId
        shell {
          _id
          name
        }
      }
      chainId
      chainName
      model
      chainA {
        temperature
        messages {
          role
          content
        }
        outputKeys {
          type
          key
          description
        }
      }
      chainB {
        temperature
        messages {
          role
          content
        }
        outputKeys {
          type
          key
          description
        }
      }
      postA
      postB
      winner
      topicContext
      postId
      ideaId
      random
    }
  }
`);

export const GET_USERS_BY_SHELL = gql(`
  query GetUsersByShell {
    getAllUsersByShell {
      _id
      firstName
      avatar
      lastName
      email
    }
  }
`);

export const GET_POST_LIFECYCLE_STATES = gql(`
  query GetPostLifecycleStates($allStates: Boolean) {
    getPostLifecycleStates(allStates: $allStates) {
      lifecycleState
      lifecycleStateDisplay {
        name
        color
        order
        isStateChangeable
        isPostPostable
      }
    }
  }
`);

export const GET_PUBLIC_BRAND = gql(`
  query GetPublicBrand($brandId: ObjectId!) {
    getPublicBrand(brandId: $brandId) {
      _id
      name
      shellId
      typeBrand
      backgroundColor
      account {
        id
        type
        name
        token
        avatar
        exp
      }
    }
  }
`);

export const GET_BRANDS = gql(`
  query GetBrands($allBrands: Boolean) {
    getBrands(allBrands: $allBrands) {
      _id
      name
      shellId
      shell {
        _id
        name
      }
      email
      typeBrand
      createdOn
      createdById
      phone
      userId
      user {
        _id
        email
        firstName
        role
        shellId
        shellName
        notifications {
          email {
            allowed
          }
        }
      }
      groupsIds
      groups {
        _id
        name
        shellId
      }
      brandManagers {
        _id
        firstName
        lastName
        email
        role
        avatar
        shellId
        shellName
      }
      backgroundColor
      companyBrandId
      approval {
        required
        email
        whatsapp
      }
      account {
        id
        type
        name
        token
        exp
        avatar
      }
      contentCreation {
        language
        bio
        hashtags
        signature
        toneVoice
        negatives
        otherIndustries
        industry
        subIndustry
        competitors
        competitiveAdvantage
        position
        themeTalk
        targetBrand
        targetPublic
        goalsBrand
        otherGoalsBrand
        valuesBrand
        corporateValues
        requireContent
        responsabilities
        perspective
        numberEmojis
        numberHashtags
        textLength
        themeExtra
        uniqueBrand
        themeNegative
        corporatePostFirstPart
        corporatePostSecondPart
        corporatePostThirdPart
        otherCorporatePostFirstPart
        otherCorporatePostSecondPart
        otherCorporatePostThirdPart
        contentConsumption
        otherContentConsumption
        firstPost
        secondPost
        thirdPost
        fourthPost
        worldLeaderInspiration
        industryLeaderInspiration
        hobbies
        artistsInspiration
        valuesBrand
        corporateValues
        politicalSocialActivityBrand
        trendingTopics
        trustedMedia
        corporateTarget
        corporateIdeas
        corporateVoice
      }
    }
  }
`);

export const GET_METRICS_COMPANY = gql(`
  query GetMetricsCompany($dateInit: Date!, $dateEnd: Date!)
  {
    getMetricsCompany(
      dateInit: $dateInit, 
      dateEnd: $dateEnd
      ) {
      followers
      followers30DaysAgo
      postsLast30Days
      posts30DaysBeforeLast30Days
      impressions
      impressions30DaysAgo
      interactions
      interactions30DaysAgo
      followersMonthly {
        month
        followers
        postsByMonth
      }
      followersMonthlyDetail {
        day
        followers
        postsByDay
      }
      monthlyData {
        month
        data {
          postCount
          impressionCount
        }

      }
      performingContent {
        postedAt
        content
        brandName
        postUrn
        metrics {
          followers
          numComments
          numLikes
          numViews
          totalShares
        }
    }
        reactionsRange
        commentsRange
        sharesRange
        impressionsRange
    }
  }
`);

export const GET_IDEAS = gql(`
  query GetIdeas($filter: IdeasFilter, $orderBy: OrderBy, $page: Int, $limit: Int) {
    getIdeas(filter: $filter, orderBy: $orderBy, page: $page, limit: $limit) {
      total
      ideas {
        _id
        content
        chainId
        chainName
        brandId
        brandName
        createdOn
        shellId
        priority
        prompterRunIds
      }
    }
  }
`);

export const GET_TAGS_BY_SHELL = gql(`
  query GetTagsByShell($shellId: ObjectId) {
    getTagsByShell(shellId: $shellId) {
      _id
      color
      name
      }
  }
`);
