import { useMutation } from '@apollo/client';
import { Button, Stack } from '@mui/material';
import { useContext } from 'react';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { User } from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { UPDATE_USER, UPDATE_USER_AVATAR } from '../../graphql/mutations';
import Avatar from '../Avatar/Avatar';
import UploadAvatarButton from '../Avatar/UploadAvatarButton';
import SettingsSection from '../SettingsSection/SettingsSection';

const buttonStyle = {
  width: 'fit-content',
  paddingX: 5,
};

const inputStyle = {
  width: '100%',
  maxWidth: '800px',
};

const SettingsProfile = () => {
  const { setSuccessMessage, setErrorMessage } = useContext(SnackbarContext);
  const { user, setUser } = useContext(AuthContext);

  const [uploadAvatar] = useMutation(UPDATE_USER_AVATAR, {
    onCompleted: ({ updateUserAvatar }) => {
      if (updateUserAvatar.success) {
        setSuccessMessage('Your avatar has been updated');

        setUser({
          ...user,
          ...(updateUserAvatar.user as User),
        });
        return;
      }
      setErrorMessage('Oops! There was an error updating your avatar');
    },
    onError: (err) => {
      setErrorMessage(`Profile picture couldn't be updated - ${err.message}`);
    },
  });

  const handleUploadAvatar = (avatar: string) => {
    uploadAvatar({ variables: { avatar } });
  };

  const handleRemoveAvatar = () => {
    uploadAvatar({
      variables: {
        avatar: '',
      },
    });
  };

  const [updateUserNameAndLastName] = useMutation(UPDATE_USER, {
    onError: (err) => {
      setErrorMessage(`Name and last name couldn't be updated - ${err.message}`);
    },
  });

  type FormName = {
    firstName: string;
    lastName: string;
  };

  const handleUpdateUserNameAndLastName = (data: FormName) => {
    const input = {
      email: user?.email || '',
      firstName: data.firstName,
      lastName: data.lastName,
      role: user?.role || [],
      plan: user?.plan || [],
      shellId: user?.shellId || '',
      shellName: user?.shellName || '',
    };
    updateUserNameAndLastName({
      variables: {
        input,
        userId: user?._id || '',
      },
      onCompleted: ({ updateUser }) => {
        if (updateUser.success) {
          setSuccessMessage('Your profile has been updated');
          setUser({
            ...user,
            ...(updateUser.user as User),
          });
          return;
        }

        setErrorMessage('Oops! There was an error updating your profile');
      },
      onError: (err) => {
        setErrorMessage(`Name and last name couldn't be updated - ${err.message}`);
      },
    });
  };

  return (
    <>
      <SettingsSection
        title="Avatar"
        subtitle="You can change your avatar here or remove the current avatar. This will be your profile picture on magnettu."
      >
        <Stack direction={{ sm: 'column', md: 'row' }} gap={2}>
          <Avatar avatar={user?.avatar || undefined} size={100} />
          <Stack direction={'column'} spacing={1} sx={{ alignItems: 'start' }}>
            <UploadAvatarButton
              setAvatar={handleUploadAvatar}
              setErrorMessage={setErrorMessage}
              buttonProps={{ variant: 'contained', sx: buttonStyle }}
            />
            {user?.avatar && (
              <Button variant="outlined" onClick={handleRemoveAvatar} sx={buttonStyle}>
                Remove avatar
              </Button>
            )}
          </Stack>
        </Stack>
      </SettingsSection>
      <SettingsSection title="User information" subtitle="">
        <FormContainer
          defaultValues={{
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
          }}
          onSuccess={(data) => {
            handleUpdateUserNameAndLastName(data);
          }}
        >
          <Stack sx={{ mb: 3, ...inputStyle }} direction="column" spacing={2}>
            <TextFieldElement name="firstName" label="First name" required />
            <TextFieldElement name="lastName" label="Last name" required />
          </Stack>
          <Button
            variant="contained"
            type="submit"
            sx={buttonStyle}
            data-cy="submit-button"
          >
            Save
          </Button>
        </FormContainer>
      </SettingsSection>
    </>
  );
};

export default SettingsProfile;
