import {
  Box,
  Grid,
  MenuItem,
  Select,
  Slider,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import { Control, Controller, FieldErrors, UseFormWatch } from 'react-hook-form';
import {
  BrandInput,
  firstPartOptions,
  perspectiveOptions,
  secondPartOptions,
  textLengthOptions,
  thirdPartOptions,
} from './BrandData';
import { Info } from '@mui/icons-material';

const marks = [
  { value: 0, label: '0' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
];

interface BrandPersonalContentContextFormProps {
  control: Control<BrandInput, any>;
  inputStyle: any;
  errors: FieldErrors<BrandInput>;
  watch: UseFormWatch<BrandInput>;
}

const BrandPersonalContentContextForm = ({
  control,
  inputStyle,
  errors,
  watch,
}: BrandPersonalContentContextFormProps) => {
  const watchCorporatePostFirstPart = watch('corporatePostFirstPart');
  const watchCorporatePostSecondPart = watch('corporatePostSecondPart');
  const watchCorporatePostThirdPart = watch('corporatePostThirdPart');

  return (
    <Box display="flex" flexDirection="column" gap={1} fontSize="13px">
      <Typography fontSize="inherit">
        Could you describe the key responsibilities of your job?
      </Typography>
      <Controller
        name="responsabilities"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField
            multiline
            rows={3}
            required
            value={value}
            onChange={onChange}
            sx={inputStyle}
          />
        )}
      />
      <Typography fontSize="inherit">
        In which perspective or point of view do you prefer to write?
      </Typography>
      <Controller
        name="perspective"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select required value={value || ''} onChange={onChange} sx={inputStyle}>
            {perspectiveOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      <Typography fontSize="inherit">
        Is there anything you definitely do not want to be part of your personal brand?
      </Typography>
      <Controller
        name="negatives"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField required value={value || ''} onChange={onChange} sx={inputStyle} />
        )}
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box>
            <Tooltip
              title="Please provide a list of the most used emojis you use in your posts."
              placement="top-start"
            >
              <Typography fontSize="inherit">
                Preference for Emoji and hashtag use in posts{' '}
                <Info fontSize="small" color="primary" />
              </Typography>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography fontSize="inherit">Emojis</Typography>
          <Controller
            name="numberEmojis"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Slider
                value={value || 0}
                onChange={onChange}
                sx={inputStyle}
                marks={marks}
                min={0}
                max={10}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography fontSize="inherit">Hashtags</Typography>
          <Controller
            name="numberHashtags"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Slider
                value={value || 0}
                onChange={onChange}
                sx={inputStyle}
                marks={marks}
                min={0}
                max={10}
              />
            )}
          />
        </Grid>
      </Grid>

      <Typography fontSize="inherit">Most used hashtags</Typography>
      <Controller
        name="hashtags"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField required value={value} onChange={onChange} sx={inputStyle} />
        )}
      />
      <Typography fontSize="inherit">
        How long would you like your texts to be?
      </Typography>
      <Controller
        name="textLength"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select required value={value || ''} onChange={onChange} sx={inputStyle}>
            {textLengthOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      <Typography fontSize="inherit">
        Are there other important aspects or topics for us to know about that matter to
        you?
      </Typography>
      <Controller
        name="themeExtra"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField
            multiline
            rows={3}
            required
            value={value}
            onChange={onChange}
            sx={inputStyle}
          />
        )}
      />
      <Typography fontSize="inherit">
        What do you think makes your personal brand unique?
      </Typography>
      <Controller
        name="uniqueBrand"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField
            multiline
            rows={3}
            required
            value={value}
            onChange={onChange}
            sx={inputStyle}
          />
        )}
      />
      <Typography fontSize="inherit">
        What words or phrases do you prefer to avoid in your posts?
      </Typography>
      <Controller
        name="themeNegative"
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField required value={value} onChange={onChange} sx={inputStyle} />
        )}
      />
      <Typography fontSize="inherit">"How are your posts organized?</Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography fontSize="inherit">First part</Typography>
          <Controller
            name="corporatePostFirstPart"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                required
                value={value || ''}
                onChange={onChange}
                sx={inputStyle}
                multiple
              >
                {firstPartOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {watchCorporatePostFirstPart?.includes('Other (specify)') && (
            <Controller
              name="otherCorporatePostFirstPart"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextField
                  required
                  value={value}
                  onChange={onChange}
                  sx={inputStyle}
                  placeholder="Other (please specify)"
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography fontSize="inherit">Second part</Typography>
          <Controller
            name="corporatePostSecondPart"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                required
                value={value || ''}
                onChange={onChange}
                sx={inputStyle}
                multiple
              >
                {secondPartOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {watchCorporatePostSecondPart?.includes('Other (specify)') && (
            <Controller
              name="otherCorporatePostSecondPart"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextField
                  required
                  value={value}
                  onChange={onChange}
                  sx={inputStyle}
                  placeholder="Other (please specify)"
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography fontSize="inherit">Third part</Typography>
          <Controller
            name="corporatePostThirdPart"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                required
                value={value || ''}
                onChange={onChange}
                sx={inputStyle}
                multiple
              >
                {thirdPartOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {watchCorporatePostThirdPart?.includes('Other (specify)') && (
            <Controller
              name="otherCorporatePostThirdPart"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextField
                  required
                  value={value}
                  onChange={onChange}
                  sx={inputStyle}
                  placeholder="Other (please specify)"
                />
              )}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default BrandPersonalContentContextForm;
