import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { AuthContext } from './AuthContext';
import { io, Socket } from 'socket.io-client';

export interface WebSocketContextType {
  socket: Socket | null;
  isSocketConnected: boolean;
}

const initialState: WebSocketContextType = {
  socket: null,
  isSocketConnected: false,
};

export const WebSocketContext = createContext<WebSocketContextType>(initialState);

export const WebSocketProvider = ({
  children,
  postId,
}: {
  children: React.ReactNode;
  postId: string | null;
}) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isSocketConnected, setIsSocketConnected] = useState<boolean>(
    socket?.connected || false,
  );
  const { user, loading, anonymousId } = useContext(AuthContext);

  const socketRef = useRef<Socket | null>(null);
  const socketUrlRef = useRef<string | null>(null);

  useEffect(() => {
    socketRef.current = socket;
  }, [socket]);

  const { reset, pause } = useIdleTimer({
    timeout: 1000 * 60 * 5, // 5 minutes of inactivity
    onIdle: () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    },
    onActive: () => {
      if (socketRef.current && !socketRef.current.connected && socketUrlRef.current) {
        const queryParams: Record<string, string> = {
          postId: postId!,
        };

        const newSocket = io(socketUrlRef.current, { query: queryParams, path: '/post' });
        setSocket(newSocket);
      }
    },
    debounce: 500,
  });

  useEffect(() => {
    // Update isSocketConnected when socket connection status changes
    const handleConnect = () => {
      setIsSocketConnected(true);
    };
    const handleDisconnect = () => setIsSocketConnected(false);

    socket?.on('connect', handleConnect);
    socket?.on('disconnect', handleDisconnect);

    // Clean up event listeners on unmount
    return () => {
      socket?.off('connect', handleConnect);
      socket?.off('disconnect', handleDisconnect);
    };
  }, [socket]);

  useEffect(() => {
    if (postId && !loading) {
      const socketUrl = `${process.env.REACT_APP_WS_SERVER_URL}/post`;
      const queryParams: Record<string, string> = {
        postId: postId,
      };

      if (user?._id) {
        queryParams.userId = user._id;
      } else {
        queryParams.anonymousId = anonymousId;
      }

      socketUrlRef.current = socketUrl;
      const newSocket = io(socketUrl, { query: queryParams, path: '/post' });

      newSocket.on('connect', () => {
        setSocket(newSocket);
      });

      setSocket(newSocket);

      return () => {
        newSocket.disconnect();
      };
    }
  }, [user?._id, postId, loading, anonymousId]);

  useEffect(() => {
    return () => {
      reset();
      pause();
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [pause, reset]);

  return (
    <WebSocketContext.Provider value={{ socket, isSocketConnected }}>
      {children}
    </WebSocketContext.Provider>
  );
};
