import { useQuery } from '@apollo/client';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { MetricTeamResponse, UserRole } from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import { GET_METRICS_TEAM } from '../../graphql/queries';
import BrandsActivityComponent from '../../components/Analitycs/BrandsActivityComponent';

const TeamActivityScreen = () => {
  const { user } = useContext(AuthContext);

  const { data: metricsTeam, loading: loadingMetricsTeam } = useQuery(GET_METRICS_TEAM, {
    fetchPolicy: 'network-only',
    variables: {
      shellId: user?.shellId as string,
    },
    skip:
      !user ||
      (!user.role.includes(UserRole.BrandManager) &&
        !user.role.includes(UserRole.LimitedBrandManager)),
  });

  const loading = loadingMetricsTeam;

  return (
    <>
      {loading ? <LinearProgress /> : <Box height={4} />}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        spacing={2}
        padding={4}
      >
        <Stack direction="column" alignItems="flex-start">
          <Typography variant={'h4'} fontWeight="bold">
            Brands Activity
          </Typography>
          <Typography variant={'body1'}>Analyze the performance of your team</Typography>
        </Stack>
      </Stack>
      {metricsTeam && (
        <Stack direction="column" px={4}>
          <BrandsActivityComponent
            metricsTeam={metricsTeam?.getMetricsTeam as MetricTeamResponse}
          />
        </Stack>
      )}
    </>
  );
};

export default TeamActivityScreen;
