import { useMutation } from '@apollo/client';
import { AddBox, ContentCopy } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Chain, Chat as ChatType, OpenAiRole } from '../../__generated__/graphql';
import Chat from '../../components/Prompter/Chat';
import { DeviceContext } from '../../context/DeviceContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { CREATE_CHAIN, UPDATE_CHAIN } from '../../graphql/mutations';
import { GET_CHAINS } from '../../graphql/queries';

const inputStyle = {
  width: '100%',
};

export interface ChainForm {
  chain: ChatType[];
}

const CreateChain = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const chain: Chain = state?.chain;

  const { isMobile } = useContext(DeviceContext);
  const { setSuccessMessage, setErrorMessage } = useContext(SnackbarContext);

  const { control, handleSubmit, register, watch, setValue } = useForm<Chain>({
    defaultValues: {
      name: chain?.name || '',
      description: chain?.description || '',
      resultKey: chain?.resultKey || '',
      chainA: chain?.chainA || [],
      chainB: chain?.chainB || [],
      streamed: chain?.streamed || false,
    },
  });

  const streamed = watch('streamed');

  const chainAField = useFieldArray({
    control: control,
    name: 'chainA',
  });

  const chainBField = useFieldArray({
    control: control,
    name: 'chainB',
  });

  const [createChain] = useMutation(CREATE_CHAIN, {
    onCompleted: ({ createChain }) => {
      if (createChain.success) {
        setSuccessMessage(createChain.message || 'Chain created successfully');
        navigate('/content-manager');
        return;
      }

      setErrorMessage(createChain.message || 'There was an error saving your chain');
    },
    refetchQueries: [GET_CHAINS],
  });

  const [updateChain] = useMutation(UPDATE_CHAIN, {
    onCompleted: ({ updateChain }) => {
      if (updateChain.success) {
        setSuccessMessage(updateChain.message || 'Chain updated successfully');
        navigate('/content-manager');
        return;
      }

      setErrorMessage(updateChain.message || 'There was an error saving your chain');
    },
  });

  const handleDuplicate = (id: string) => {
    const sourceChain = id === 'chainA' ? chainAField.fields : chainBField.fields;
    const targetChainName = id === 'chainA' ? 'chainB' : 'chainA';

    setValue(targetChainName, [...sourceChain]);
  };

  const onSuccess = ({
    name,
    description,
    resultKey,
    chainA,
    chainB,
    streamed,
  }: {
    name: string;
    description: string;
    resultKey: string;
    chainA: ChatType[];
    chainB: ChatType[];
    streamed: boolean;
  }) => {
    const initializeChain = (chain: ChatType[]) => {
      return chain.map((chat) => ({
        messages: chat.messages.map((message) => ({
          role: message.role,
          content: message.content,
        })),
        temperature: Number(chat.temperature),
        outputKeys: chat.outputKeys.map((outputKey) => ({
          type: 'string',
          key: outputKey.key,
          description: outputKey.description,
        })),
      }));
    };

    const input = {
      name,
      description,
      resultKey,
      chainA: initializeChain(chainA),
      chainB: initializeChain(chainB),
      streamed,
    };

    chain
      ? updateChain({ variables: { chainId: chain._id, input } })
      : createChain({ variables: { input } });
  };

  return (
    <>
      <Stack
        direction="column"
        p={isMobile ? '40px 30px 80px 30px' : '40px'}
        spacing={4}
        alignItems={isMobile ? 'center' : 'start'}
      >
        <Typography variant={isMobile ? 'h6' : 'h4'} fontWeight="bold">
          {chain ? 'Edit' : 'New'} Chain
        </Typography>
        <form onSubmit={handleSubmit(onSuccess)} style={{ width: '100%' }}>
          <Stack sx={{ width: '100%' }} direction="column" spacing={2}>
            <TextField
              label="Name"
              autoComplete="off"
              required
              sx={inputStyle}
              {...register('name')}
            />
            <TextField
              label="Description"
              autoComplete="off"
              required
              multiline
              sx={inputStyle}
              {...register('description')}
            />
            <TextField
              label="Result Key"
              autoComplete="off"
              required
              sx={inputStyle}
              {...register('resultKey')}
            />
            <FormControlLabel
              control={<Checkbox {...register('streamed')} checked={streamed} />}
              label="Streamed"
            />
            <Divider />
            <Stack direction="row" spacing={3}>
              {[
                { id: 'chainA', chainField: chainAField, name: 'Chain A' },
                ...(streamed
                  ? []
                  : [{ id: 'chainB', chainField: chainBField, name: 'Chain B' }]),
              ].map(({ id, chainField, name }) => (
                <Stack key={id} direction={'column'} width={'100%'} spacing={1}>
                  <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography variant="h6" fontWeight="bold">
                      {name}
                    </Typography>
                    <Button
                      onClick={() => handleDuplicate(id)}
                      startIcon={<ContentCopy />}
                      disabled={streamed}
                    >
                      Duplicate
                    </Button>
                  </Stack>
                  {chainField.fields.map((field, index) => (
                    <>
                      <Chat
                        key={field.id}
                        chat={field as ChatType}
                        register={register}
                        control={control}
                        chainId={id as 'chainA' | 'chainB'}
                        index={index}
                        move={chainField.move}
                        remove={chainField.remove}
                        disabled={false}
                        disableMoveDown={index === chainField.fields.length - 1}
                        outputKeys={!streamed}
                      />
                    </>
                  ))}
                  <Button
                    variant="text"
                    type="button"
                    size="small"
                    startIcon={<AddBox />}
                    disabled={streamed && chainField.fields.length === 1}
                    onClick={() => {
                      chainField.append({
                        temperature: 0.0,
                        messages: [
                          {
                            role: OpenAiRole.System,
                            content: '',
                          },
                          {
                            role: OpenAiRole.User,
                            content: '',
                          },
                        ],
                        outputKeys: [
                          {
                            type: 'string',
                            key: '',
                            description: '',
                          },
                        ],
                      });
                    }}
                  >
                    Add chat
                  </Button>
                </Stack>
              ))}
            </Stack>
            <Button variant="contained" type="submit">
              {chain ? 'Update' : 'Create'}
            </Button>
          </Stack>
        </form>
      </Stack>
    </>
  );
};

export default CreateChain;
