import { useQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Snackbar,
  Stack,
  Typography,
  Zoom,
} from '@mui/material';
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  signInWithPopup,
} from 'firebase/auth';
import { useContext, useEffect, useState } from 'react';
import { FormContainer, PasswordElement, TextFieldElement } from 'react-hook-form-mui';
import { useNavigate } from 'react-router-dom';
import googleLogo from '../../assets/google-logo.svg';
import { AuthContext } from '../../context/AuthContext';
import { VALIDATE_INVITE } from '../../graphql/queries';

const Signup = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const auth = getAuth();
  const navigate = useNavigate();
  const { user, loading, error: errorAuth } = useContext(AuthContext);

  const error = errorMsg || errorAuth;

  const urlParams = new URLSearchParams(window.location.search);
  const inviteToken = urlParams.get('token');

  useEffect(() => {
    if (!loading && user) {
      navigate('/');
    }
  }, [loading, navigate, user]);

  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token');

  const { data: inviteValidationData, loading: inviteValidationLoading } = useQuery(VALIDATE_INVITE, {
    fetchPolicy: 'network-only',
    variables: {
      token,
    },
    skip: !token,
  });

  const handleSubmit = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    if (!inviteToken || !inviteValidationData?.validateInvite?.success) {
      setErrorMsg('You need an invite to sign up in magnettu');
      return;
    }

    createUserWithEmailAndPassword(auth, email, password).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;

      if (errorCode === 'auth/email-already-in-use') {
        setErrorMsg('The email address is already in use by another account.');
      } else {
        setErrorMsg(errorMessage);
      }
    });
  };

  const handleGoogleSignup = async () => {
    if (!inviteToken || !inviteValidationData?.validateInvite?.success) {
      setErrorMsg('You need an invite to sign up in magnettu');
      return;
    }

    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        const email = result.user.email;
        if (!email || email !== inviteValidationData.validateInvite?.email) {
          // Proceed with the authentication

          // Sign out the user and show an error message
          auth.signOut();
          setErrorMsg(
            `Please use your ${inviteValidationData?.validateInvite?.email} account.`,
          );
        }
      })
      .catch((error) => {
        // Handle errors here
        const errorMessage = error.message;
        setErrorMsg(errorMessage);
      });
  };

  const onCloseSnackbar = () => {
    setErrorMsg('');
  };

  if (loading || inviteValidationLoading) {
    
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ marginY: '25px' }}>
      <FormContainer
        values={{ email: inviteValidationData?.validateInvite?.email || '', password: '' }}
        onSuccess={handleSubmit}
      >
        <Grid container justifyContent="center">
          <Grid item>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{ marginTop: '50px', maxWidth: '350px' }}
            >
              <Typography variant="h4" sx={{ fontWeight: 700, fontSize: '32px' }}>
                Sign up
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 700,
                  fontSize: '18px',
                  color: 'text.secondary',
                  textAlign: 'center',
                }}
              >
                Create your account to unlock the potential of your personal brand
              </Typography>
              <TextFieldElement
                label="Email"
                name="email"
                variant="outlined"
                required
                sx={{ minWidth: '100%' }}
                type="email"
                data-cy="email-input"
                disabled
              />
              <PasswordElement
                label="Password"
                name="password"
                autoComplete="new-password"
                required
                sx={{ minWidth: '100%' }}
                data-cy="password-input"
                validation={{
                  required: 'Password is required',
                  minLength: {
                    value: 6,
                    message: 'Password must have at least 6 characters',
                  },
                }}
              />
              {!inviteValidationData?.validateInvite?.success && (
                <Alert severity="error" sx={{ minWidth: '100%', marginBottom: '16px' }}>
                  The invitation has expired. Ask your administrator for a new one.
                </Alert>
              )}
              {!inviteToken && (
                <Alert severity="error" sx={{ minWidth: '100%', marginBottom: '16px' }}>
                  You need an invite to sign up in magnettu
                </Alert>
              )}
              <LoadingButton
                variant="contained"
                type="submit"
                sx={{ minWidth: '100%' }}
                data-cy="signup-button"
                loading={loading}
                loadingPosition="start"
                startIcon={<></>}
              >
                <Typography fontWeight="600">Sign up</Typography>
              </LoadingButton>
              <Divider sx={{ minWidth: '100%' }}>Or</Divider>
              <Button
                variant="outlined"
                onClick={handleGoogleSignup}
                sx={{ minWidth: '100%' }}
              >
                <img
                  src={googleLogo}
                  alt="Google Logo"
                  style={{ width: '24px', marginRight: '8px' }}
                />
                <Typography fontWeight="600">Sign up with Google</Typography>
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </FormContainer>
      <Snackbar
        open={!!error}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        TransitionComponent={Zoom}
        onClose={onCloseSnackbar}
        data-cy="error-snackbar"
      >
        <Alert severity="error" sx={{ width: '100%' }} onClose={onCloseSnackbar}>
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Signup;
