import { AutoAwesome, Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { ChangeEvent, useContext, useRef, useState } from 'react';
import { DeviceContext } from '../../context/DeviceContext';
import { PostContext } from '../../context/PostContext';
import { useStreamedCompletion } from '../../hooks/useStreamedCompletion';
import { Language } from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';

const textareaStyle = {
  width: '100%',
  resize: 'none' as const,
  borderColor: '#BBBBBB',
  fontFamily: 'inherit',
  fontSize: '16px',
  lineHeight: 1.5,
  padding: '10px 10px 10px 10px',
  borderRadius: '4px',
  outline: 'none',
};

interface Props {
  open: boolean;
  onAddText: (text: string) => void;
  onClose: () => void;
}

const AIAssistantModal = ({ open, onClose, onAddText }: Props) => {
  const { isMobile } = useContext(DeviceContext);
  const { postState } = useContext(PostContext);
  const { user } = useContext(AuthContext);
  
  const contentRef = useRef<HTMLTextAreaElement>(null);

  const [loading, setLoading] = useState(false);
  const [userPrompt, setUserPrompt] = useState('');
  const [content, setContent] = useState('');

  const [error, setError] = useState<string | null>(null);
  const [fetchData] = useStreamedCompletion(
    (content) => {
      if (content) {
        setContent((currentContent) => currentContent + content);
      }
    },
    (error) => {
      setError(error);
      setLoading(false);
    },
    () => setLoading(false),
  );

  const language = postState?.post.brand?.contentCreation?.language || Language.English;

  const handleGenerateContent = async () => {
    setContent('');
    setLoading(true);
    fetchData({
      input: userPrompt,
      chainId: '66310b89f1de1c64651db3a2',
      brandId: postState?.post.brandId || undefined,
      userId: user?._id || undefined,
    });
  };

  return (
    <Dialog open={open} fullScreen={isMobile} disableEscapeKeyDown maxWidth={'xl'}>
      <DialogContent>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">Generate Content</Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Stack>
          <Typography variant="body2">
            {`Your content will be generated in ${language}. You can change this preference in your `}
            <Link
              href="/settings"
              color="inherit"
              underline="always"
              style={{ textDecoration: 'underline' }}
            >
              Settings
            </Link>
            .
          </Typography>
          <textarea
            rows={isMobile ? 5 : 3}
            style={textareaStyle}
            data-testid="ai-assistant-textarea"
            placeholder="Example: Write a post about the climate change. Use a bullet list of 3 items with statistics and a quote from a famous person."
            value={userPrompt}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              setUserPrompt(e.target.value);
            }}
          />
          <Stack direction="row" justifyContent={isMobile ? 'flex-end' : 'space-between'}>
            {!isMobile && (
              <Alert
                severity={error ? 'error' : 'info'}
                sx={{ backgroundColor: 'transparent', p: 0 }}
              >
                {error
                  ? error
                  : 'The more precise you are, the better the result will be '}
              </Alert>
            )}
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<AutoAwesome />}
              variant="outlined"
              onClick={handleGenerateContent}
              disabled={userPrompt.length === 0}
              data-testid="ai-assistant-generate-button"
            >
              {/* Span is necessary to avoid bug with google translate: https://github.com/mui/material-ui/issues/27853 */}
              <span>Generate</span>{' '}
            </LoadingButton>
          </Stack>
          {!!content && (
            <>
              <Divider />
              <textarea
                disabled
                ref={contentRef}
                rows={10}
                style={textareaStyle}
                data-testid="ai-assistant-post-generated"
                value={content}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  setContent(e.target.value);
                }}
              />
              <Stack direction="row" justifyContent={'flex-end'}>
                <Button
                  variant="contained"
                  onClick={() => {
                    onAddText(content || '');
                    onClose();
                  }}
                >
                  Add to post
                </Button>
              </Stack>
            </>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default AIAssistantModal;
