import { LoadingButton } from '@mui/lab';
import { ChangeEvent, FC, useState } from 'react';
import { isValidContent, uploadMedia } from '../../utils';

const inputStyle = {
  maxWidth: '500px',
};

interface UploadAvatarButtonProps {
  setAvatar: (avatar: string) => void;
  setErrorMessage: (message: string) => void;
  buttonProps?: {
    variant?: 'text' | 'outlined' | 'contained';
    sx?: object;
  };
}

const UploadAvatarButton: FC<UploadAvatarButtonProps> = ({
  setErrorMessage,
  setAvatar,
  buttonProps,
}) => {
  const [loading, setLoading] = useState(false);


  const handleUploadAvatar = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    if (!isValidContent(e.target.files![0])) {
      setErrorMessage('The avatar size should be less than 200KB');
      return;
    }

    setLoading(true);
    uploadMedia(e.target.files[0], 'avatar')
      .then((url) => {
        setAvatar(url.url!);
        setLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setLoading(false);
      });
  };

  return (
    <LoadingButton
      variant="outlined"
      sx={inputStyle}
      component="label"
      aria-haspopup="true"
      loading={loading}
      {...buttonProps}
    >
      <input hidden accept="image/*" type="file" onChange={handleUploadAvatar} />
      Upload avatar
    </LoadingButton>
  );
};

export default UploadAvatarButton;
