import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import {
  ComposedChart,
  LabelList,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { MetricsCompanyResponse } from '../../__generated__/graphql';
import { formatNumber, months } from '../../utils';

interface Metrics {
  metricCompany: MetricsCompanyResponse;
}

const GraphicReportCompanyTeamPostsFollowers: FC<Metrics> = ({
  metricCompany,
}) => {
  function generateData(metrics: MetricsCompanyResponse) {
    const data = [];
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
  
    let currentFollowersCount = metrics.followers!;
  
    for (let i = 0; i < 6; i++) {
      const monthDate = new Date(currentYear, currentMonth - i, 1);
      const monthName = months[monthDate.getMonth()];
      const monthYearKey = `${monthDate.getFullYear()}-${('0' + (monthDate.getMonth() + 1)).slice(-2)}`;
  
      let followers = 0;
      // let companyPosts = 0;

      if (i === 0) {
        // Para el mes más reciente, usamos los seguidores actuales
        followers = currentFollowersCount ?? 0;
      } else {
        const previousMonthData = metrics.followersMonthly!.find((item) => item!.month === monthYearKey);
        followers = previousMonthData ? currentFollowersCount! - previousMonthData!.followers! : currentFollowersCount ?? 0;
        currentFollowersCount -= previousMonthData ? previousMonthData.followers! : 0;
      }

  
      const newData = {
        name: monthName,
        FollowersCount: formatNumber(followers),
        Followers: followers
      };
  
      data.unshift(newData);
    }
  
    return data;
  }
  

  const data = generateData(metricCompany);
  
  return (
    <Box
      width="100%"
      style={{
        backgroundColor: '#F8F8F8',
        borderRadius: '10px',
        height: '470px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        padding: '2rem',
      }}
    >
      <YAxis orientation="right" yAxisId="right" />
      <Box marginY="1rem" marginLeft="2rem">
        <Typography fontSize="1rem" textAlign="left">
          Followers last 6 months
        </Typography>
      </Box>
      <ResponsiveContainer>
        <ComposedChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 30,
          }}
        >
          <Line
            type="monotone"
            dataKey="Followers"
            stroke="#FF007A"
            strokeWidth={2}
            isAnimationActive={false}
            yAxisId="right"
          >
            <LabelList dataKey="FollowersCount" position="top" fill="black" />
          </Line>
          <XAxis dataKey="name" dy={10} />
          <Tooltip />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default GraphicReportCompanyTeamPostsFollowers;
