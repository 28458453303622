import { useQuery } from '@apollo/client';
import { Alert, Box, LinearProgress, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import {
  MetricByUserResponse,
  MetricsCompanyResponse,
  PerformingContent,
  Tag,
  TypeBrand,
} from '../../__generated__/graphql';
import CompanyPerformance from '../../components/Analitycs/CompanyPerformance';
import IndividualDashboard from '../../components/Analitycs/IndividualDashboard';
import PerformingContentTable from '../../components/Tables/PerformingContentTable';
import { TabPanel } from '../../components/Tabs/TabPanel';
import { AuthContext } from '../../context/AuthContext';
import { GET_METRICS_BY_BRAND, GET_METRICS_COMPANY } from '../../graphql/queries';

const ANALYTICS_DATA_RECENCY_THRESHOLD = 7; // Days

const Analytics = () => {
  const init = new Date();
  init.setDate(init.getDate() - 30);
  const end = new Date();

  const { user } = useContext(AuthContext);
  const brandSelected = user?.brandSelected;
  const [tab, setTab] = useState(0);

  const [dateInit, setDateInit] = useState<Date>(init);
  const [dateEnd, setDateEnd] = useState<Date>(end);
  const [metricsBrand, setMetricsBrand] = useState<PerformingContent[]>([]);

  const updateMetricsBrandWithTags = (tags: Tag[], postId: string) => {
    const updatedMetricsBrand = metricsBrand.map((metric) => {
      if (metric.postId === postId) {
        return { ...metric, tags: tags };
      }
      return metric;
    });

    setMetricsBrand(updatedMetricsBrand);
  };

  const changeDateFilter = (dateInitInput: string, dateEndInput: string) => {
    const dateInitISO = new Date(dateInitInput);
    const dateEndISO = new Date(dateEndInput);

    if (dateInit === dateInitISO && dateEnd === dateEndISO) return;

    setDateInit(dateInitISO);
    setDateEnd(dateEndISO);
  };



  const { data: metricsCompany, loading: loadingMetricsCompany,  } = useQuery(
    GET_METRICS_COMPANY,
    {
      fetchPolicy: 'network-only',
      variables: {
        dateInit: dateInit,
        dateEnd: dateEnd,
      },
      skip: brandSelected?.typeBrand === TypeBrand.Personal || !user,
    },
  );

  const { data: metricsUser, loading: loadingMetricsUser } = useQuery(
    GET_METRICS_BY_BRAND,
    {
      fetchPolicy: 'network-only',
      variables: {
        brandId: brandSelected?._id!,
        dateInit: dateInit,
        dateEnd: dateEnd,
      },
      skip: !brandSelected || brandSelected?.typeBrand === TypeBrand.Corporate,
      onCompleted: (data) => {
        setMetricsBrand(data.getMetricsByBrand?.performingContent as PerformingContent[]);
      }
    },
  );

  const loading = loadingMetricsUser || loadingMetricsCompany;

  const tabStyle = {
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 'bold',
    color: 'text.primary',
  };

  // Determine if the analytics data was updated within the last 7 days.
  const analyticsDataRecentlyUpdated =
    brandSelected?.lastMetricsUpdate &&
    (new Date().getTime() - new Date(brandSelected?.lastMetricsUpdate).getTime()) /
      (1000 * 60 * 60 * 24) <=
      ANALYTICS_DATA_RECENCY_THRESHOLD;

  const tabsConfig = [
    ...(brandSelected?.typeBrand === TypeBrand.Personal
      ? [
          {
            label: `Performance`,
            content: metricsUser && (
              <>
                {!brandSelected?.lastMetricsUpdate && (
                  <Alert severity="info" sx={{ mb: 2 }}>
                    <strong>Looks like this brand doesn't have any data yet.</strong> Make
                    sure to install our{' '}
                    <a
                      href="https://chrome.google.com/webstore/detail/magnett%C3%BC-for-linkedin/gbhgeemkcacibokmfjoimkgohejgehap"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Chrome Extension
                    </a>{' '}
                    to start tracking your performance.
                  </Alert>
                )}

                {brandSelected?.lastMetricsUpdate &&
                  !analyticsDataRecentlyUpdated &&
                  !brandSelected && (
                    <Alert severity="warning" sx={{ mb: 2 }}>
                      <strong>
                        It has been more than {ANALYTICS_DATA_RECENCY_THRESHOLD} days
                        since we last received your analytics data.
                      </strong>{' '}
                      Please check if our{' '}
                      <a
                        href="https://chrome.google.com/webstore/detail/magnett%C3%BC-for-linkedin/gbhgeemkcacibokmfjoimkgohejgehap"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Chrome Extension
                      </a>{' '}
                      is correctly installed.
                    </Alert>
                  )}
                <IndividualDashboard
                  data={metricsUser.getMetricsByBrand as MetricByUserResponse}
                />
              </>
            ),
          },
          {
            label: `Content Table`,
            content: (
              <PerformingContentTable
                loadingButton={loading}
                data={metricsBrand}
                
                dateFilter={changeDateFilter}
                userOrShell="user"
                updateMetricsBrandWithTags={updateMetricsBrandWithTags}
                

              />
            ),
          },
        ]
      : []),
    ...(brandSelected?.typeBrand === TypeBrand.Corporate
      ? [
          {
            label: 'Performance',
            content: metricsCompany && (
              <>
                {!brandSelected?.account ? (
                  <Alert severity="info" sx={{ mb: 2 }}>
                    <strong>
                      It looks like this brand doesn't have an affiliated account.{' '}
                    </strong>
                    Please affiliate an account to enjoy and access all the details of
                    your performance.
                  </Alert>
                ) : (
                  <CompanyPerformance
                    metricsCompany={
                      metricsCompany.getMetricsCompany as MetricsCompanyResponse
                    }
                  />
                )}
              </>
            ),
          },
          {
            label: `Content Table`,
            content: (
              <PerformingContentTable
                loadingButton={loading}
                data={metricsBrand}
                dateFilter={changeDateFilter}
                userOrShell="user"
                reactionsRange={metricsCompany?.getMetricsCompany?.reactionsRange || 0}
                commentsRange={metricsCompany?.getMetricsCompany?.commentsRange || 0}
                sharesRange={metricsCompany?.getMetricsCompany?.sharesRange || 0}
                impressionsRange={
                  metricsCompany?.getMetricsCompany?.impressionsRange || 0
                }
                dateInit={dateInit}
                dateEnd={dateEnd}
                isBrandCompany
                updateMetricsBrandWithTags={updateMetricsBrandWithTags}
              />
            ),
          },
        ]
      : []),
  ];

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  return (
    <>
      {loading ? <LinearProgress /> : <Box height={4} />}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        spacing={2}
        p={4}
      >
        <Stack direction="column" alignItems="flex-start">
          <Typography variant={'h4'} fontWeight="bold">
            Analytics
          </Typography>
          <Typography variant={'body1'}>
            See your posts' impact and fine-tune your strategy!
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="column" px={4}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          sx={{ borderBottom: 1, borderRadius: 0, borderColor: 'grey.100', mb: 4 }}
        >
          {tabsConfig.map((tabConfig, index) => (
            <Tab disableRipple key={index} label={tabConfig.label} sx={tabStyle} />
          ))}
        </Tabs>
        {tabsConfig.map((tabConfig, index) => (
          <TabPanel value={tab} index={index} sx={{ p: 0 }} key={index}>
            {tabConfig.content}
          </TabPanel>
        ))}
      </Stack>
    </>
  );
};

export default Analytics;
