import { Close, CheckCircle } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  Stack,
  Typography,
  IconButton,
  Divider,
  Button,
  Box,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
} from '@mui/material';
import React, { FC } from 'react';
import { Control, Controller, UseFormHandleSubmit } from 'react-hook-form';
import { getFullName } from '../../utils';
import { Brand, User } from '../../__generated__/graphql';
import Avatar from '../Avatar/Avatar';

interface EditMemberModalProps {
  openModalChangeRole: boolean;
  handleCloseModalChangeRole: () => void;
  loading: boolean;
  memberSelected: User | null;
  isBrandManager: boolean;
  setIsBrandManager: (value: boolean) => void;
  control: Control<
    {
      brands: (string | undefined)[];
    },
    any
  >;
  dataBrands: Brand[];
  loadingUpdateMember: boolean;
  onSubmit: (data: any) => void;
  handleSubmit: UseFormHandleSubmit<{
    brands: (string | undefined)[];
  }>;
}

const EditMemberModal: FC<EditMemberModalProps> = ({
  openModalChangeRole,
  handleCloseModalChangeRole,
  loading,
  memberSelected,
  isBrandManager,
  setIsBrandManager,
  control,
  dataBrands,
  loadingUpdateMember,
  onSubmit,
  handleSubmit,
}) => {
  return (
    <Dialog
      open={openModalChangeRole}
      onClose={handleCloseModalChangeRole}
      PaperProps={{
        sx: {
          width: { xs: '100%', sm: '555px' },
          height: 'auto',
          minHeight: '300px',
          padding: 2,
        },
      }}
    >
      <Stack>
        <Stack>
          <Typography fontWeight={700} fontSize={25}>
            Edit this member
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseModalChangeRole}
            disabled={loading}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <Close />
          </IconButton>
        </Stack>
        <Divider
          sx={{
            marginY: '1rem',
          }}
        />

        <Box
          display="flex"
          width="100%"
          alignItems="center"
          gap={1}
          marginTop="0.3rem"
          marginBottom={1}
          sx={{
            backgroundColor: 'white',
            height: '56px',
          }}
        >
          <Avatar
            name={getFullName(memberSelected)}
            backgroundColor={memberSelected?.brand?.backgroundColor || undefined}
            avatar={memberSelected?.brand?.account?.avatar || undefined}
            size={50}
          />
          <Stack>
            <Typography
              fontSize={19}
              sx={{
                color: 'black',
                fontWeight: 700,
              }}
            >
              {getFullName(memberSelected)}
            </Typography>
            <Typography
              fontSize={'14px'}
              sx={{
                color: 'black',
                fontWeight: 'normal',
              }}
            >
              {memberSelected?.firstName && memberSelected?.email}
            </Typography>
          </Stack>
        </Box>

        <Stack gap={2}>
          <Button
            onClick={() => setIsBrandManager(true)}
            variant={'outlined'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              background: isBrandManager ? '#FF007A14' : 'white',
              fontWeight: 700,
            }}
          >
            Brand Manager
            {isBrandManager && <CheckCircle color="primary" />}
          </Button>

          <Button
            onClick={() => setIsBrandManager(false)}
            variant={'outlined'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              background: !isBrandManager ? '#FF007A14' : 'white',
              fontWeight: 700,
            }}
          >
            Member
            {!isBrandManager && <CheckCircle color="primary" />}
          </Button>
        </Stack>
        <Divider
          sx={{
            marginY: '1rem',
          }}
        />
        <Box
          sx={{
            background: '#FEFBFC',
            padding: '20px',
            borderRadius: '10px',
          }}
        >
          <Typography
            sx={{
              marginBottom: '10px',
            }}
          >
            {isBrandManager
              ? 'Specify which brands this Brand Manager can manage.'
              : 'This member has a personal brand.'}
          </Typography>

          {isBrandManager && (
            <Controller
              name={`brands`}
              control={control}
              render={({ field }) => (
                <Select
                  multiple
                  placeholder="Select brands"
                  value={field.value || []}
                  onChange={field.onChange}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === dataBrands.length) {
                      return 'All';
                    }

                    if (selected.length === 0) {
                      return 'None';
                    }

                    const displayValues = selected?.slice(0, 4).map((value) => {
                      const brand = dataBrands.find((brand) => brand._id === value);
                      return brand ? brand.name : '';
                    });

                    if (selected.length > 4) {
                      return `${displayValues.join(', ')} +${selected.length - 4}`;
                    }

                    return displayValues.join(', ');
                  }}
                  variant="outlined"
                  style={{ width: '100%' }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      if (field.value && field.value.length === dataBrands.length) {
                        field.onChange([]);
                        return;
                      }
                      field.onChange(dataBrands.map((brand) => brand._id));
                    }}
                  >
                    <ListItemText
                      sx={{
                        marginLeft: '10px',
                        fontWeight: 700,
                      }}
                      primary="Will manage all brands"
                      primaryTypographyProps={{
                        fontWeight: 700,
                      }}
                    />

                    <Checkbox checked={field?.value?.length === dataBrands.length} />
                  </MenuItem>
                  <Divider />

                  {dataBrands.map((brand) => (
                    <MenuItem key={brand._id} value={brand._id}>
                      <Avatar
                        avatar={brand?.account?.avatar || ''}
                        name={brand.name}
                        size={40}
                        backgroundColor={brand.backgroundColor || ''}
                      />
                      <ListItemText
                        primary={brand.name}
                        sx={{
                          marginLeft: '10px',
                        }}
                      />
                      <Checkbox
                        checked={
                          field.value && field.value.includes(brand._id) ? true : false
                        }
                      />
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          )}
        </Box>

        <LoadingButton
          variant="contained"
          color="primary"
          size="small"
          onClick={handleSubmit(onSubmit)}
          loading={loadingUpdateMember}
          sx={{
            width: '156px',

            height: '42px',
            marginTop: '20px',
            marginLeft: 'auto',
          }}
        >
          Update & Save
        </LoadingButton>
      </Stack>
    </Dialog>
  );
};

export default EditMemberModal;
