import { useMutation } from '@apollo/client';
import {
  AutoDelete,
  Clear,
  ConnectWithoutContact,
  Delete,
  DoneAll,
  FileCopy,
  KeyboardArrowDown,
  Link,
  LinkedIn,
  RemoveDone,
  Save,
  Schedule,
  Send,
  SupervisorAccount,
  Undo,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  ClickAwayListener,
  Divider,
  Grow,
  MenuList,
  Paper,
  Popper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import { useContext, useEffect, useState } from 'react';
import { PostLifecycleState, PostType, UserRole } from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import { DeviceContext } from '../../context/DeviceContext';
import { PostContext } from '../../context/PostContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { WebSocketContext } from '../../context/WebSocketContext';
import { ENABLE_SHARE_LINK } from '../../graphql/mutations';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import DatePicker from '../DatePicker/DatePicker';
import ConfirmationPostModal from './ConfirmationPostModal';
import { Message } from './PostEditor';

interface Props {
  setError: ({ type, text, action }: Message) => void;
  onClose: (force?: boolean) => void;
}

const CustomMenuItem = ({
  onClick,
  icon,
  text,
  disabled = false,
}: {
  onClick: () => void;
  icon?: any;
  text: string;
  disabled?: boolean;
}) => (
  <MenuItem
    disabled={disabled}
    onClick={onClick}
    sx={{ paddingLeft: 2, fontSize: '14px' }}
  >
    {icon && <ListItemIcon sx={{ minWidth: 40 }}>{icon}</ListItemIcon>}
    {text}
  </MenuItem>
);

const PostActions = ({ setError, onClose }: Props) => {
  const { user } = useContext(AuthContext);
  const { isMobile } = useContext(DeviceContext);
  const { postState } = useContext(PostContext);
  const { setSuccessMessage } = useContext(SnackbarContext);
  const { socket, isSocketConnected } = useContext(WebSocketContext);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [datePickerScheduleOpen, setDatePickerScheduleOpen] = useState<boolean>(false);
  const [unpublishModalOpen, setUnpublishModelOpen] = useState<boolean>(false);
  const [deletePostModalOpen, setDeletePostModalOpen] = useState<boolean>(false);
  const [savingStatus, setSavingStatus] = useState<string>('Syncing...');

  const [enableShareLink] = useMutation(ENABLE_SHARE_LINK);

  const theme = useTheme();

  const open = Boolean(anchorEl) && !datePickerScheduleOpen;
  const { post, status, handlers } = postState ?? {};

  const postScheduleDefaultDate = new Date(post?.schedule ? post?.schedule : Date.now());
  postScheduleDefaultDate.setHours(12, 0, 0, 0);

  const closeEditor = (force: boolean = false) => {
    if (!user) {
      handleCloseActionMenu();
      return;
    }
    onClose(force);
  };

  const handleOpenActionMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActionMenu = () => {
    setAnchorEl(null);
  };

  const handleSchedule = () => {
    setDatePickerScheduleOpen(true);
  };

  const handleRemoveSchedule = () => {
    handlers?.handleRemoveSchedule();
    handleCloseActionMenu();
  };

  const handleSubmitButton = () => {
    if (post?.type === PostType.Insights) {
      handlers?.handlePostOnLinkedin();
      return;
    }
    handlers?.handleOpenConfirmationPostsDialog();
  };

  const handleSchedulePostSubmit = (date: Date) => {
    handlers?.handleScheduleChange(date, () => {
      setSuccessMessage('Post scheduled successfully 🎉');
      closeEditor(true);
    });

    setDatePickerScheduleOpen(false);
    handleCloseActionMenu();
  };

  const handleUnpublishPost = () => {
    handlers?.handleUnpublishLinkedinPost();
    setUnpublishModelOpen(false);
    handleCloseActionMenu();
  };

  const handleCloseModalDelete = () => {
    setUnpublishModelOpen(false);
  };

  const handleViewOnLinkedin = () => {
    window.open(
      `https://www.linkedin.com/feed/update/${post?.linkedin?.postUrn}`,
      '_blank',
      'noreferrer',
    );

    handleCloseActionMenu();
  };

  const handleUnpublishLinkedin = () => {
    setUnpublishModelOpen(true);
    handleCloseActionMenu();
  };

  const handleCreateAdvocacyPost = () => {
    handlers?.handleCreateAdvocacy(closeEditor);
    handleCloseActionMenu();
  };

  const handleUpdateAdvocacyPost = () => {
    handlers?.handleUpdateAdvocacy(() => {
      setSuccessMessage('Post updated successfully');
      closeEditor();
    });
    handleCloseActionMenu();
  };

  const handleSaveAsDraft = () => {
    handlers?.handleSaveAsDraft({
      callback: () => {
        closeEditor(true);
        setSuccessMessage('The post has been saved as a draft.');
      },
    });
  };

  const handleSendToContentBoard = () => {
    handleCloseActionMenu();

    if (status?.isNew) {
      handlers?.handleSaveAsDraft({
        callback: () => {
          closeEditor(true);
          setSuccessMessage('The post has been submitted to the user content board.');
        },
        lifecycleState: PostLifecycleState.Ready,
      });
      return;
    }

    handlers?.handleStateChange(PostLifecycleState.Ready);
    closeEditor();
    setSuccessMessage('The post has been submitted to the user content board.');
  };

  const handleTurnIntoAdvocacy = () => {
    handlers?.handleTurnIntoAdvocacy();
    handleCloseActionMenu();
  };

  const handleCloseDatepicker = () => {
    setDatePickerScheduleOpen(false);
  };

  const handleCopyLink = () => {
    if (!post || !post._id) {
      setError({ type: 'error', text: 'Post cannot be shared until it has been saved' });
      return;
    }

    navigator.clipboard.writeText(`${window.location.origin}/post/${post._id}`);
    setSuccessMessage('Post link copied to your clipboard');
    handleCloseActionMenu();

    if (!post.shareLink || !post.shareLink.share) {
      enableShareLink({
        variables: {
          postId: post._id,
        },
      });
    }
  };

  const handleRevertApproval = () => {
    handleCloseActionMenu();

    if (status?.isScheduled) {
      handlers?.handleRemoveSchedule(() =>
        handlers?.handleStateChange(PostLifecycleState.PendingApproval),
      );

      return;
    }

    handlers?.handleStateChange(PostLifecycleState.PendingApproval);
  };

  const handleApprove = () => {
    handlers?.handleStateChange(PostLifecycleState.Approved);
    setSuccessMessage('The post has been approved');
    closeEditor();
  };

  const handleDiscard = () => {
    handlers?.handleStateChange(PostLifecycleState.Discarded);
    setSuccessMessage('The post has been discarded');
    closeEditor();
  };

  const handleReturnToDraft = () => {
    handlers?.handleStateChange(PostLifecycleState.Draft);
    handleCloseActionMenu();
  };

  const handleDeletePost = () => {
    handleCloseActionMenu();
    handlers?.handleDeletePost(() => closeEditor());
  };

  const handleRequestApproval = () => {
    if (status?.isNew) {
      handlers?.handleSaveAsDraft({
        callback: () => {
          closeEditor(true);
          setSuccessMessage('A content approval request has been sent to the user.');
        },
        lifecycleState: PostLifecycleState.PendingApproval,
      });

      return;
    }

    handlers?.handleStateChange(PostLifecycleState.PendingApproval);
    setSuccessMessage('Post approval has been requested');
    closeEditor();
  };

  const handleDuplicatePost = () => {
    handlers?.handleDuplicate(() => {
      setSuccessMessage('The post has been duplicated and is now available as a draft.');
    });
    handleCloseActionMenu();
  };

  useEffect(() => {
    if (
      postState?.status.isCurrentUserEditing &&
      !postState.status.isEditDisabled &&
      !postState?.status.isNew &&
      !postState?.status.isSaved
    ) {
      setSavingStatus('Saving...');
    } else if (socket && isSocketConnected && !postState?.status.isNew) {
      setSavingStatus('Your post has been saved');
    } else if (!postState?.status.isNew && (!socket || !isSocketConnected)) {
      setSavingStatus('You are offline');
    }
  }, [socket, postState, isSocketConnected, isMobile, theme.palette.grey]);

  const unpublishLinkedinPost = status?.isPosted;
  const viewOnLinkedIn = status?.isPosted;
  const convertToAdvocacyPost =
    !status?.isPosted &&
    !status?.isAdvocacyParent &&
    !status?.isScheduled &&
    user?.role.includes(UserRole.AdvocacyManager);

  const postOnLinkedin = !status?.isAdvocacyParent && !status?.isPosted;

  const saveAsDraft = !status?.isPosted && !status?.isAdvocacyParent && status?.isNew;

  const returnToDraft =
    !status?.isPosted &&
    !status?.isAdvocacyParent &&
    !status?.isAdvocacy &&
    post?.lifecycleState &&
    [
      PostLifecycleState.Ready,
      PostLifecycleState.Approved,
      PostLifecycleState.Discarded,
      PostLifecycleState.PendingApproval,
      PostLifecycleState.Error,
    ].includes(post?.lifecycleState);

  const sendTo =
    !status?.isAdvocacyParent &&
    post?.brandId !== user?.brandId &&
    post?.lifecycleState &&
    [PostLifecycleState.Draft, PostLifecycleState.Error].includes(post?.lifecycleState);

  const baseSchedule = !status?.isPosted && !status?.isAdvocacyParent;

  const schedule = baseSchedule && post?.lifecycleState !== PostLifecycleState.Scheduled;
  const removeSchedule =
    baseSchedule && post?.lifecycleState === PostLifecycleState.Scheduled;

  const createAdvocacy =
    !status?.isPosted &&
    status?.isAdvocacyParent &&
    status.isNew &&
    user?.role.includes(UserRole.AdvocacyManager);

  const updateAdvocacy =
    !status?.isPosted &&
    status?.isAdvocacyParent &&
    !status.isNew &&
    user?.role.includes(UserRole.AdvocacyManager);

  const copyLink = !status?.isPosted && !status?.isAdvocacyParent && !status?.isNew;

  const requestApproval =
    !status?.isPosted &&
    !status?.isAdvocacyParent &&
    !status?.isScheduled &&
    post?.lifecycleState !== PostLifecycleState.Approved;

  const deletePost =
    !status?.isAdvocacyParent &&
    post?.lifecycleState &&
    [
      PostLifecycleState.Draft,
      PostLifecycleState.Discarded,
      PostLifecycleState.Error,
    ].includes(post?.lifecycleState);

  const revertApproval =
    !status?.isAdvocacyParent &&
    post?.lifecycleState &&
    [PostLifecycleState.Approved, PostLifecycleState.Scheduled].includes(
      post?.lifecycleState,
    );

  const approve =
    !status?.isAdvocacyParent &&
    post?.lifecycleState &&
    [PostLifecycleState.Discarded, PostLifecycleState.PendingApproval].includes(
      post?.lifecycleState,
    );

  const discard =
    !status?.isAdvocacyParent &&
    post?.lifecycleState &&
    [
      PostLifecycleState.Ready,
      PostLifecycleState.Approved,
      PostLifecycleState.PendingApproval,
      PostLifecycleState.Error,
    ].includes(post?.lifecycleState);

  const duplicate = !status?.isAdvocacyParent && !status?.isNew;

  let actionButtonText = 'Actions';
  if (status?.isPosting) actionButtonText = 'Posting...';
  else if (status?.isUnposting) actionButtonText = 'Unpublishing...';

  const menuItems: {
    onClick: () => void;
    icon?: JSX.Element;
    text: string;
    disabled?: boolean;
  }[] = [];

  if (postOnLinkedin) {
    menuItems.push({
      onClick: handleSubmitButton,
      icon: <LinkedIn sx={{ color: '#0963CB' }} fontSize="small" />,
      text: 'Post on LinkedIn now',
      disabled: status?.isSubmitDisabled,
    });
  }

  if (schedule) {
    menuItems.push({
      onClick: handleSchedule,
      icon: <Schedule sx={{ color: 'primary.main' }} fontSize="small" />,
      text: 'Post on Scheduled Date',
      disabled: !status?.isValid || status?.isSubmitDisabled,
    });
  }

  if (requestApproval) {
    menuItems.push({
      onClick: handleRequestApproval,
      icon: <SupervisorAccount fontSize="small" />,
      text: 'Request Approval',
    });
  }

  if (sendTo) {
    menuItems.push({
      onClick: handleSendToContentBoard,
      icon: <Send sx={{ color: '#8DC391' }} fontSize="small" />,
      text: `Send to Content Board`,
      disabled: !status?.isSavable,
    });
  }

  if (deletePost) {
    menuItems.push({
      onClick: () => setDeletePostModalOpen(true),
      icon: <Delete fontSize="small" />,
      text: 'Delete',
    });
  }

  if (removeSchedule) {
    menuItems.push({
      onClick: handleRemoveSchedule,
      icon: <AutoDelete sx={{ color: 'primary.main' }} fontSize="small" />,
      text: 'Remove Schedule',
    });
  }

  if (revertApproval) {
    menuItems.push({
      onClick: handleRevertApproval,
      icon: <RemoveDone fontSize="small" />,
      text: 'Revert Approval',
    });
  }

  if (approve) {
    menuItems.push({
      onClick: handleApprove,
      icon: <DoneAll fontSize="small" />,
      text: 'Approve',
    });
  }

  if (discard) {
    menuItems.push({
      onClick: handleDiscard,
      icon: <Clear fontSize="small" />,
      text: 'Discard',
    });
  }

  if (saveAsDraft) {
    menuItems.push({
      onClick: handleSaveAsDraft,
      icon: <Save fontSize="small" />,
      text: 'Save as Draft',
      disabled: !status?.isSavable,
    });
  }

  if (returnToDraft) {
    menuItems.push({
      onClick: handleReturnToDraft,
      icon: <Undo fontSize="small" />,
      text: 'Return to Draft',
    });
  }

  if (duplicate) {
    menuItems.push({
      onClick: handleDuplicatePost,
      icon: <FileCopy fontSize="small" />,
      text: 'Duplicate',
    });
  }

  if (copyLink) {
    menuItems.push({
      onClick: handleCopyLink,
      icon: <Link fontSize="small" />,
      text: 'Share via Link',
    });
  }

  if (convertToAdvocacyPost) {
    menuItems.push({
      onClick: handleTurnIntoAdvocacy,
      icon: <ConnectWithoutContact fontSize="small" />,
      text: 'Create Advocacy Post',
    });
  }

  if (createAdvocacy) {
    menuItems.push({
      onClick: handleCreateAdvocacyPost,
      text: 'Create',
    });
  }

  if (updateAdvocacy) {
    menuItems.push({
      onClick: handleUpdateAdvocacyPost,
      text: 'Update',
    });
  }

  menuItems.reverse();

  useEffect(() => {
    if (
      postState?.status.isCurrentUserEditing &&
      !postState.status.isEditDisabled &&
      !postState?.status.isNew &&
      !postState?.status.isSaved
    ) {
      setSavingStatus('Saving...');
    } else if (socket && isSocketConnected && !postState?.status.isNew) {
      setSavingStatus('Your post has been saved');
    } else if (!postState?.status.isNew && (!socket || !isSocketConnected)) {
      setSavingStatus('You are offline');
    }
  }, [socket, postState, isSocketConnected, isMobile, theme.palette.grey]);

  return (
    <>
      <ConfirmationPostModal
        status={status}
        handlers={handlers}
        post={post}
        onClose={closeEditor}
      />
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="top-end"
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'center bottom',
            }}
          >
            <Paper
              sx={{
                mb: 1,
                borderRadius: '8px',
                boxShadow:
                  '0px 1px 5px rgba(0, 0, 0, 0.1), 0px 1px 5px rgba(0, 0, 0, 0.1), 0px 2px 5px rgba(0, 0, 0, 0.1)',
              }}
            >
              <ClickAwayListener onClickAway={handleCloseActionMenu}>
                <MenuList autoFocusItem={open}>
                  <Typography variant="body2" sx={{ py: 1, px: 2 }}>
                    {savingStatus}
                  </Typography>
                  <Divider />
                  {menuItems.map((item, index) => (
                    <CustomMenuItem
                      key={index}
                      onClick={item.onClick}
                      icon={item.icon}
                      text={item.text}
                      disabled={item.disabled}
                    />
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {datePickerScheduleOpen && (
        <DatePicker
          date={postScheduleDefaultDate}
          open={datePickerScheduleOpen}
          maxDate={new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)}
          anchorElement={anchorEl}
          onClose={handleCloseDatepicker}
          onSubmit={handleSchedulePostSubmit}
          primaryActionTitle={datePickerScheduleOpen ? 'Schedule' : 'Save'}
          isMobile={isMobile}
        />
      )}
      <Stack direction={isMobile ? 'column' : 'row'} justifyContent="end" spacing={1}>
        {menuItems.length > 0 && (
          <div>
            {menuItems.length > 1 ? (
              <LoadingButton
                onClick={handleOpenActionMenu}
                variant="linkedin"
                endIcon={<KeyboardArrowDown />}
                loading={status?.isPosting || status?.isUnposting || status?.isSaving}
                loadingPosition="end"
                disabled={status?.isPosting || status?.isUnposting || status?.isSaving}
                fullWidth={isMobile}
                sx={{
                  whiteSpace: 'nowrap',
                  '& .MuiLoadingButton-loadingIndicator': {
                    position: 'absolute',
                    right: '14px',
                  },
                }}
              >
                <span>{actionButtonText}</span>
              </LoadingButton>
            ) : (
              <LoadingButton
                onClick={menuItems[0].onClick}
                variant="contained"
                endIcon={menuItems[0].icon}
                loading={status?.isPosting || status?.isUnposting}
                loadingPosition="end"
                disabled={status?.isPosting || status?.isUnposting}
              >
                {menuItems[0].text}
              </LoadingButton>
            )}
          </div>
        )}
        {unpublishLinkedinPost && (
          <LoadingButton
            onClick={handleUnpublishLinkedin}
            variant="outlined"
            loading={status?.isUnposting}
            disabled={status?.isUnposting}
          >
            Unpublish LinkedIn post
          </LoadingButton>
        )}
        {viewOnLinkedIn && (
          <LoadingButton variant="linkedin" onClick={handleViewOnLinkedin}>
            View on LinkedIn
          </LoadingButton>
        )}
      </Stack>
      <ConfirmationDialog
        title={`Unpublish post from LinkedIn?`}
        content={
          <>
            This action will change the post's status to 'ready.' The post will not
            disappear, but all metrics and statistics associated with it will be
            permanently lost and cannot be recovered.
            <br />
            <br />
            <b>Do you still want to continue?</b>
          </>
        }
        deleteButton
        onConfirm={handleUnpublishPost}
        open={unpublishModalOpen}
        onClose={handleCloseModalDelete}
        okText="Unpublish"
        cancelText="Cancel"
      />
      <ConfirmationDialog
        title={`Delete post forever?`}
        content={
          <>
            This action cannot be undone and will delete your post forever
            <br />
            <br />
            <b>Do you still want to continue?</b>
          </>
        }
        deleteButton
        onConfirm={handleDeletePost}
        open={deletePostModalOpen}
        onClose={() => setDeletePostModalOpen(false)}
        okText="Delete"
        cancelText="Cancel"
      />
    </>
  );
};

export default PostActions;
