import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import {
  Area,
  ComposedChart,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { MetricByWeek } from '../../__generated__/graphql';
import { formatDateMonths } from '../../utils';

interface Metrics {
  data: MetricByWeek[]
}

const GraphicReportCompanyUserInteractionsForWeek: FC<Metrics> = ({
  data: metric,
}) => {

  function generateData() {
    const sortedData = metric.slice().sort((a, b) => {
      // Ordena por startDate de manera ascendente (de menor a mayor fecha)
      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
    });

    if (sortedData.length === 0) return [];
    if (sortedData.length > 1) sortedData.shift();


    return sortedData.map((metric) => ({
      startDate: metric.startDate,
      endDate: metric.endDate,
      "Reactions": metric.numLikes,
      "Comments": metric.numComments,
      "Shares": metric.totalShares,
    }));
  }

  const data = generateData();

  return (
    <Box
      width="100%"
      style={{
        backgroundColor: '#F8F8F8',
        borderRadius: '10px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '500px',
        paddingTop: '1rem',
      }}
    >
      <Box marginY="1rem" marginLeft="2rem">
        <Typography fontSize="1rem" textAlign="left">
          Weekly Interactions
        </Typography>
      </Box>
      <ResponsiveContainer width="100%" height={450}>
        <ComposedChart
          width={620}
          height={400}
          data={data}
          margin={{
            top: 0,
            right: 40,
            bottom: 60,
            left: 0,
          }}
        >
          <XAxis dataKey="startDate" dy={20} tickFormatter={(date) => formatDateMonths(date).split(' ')[0] + ' ' + formatDateMonths(date).split(' ')[1]} 
          angle={-60} />
          <YAxis  />
          {metric.length > 1 && (

            <Tooltip
              content={({ payload }) => {
                if (payload!!.length === 0) return null;
                const {
                  startDate,
                  endDate,
                  Reactions,
                  Comments,
                  Shares,


                } = payload!![0].payload;


                return (
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      border: '1px solid rgb(204, 204, 204)',
                      padding: '10px',
                      whiteSpace: 'nowrap',
                      margin: '0px'
                    }}>
                    <Typography variant="body1">{`${formatDateMonths(startDate)} - ${formatDateMonths(endDate)}`}</Typography>
                    <Typography variant="body1">{`Reactions: ${Reactions}`}</Typography>
                    <Typography variant="body1">{`Comments: ${Comments}`}</Typography>
                    <Typography variant="body1">{`Shares: ${Shares}`}</Typography>
                  </Box>
                );
              }} />
          )}

          <Legend verticalAlign="top" align="center" layout="horizontal" />
          <ReferenceLine y={0} stroke="#000" />
          <Area
            type="monotone"
            dataKey="Reactions"
            stackId="1"
            stroke="#CD0061"
            fill="#CD0061"
            isAnimationActive={false}
          />
          <Area
            type="monotone"
            dataKey="Comments"
            stackId="1"
            stroke="#E16B9D"
            fill="#E16B9D"
            isAnimationActive={false}
          />
          <Area
            type="monotone"
            dataKey="Shares"
            stackId="1"
            stroke="#685E64"
            fill="#685E64"
            isAnimationActive={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>

  );
};

export default GraphicReportCompanyUserInteractionsForWeek;