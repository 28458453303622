import { Close, Add, Send, Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { Control, Controller, FieldErrors, UseFormHandleSubmit } from 'react-hook-form';
import { Brand } from '../../__generated__/graphql';
import Avatar from '../Avatar/Avatar';

const inputStyle = {
  width: '100%',
  borderRadius: '8px',
  marginBottom: '10px',
  '&.MuiInputBase-root': {
    borderRadius: '8px',
  },
};

interface Props {
  openModal: boolean;
  handleCloseModal: () => void;
  handleSubmit: UseFormHandleSubmit<{
    brands: (string[] | undefined)[];
    emails: string[];
  }>;
  onSubmit: (data: any) => void;
  setShowErrors: (value: boolean) => void;
  showErrors: boolean;
  step: number;
  optionSelected: 'member' | 'manager';
  setStep: (value: number) => void;
  setOptionSelected: (value: 'member' | 'manager') => void;
  fields: Record<'id', string>[];
  loading: boolean;
  handleAppend: () => void;
  dataBrands: Brand[];
  errors: FieldErrors<{
    emails: string[];
    brands: (string[] | undefined)[];
  }>;
  control: Control<
    {
      brands: (string[] | undefined)[];
      emails: string[];
    },
    any
  >;
  handleRemove: (index: number) => void;
}

const InviteNewMemberModal: FC<Props> = ({
  openModal,
  handleCloseModal,
  handleSubmit,
  onSubmit,
  setShowErrors,
  step,
  optionSelected,
  setStep,
  setOptionSelected,
  fields,
  loading,
  showErrors,
  handleAppend,
  dataBrands,
  errors,
  control,
  handleRemove,
}) => {
  return (
    <Dialog
      open={openModal}
      onClose={handleCloseModal}
      disableEscapeKeyDown
      maxWidth={false}
      PaperProps={{
        sx: {
          width: { xs: '100%', sm: '868px' },
          height: { xs: '100vh', sm: 'auto' },
          minHeight: '400px',
          borderRadius: '10px',
        },
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit, (errors) => {
          setShowErrors(true);
        })}
      >
        <DialogTitle id="invite-dialog-title" sx={{ position: 'relative' }}>
          <Typography fontWeight={700} fontSize={25}>
            {step === 0 && 'Member type'}
            {step === 1 && optionSelected === 'manager' && 'Brand Manager'}
            {step === 1 && optionSelected === 'member' && 'Members with Personal Brand'}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            disabled={loading}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography marginBottom={2} fontSize={19}>
            {step === 1 &&
              optionSelected === 'manager' &&
              'Invite a brand manager to manage the affiliated brands.'}
            {step === 1 &&
              optionSelected === 'member' &&
              'Add members to manage their own personal brand. By sending them the invitation, they will have access to Magnettu and can start creating content for their personal brand'}
          </Typography>
          {step === 0 && (
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box
                  onClick={() => {
                    setStep(1);
                    setOptionSelected('manager');
                  }}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px',
                    border: '1px solid hsla(0, 0%, 0%, 0.6)',
                    borderRadius: '10px',
                    cursor: 'pointer',
                    minHeight: '288px',
                    height: '100%',
                    maxHeight: '350px',
                    '&:hover': {
                      backgroundColor: 'hsla(0, 0%, 0%, 0.1)',
                    },
                  }}
                >
                  <img
                    src="./images/icons/campaign.svg"
                    alt="brand"
                    style={{
                      width: '72px',
                      height: '72px',
                      objectFit: 'cover',
                    }}
                  />
                  <Typography fontWeight={700} fontSize={18} marginY={1.5}>
                    Brand Manager
                  </Typography>

                  <Typography lineHeight="19.76px" letterSpacing="0.25px">
                    Manages personal or corporate brands. Creates, edits, distributes, and
                    publishes content.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  onClick={() => {
                    setStep(1);
                    setOptionSelected('member');
                  }}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px',
                    border: '1px solid hsla(0, 0%, 0%, 0.6)',
                    borderRadius: '10px',
                    cursor: 'pointer',
                    height: '288px',
                    '&:hover': {
                      backgroundColor: 'hsla(0, 0%, 0%, 0.1)',
                    },
                  }}
                >
                  <img
                    src="./images/icons/bussiness.svg"
                    alt="brand"
                    style={{
                      width: '72px',
                      height: '72px',
                      objectFit: 'cover',
                    }}
                  />
                  <Typography fontWeight={700} fontSize={18} marginY={1.5}>
                    Members with Personal Brand
                  </Typography>

                  <Typography lineHeight="19.76px" letterSpacing="0.25px">
                    Members who manage their own personal brand. They create and publish
                    content on their own.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}
          {step === 1 && optionSelected === 'manager' && (
            <Box minHeight={300} maxHeight={600}>
              {fields.map((item, index) => (
                <Box
                  key={item.id}
                  display="flex"
                  flexDirection="column"
                  gap={1}
                  sx={{
                    background: '#FEFBFC',
                    padding: '20px',
                    borderRadius: '10px',
                    marginBottom: '20px',
                  }}
                >
                  <Typography fontSize={15} fontWeight={700}>
                    Email
                  </Typography>
                  <Controller
                    name={`emails.${index}`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoFocus
                        margin="dense"
                        placeholder="Email address"
                        type="email"
                        sx={inputStyle}
                        variant="outlined"
                        error={showErrors && !!errors.emails?.[index]}
                        helperText={showErrors && errors.emails?.[index]?.message}
                        {...field}
                      />
                    )}
                  />
                  <Divider />
                  <Typography fontSize={15}>
                    <b>Assign brands </b>
                  </Typography>
                  <Controller
                    name={`brands.${index}`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        multiple
                        placeholder="Select brands"
                        value={field.value || []}
                        onChange={field.onChange}
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return 'None';
                          }

                          const displayValues = selected
                            ?.slice(0, 4)
                            .map((value: string) => {
                              const brand = dataBrands.find(
                                (brand) => brand._id === value,
                              );
                              return brand ? brand.name : '';
                            });

                          if (selected.length > 2) {
                            return `${displayValues.join(', ')} +${selected.length - 2}`;
                          }

                          return displayValues.join(', ');
                        }}
                        variant="outlined"
                        style={{ width: '100%' }}
                        error={!!errors.brands?.[index]}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 300,
                            },
                          },
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            if (field.value && field.value.length === dataBrands.length) {
                              field.onChange([]);
                              return;
                            }
                            field.onChange(dataBrands.map((brand) => brand._id));
                          }}
                        >
                          <ListItemText
                            sx={{
                              marginLeft: '10px',
                            }}
                            primary="Will manage all brands"
                            primaryTypographyProps={{
                              fontWeight: 700,
                            }}
                          />

                          <Checkbox
                            checked={field?.value?.length === dataBrands.length}
                          />
                        </MenuItem>
                        <Divider />
                        {dataBrands.map((brand) => (
                          <MenuItem key={brand._id} value={brand._id}>
                            <Avatar
                              avatar={brand?.account?.avatar || ''}
                              name={brand.name}
                              size={40}
                              backgroundColor={brand.backgroundColor || ''}
                            />
                            <ListItemText
                              primary={brand.name}
                              sx={{
                                marginLeft: '10px',
                              }}
                            />
                            <Checkbox
                              checked={
                                field.value && field.value.indexOf(brand._id) > -1
                                  ? true
                                  : false
                              }
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />

                  {fields.length > 1 && (
                    <Button
                      type="button"
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        handleRemove(index);
                      }}
                    >
                      Remove
                    </Button>
                  )}
                </Box>
              ))}
              <Button
                onClick={handleAppend}
                sx={{
                  borderRadius: '100px',
                  height: '50px',
                  width: '139px',
                  backgroundColor: 'hsla(0, 0%, 88%, 1)',
                  padding: '12px, 16px, 12px, 16px',
                  color: 'hsla(0, 0%, 0%, 0.87)',
                  fontWeight: 700,
                  fontSize: '15px',
                  marginBottom: '20px',
                }}
                endIcon={<Add />}
              >
                Add more
              </Button>
            </Box>
          )}{' '}
          {step === 1 && optionSelected === 'member' && (
            <Box>
              <Typography marginBottom={1} fontSize={15} fontWeight={700}>
                Email
              </Typography>
              <Box maxHeight={300} overflow="auto">
                {fields.map((item, index) => (
                  <Box key={item.id} display="flex" gap={2} alignItems="center">
                    <Controller
                      name={`emails.${index}`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          placeholder="Email address"
                          type="email"
                          sx={inputStyle}
                          variant="outlined"
                          error={showErrors && !!errors.emails?.[index]}
                          helperText={showErrors && errors.emails?.[index]?.message}
                          {...field}
                        />
                      )}
                    />
                    {fields.length > 1 && (
                      <Delete
                        sx={{
                          color: '#C62828',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleRemove(index)}
                      />
                    )}
                  </Box>
                ))}
                <Button
                  onClick={handleAppend}
                  sx={{
                    borderRadius: '100px',
                    height: '50px',
                    width: '139px',
                    backgroundColor: 'hsla(0, 0%, 88%, 1)',
                    padding: '12px, 16px, 12px, 16px',
                    color: 'hsla(0, 0%, 0%, 0.87)',
                    fontWeight: 700,
                    fontSize: '15px',
                  }}
                  endIcon={<Add />}
                >
                  Add more
                </Button>
              </Box>
            </Box>
          )}
        </DialogContent>
        {step === 1 && (
          <DialogActions
            sx={{ p: '0px 24px 20px 24px' }}
            style={{ display: 'flex', justifyContent: 'end' }}
          >
            <LoadingButton
              loading={loading}
              variant={'contained'}
              type="submit"
              endIcon={<Send />}
            >
              Send invite
            </LoadingButton>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};

export default InviteNewMemberModal;
