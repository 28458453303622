import {
  FormatBoldRounded,
  InsertEmoticon,
  Lock,
  LockOpen,
  Redo,
  Undo,
} from '@mui/icons-material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import {
  Box,
  Divider,
  IconButton as MuiIconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import { styled } from '@mui/system';
import { Editor, EditorContent } from '@tiptap/react';
import { useContext, useRef, useState } from 'react';
import { PostContext } from '../../context/PostContext';
import { PostHandlers, PostStatus } from '../../hooks/usePost';
import { convertToBold } from '../../utils';
import AIAssistantMenu from '../AiAssistant/AIAssistantMenu';
import EmojiComponent from '../SelectorComponents/EmojiSelector';
import TaggerSelector from '../SelectorComponents/TaggerSelector';
import CharCounter from './CharCounter';
import './styles.css';
import { Tag } from '../../__generated__/graphql';

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  width: '20px',
  '& .MuiSvgIcon-root': {
    width: '20px', // Set the width you want for the MuiSvgIcon
  },
  '&.MuiIconButton-root': {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

interface Props {
  postId?: string;
  content?: string | null;
  placeholder?: string;
  status?: PostStatus;
  handlers?: PostHandlers;
  aiAssistant?: boolean;
  resizable?: boolean;
  onChange: (html: string, json: any, action?: Tag) => void;
  advocacyEditable?: boolean;
  toggleAdvocacyEditable?: () => void;
  isUndoable?: boolean;
}

export const TextEditor = ({
  postId,
  content = null,
  placeholder = '',
  status,
  handlers,
  resizable = false,
  aiAssistant = false,
  onChange,
  advocacyEditable,
  toggleAdvocacyEditable,
  isUndoable = false,
  // tags,
}: Props) => {
  const [emojiComponentOpen, setEmojiComponentOpen] = useState(false);
  const [taggerComponentOpen, setTaggerComponentOpen] = useState(false);
  const { editor } = useContext(PostContext);  

  const anchorRef = useRef(null);

  const handleEmojiComponentOpen = () => {
    setEmojiComponentOpen(true);
  };

  const handleTaggerComponentOpen = () => {
    setTaggerComponentOpen(true);
  };

  const handleTaggerComponentClose = () => {
    setTaggerComponentOpen(false);
  };

  const handleEmojiComponentClose = () => {
    setEmojiComponentOpen(false);
  };

  const handleEmojiInputChange = (editor: Editor, emoji: string) => {
    editor.commands.insertContent(emoji);
    onChange(editor.getHTML(), editor.getJSON());
  };

  const handleTagger = (editor: Editor, tag: string, tagId: string) => {
    editor.commands.insertContent(
      `<span data-type="mention" class="mention" data-label="${tag}" data-id="${tagId}" contenteditable="false">${tag}</span>`,
    );
    editor.commands.focus();
    setTaggerComponentOpen(false);
    onChange(editor.getHTML(), editor.getJSON());
  };

  const setBold = (editor: Editor) => {
    const { view, state } = editor;
    const { from, to } = view.state.selection;
    if (from !== undefined && to !== undefined) {
      const selectedText = state.doc.textBetween(from, to);
      const boldText = convertToBold(selectedText);
      const textNode = editor.schema.text(boldText);
      const transaction = state.tr.replaceWith(from, to, textNode);
      view.dispatch(transaction);
    }
  };

  const handleUndo = () => {
    editor?.commands.undo();
  };

  const handleRedo = () => {
    editor?.commands.redo();
  };

  return (
    <Stack direction="column" height={'100%'} spacing={1} width={'100%'}>
      <Box
        sx={{
          height: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid #BBBBBB',
          borderRadius: '8px',
          cursor: 'text',
          justifyContent: 'space-between',
          overflow: 'hidden',
        }}
      >
        <Box
          minHeight={300}
          sx={{
            display: 'block',
            overflowY: 'auto',
          }}
        >
          {editor && <EditorContent editor={editor} readOnly={status?.isEditDisabled} style={{ height: '100%'}}/>}
        </Box>
        <Box
          height={'fit-content'}
          sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}
        >
          {!status?.isEditDisabled && (
            <Stack
              direction="row"
              justifyContent={'space-between'}
              sx={{
                width: '100%',
                bottom: 0,
                p: '10px 20px 10px 10px',
              }}
            >
              {editor && aiAssistant && (
                <AIAssistantMenu
                  postId={postId}
                  editor={editor}
                  onChange={onChange}
                  onLoading={(state) => {
                    editor?.setOptions({
                      editable: !state,
                    });
                  }}
                />
              )}
              <Stack direction="row" gap={1} alignItems="center">
                {status?.isAdvocacyParent && (
                  <Tooltip title="Let others edit the content" arrow placement="top">
                    <IconButton onClick={toggleAdvocacyEditable}>
                      {advocacyEditable ? <LockOpen /> : <Lock />}
                    </IconButton>
                  </Tooltip>
                )}
                {status?.charsCount !== undefined && <CharCounter />}

                {editor && (
                  <Tooltip title={'Bold'} placement="top">
                    <IconButton onClick={() => setBold(editor)}>
                      <FormatBoldRounded />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Insert mention" placement="top">
                  <IconButton onClick={handleTaggerComponentOpen} ref={anchorRef}>
                    <AlternateEmailIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Insert emoji" placement="top">
                  <IconButton
                    data-testid="emoji-button"
                    onClick={handleEmojiComponentOpen}
                    ref={anchorRef}
                  >
                    <InsertEmoticon />
                  </IconButton>
                </Tooltip>

                {editor && (
                  <EmojiComponent
                    open={emojiComponentOpen}
                    anchorElement={anchorRef.current}
                    onClose={handleEmojiComponentClose}
                    onChange={(emoji) => handleEmojiInputChange(editor, emoji)}
                  />
                )}
                {editor && (
                  <TaggerSelector
                    open={taggerComponentOpen}
                    anchorElement={anchorRef.current}
                    onClose={handleTaggerComponentClose}
                    onChange={(tag, tagId) => handleTagger(editor, tag, tagId)}
                  />
                )}
                <Divider orientation="vertical" variant="middle" flexItem />
                {isUndoable && (
                  <>
                    <Tooltip title="Undo" placement="top">
                      <IconButton onClick={handleUndo} ref={anchorRef}>
                        <Undo />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Redo" placement="top">
                      <IconButton onClick={handleRedo} ref={anchorRef}>
                        <Redo />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </Stack>
            </Stack>
          )}

          {status?.isEditDisabled && !status.isCurrentUserEditing && (
            <Stack
              direction={'row'}
              sx={{
                p: '8px',
                backgroundColor: '#f0c0c0',
                width: '100%',
                justifyContent: 'space-between',
                color: 'hsla(0, 0%, 43%, 1)',
              }}
            >
              <Typography fontWeight={600} fontSize={14} ml={0.5}>
                This post is currently being edited by another user. You'll be able to
                make edits once it's available.
              </Typography>
              <Lock />
            </Stack>
          )}
        </Box>
      </Box>
    </Stack>
  );
};
