import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { BrandMetricsWithShell } from '../../__generated__/graphql';
import { FC, useState } from 'react';
import TablePagination from '@mui/material/TablePagination';

interface Data {
  userId: string;
  name: string;
  plan: string;
  postsTotal: number;
  interactions: number;
  magnetsTotal: number;
  postsLast30Days: number;
  interactions30Days: number;
  magnetsLast30Days: number;
  postsLast7Days: number;
  magnetsLast7Days: number;
  interactions7Days: number;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof Data>(
  order: Order,
  orderBy: Key,
): (a: Data, b: Data) => number {
  return (a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    const isNumericValue = typeof aValue === 'number' && typeof bValue === 'number';

    if (aValue === 'N/A' && bValue === 'N/A') {
      return 0;
    }

    if (aValue === 'N/A') {
      return 1;
    }

    if (bValue === 'N/A') {
      return -1;
    }

    if (isNumericValue) {
      return order === 'desc' ? bValue - aValue : aValue - bValue;
    }

    // @ts-ignore
    return order === 'desc' ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
  };
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const hasNA = (value: any) => value === 'N/A';

  const sortedArray = [...array];
  sortedArray.sort((a, b) => {
    const aIsNA = hasNA(a);
    const bIsNA = hasNA(b);

    if (aIsNA && bIsNA) {
      return 0;
    }

    if (aIsNA) {
      return 1;
    }

    if (bIsNA) {
      return -1;
    }

    return comparator(a, b);
  });

  return sortedArray;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'postsTotal',
    numeric: true,
    disablePadding: false,
    label: 'Posts (Total)',
  },
  {
    id: 'interactions',
    numeric: true,
    disablePadding: false,
    label: 'Interactions (Total)',
  },
  {
    id: 'magnetsTotal',
    numeric: true,
    disablePadding: false,
    label: 'Magnets (Total)',
  },
  {
    id: 'postsLast30Days',
    numeric: true,
    disablePadding: false,
    label: 'Posts (Last 30 Days)',
  },
  {
    id: 'interactions30Days',
    numeric: true,
    disablePadding: false,
    label: 'Interactions (Last 30 Days)',

  },
  {
    id: 'magnetsLast30Days',
    numeric: true,
    disablePadding: false,
    label: 'Magnets (Last 30 Days)',
  },
  {
    id: 'postsLast7Days',
    numeric: true,
    disablePadding: false,
    label: 'Posts (Last 7 Days)',
  },
  {
    id: 'interactions7Days',
    numeric: true,
    disablePadding: false,
    label: 'Interactions (Last 7 Days)',

  },
  {
    id: 'magnetsLast7Days',
    numeric: true,
    disablePadding: false,
    label: 'Magnets (Last 7 Days)',
  },
];

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontWeight: 'bold', backgroundColor: '#FFE4F1' }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label.includes('(') ? (
                <>
                 {/* <b> */}
                  {headCell.label.split('(')[0]} 
                  {/* </b>  */}
                  <br />
                  ({headCell.label.split('(')[1]}
                </>
              ) : (
                headCell.label
              )}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface AnalitysTableProps {
  data: BrandMetricsWithShell[] | [];
}
const AnalitysTable: FC<AnalitysTableProps> = ({ data }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
    
  const rows: BrandMetricsWithShell[] | any = data.map((item: BrandMetricsWithShell) => {
    const {
      brandId,
      brandName,
      postsTotal,
      postsLast7Days,
      interactions,
      interactions30Days,
      interactions7Days,
      magnetsTotal,
      postsLast30Days,
      useExtension,
      magnetsLast30Days,
      magnetsLast7Days,
    } = item;

    return {
      brandId,
      name: brandName,
      postsTotal,
      postsLast7Days,
      interactions: useExtension ? interactions : "N/A",
      magnetsTotal,
      postsLast30Days,
      magnetsLast7Days,
      interactions30Days: useExtension ? interactions30Days : "N/A",
      interactions7Days: useExtension ? interactions7Days : "N/A",
      magnetsLast30Days,
    };
  });



  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('postsLast30Days');

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows],
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ width: '100%', marginTop: '2rem' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={index}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {row.name}
                    </TableCell>
                    {/* <TableCell align="center">
                      {toTitleCase(
                        row.plan === UserPlan.Inactive ? '' : row.plan,
                      )}
                    </TableCell> */}
                    <TableCell align="center">{row.postsTotal}</TableCell>
                    <TableCell align="center">{row.interactions}</TableCell>
                    <TableCell align="center">{row.magnetsTotal}</TableCell>
                    <TableCell align="center">{row.postsLast30Days}</TableCell>
                    <TableCell align="center">{row.interactions30Days}</TableCell>
                    <TableCell align="center">{row.magnetsLast30Days}</TableCell>
                    <TableCell align="center">{row.postsLast7Days}</TableCell>
                    <TableCell align="center">{row.interactions7Days}</TableCell>
                    <TableCell align="center">{row.magnetsLast7Days}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default AnalitysTable;
