import { Add } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import React, { FC, useContext, useState } from 'react';
import { Brand, User, UserRole } from '../../__generated__/graphql';
import BrandManagertListTable from '../Tables/BrandManagertListTable';
import AddNewBrandManagersModal from './AddNewBrandManagersModal';
import { useMutation } from '@apollo/client';
import { ADD_BRANDS_MANAGER_TO_BRAND } from '../../graphql/mutations';
import { SnackbarContext } from '../../context/SnackbarContext';
import { BrandContext } from '../../context/BrandContext';

interface Props {
  brandSelected: Brand;
  listUser: User[];
}

function filterAndSortBrandManagers(listUser: User[], brandSelected: Brand) {
  return listUser
    .filter((user) => 
      user.role.includes(UserRole.BrandManager) 
    )
    .concat((brandSelected.brandManagers || []) as User[])
    .sort((a, b) => {
      if (a.role.includes(UserRole.BrandManager) && b.role.includes(UserRole.LimitedBrandManager)) {
        return -1;
      }
      if (a.role.includes(UserRole.LimitedBrandManager) && b.role.includes(UserRole.BrandManager)) {
        return 1;
      }
      return 0;
    });
}


const ListBrandManager: FC<Props> = ({ brandSelected, listUser }) => {
  const [openModalAddNewManagers, setOpenModalAddNewManagers] = useState(false);
  const { setErrorMessage, setSuccessMessage } = useContext(SnackbarContext);
  const { refetch } = useContext(BrandContext);
  const [addBrandsManagerToBrand, { loading: loadingAddManagers }] = useMutation(
    ADD_BRANDS_MANAGER_TO_BRAND,
  );

  const handleOpenModalAddNewManagers = () => {
    setOpenModalAddNewManagers(true);
  };

  const handleCloseModalAddNewManagers = () => {
    setOpenModalAddNewManagers(false);
  };

  const listManagers = filterAndSortBrandManagers(listUser, brandSelected);

  const listNotManagers = listUser.filter(
    (user) =>
      !user.role.includes(UserRole.BrandManager) &&
      user._id !== brandSelected.createdById &&
      brandSelected.brandManagers?.every((manager) => manager?._id !== user._id),
  );

  const handleAddNewManagers = (userIds: string[]) => {
    addBrandsManagerToBrand({
      variables: {
        brandId: brandSelected._id,
        userIds,
      },
      onCompleted: (data) => {
        if (data.addBrandsManagerToBrand?.success) {
          handleCloseModalAddNewManagers();
          setSuccessMessage('Managers added successfully');
          refetch();
          return;
        }
        setErrorMessage('Error adding manager to brand');
      },
    });
  };

  return (
    <Box>
      <Button
        onClick={handleOpenModalAddNewManagers}
        variant="contained"
        sx={{
          marginY: 2,
        }}
      >
        <Add />
        Add new member to brand
      </Button>

      <Typography fontWeight={700} marginBottom={2}>
        List of members and their permissions for this brand
      </Typography>
      <BrandManagertListTable listManagers={listManagers} brandSelected={brandSelected} />
      <AddNewBrandManagersModal
        openModal={openModalAddNewManagers}
        handleCloseModal={handleCloseModalAddNewManagers}
        listNotManagers={listNotManagers}
        handleAddNewManagers={handleAddNewManagers}
        loadingAddManagers={loadingAddManagers}
        brandSelected={brandSelected}
      />
    </Box>
  );
};

export default ListBrandManager;
