import { RestartAlt } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Popover, Card, Chip, Divider, Typography } from '@mui/material'
import React from 'react'
import { past30Days, timeAgo } from '../../utils'
import { User } from '../../__generated__/graphql'

interface MemberPopoverProps {
  anchorEl: null | HTMLElement
  handleClose: () => void
  memberSelected: User
  member: User
  handleInvite: () => void
  loading: boolean
  filteredMembers: User[]
}

const MemberPopover: React.FC<MemberPopoverProps> = ({
  anchorEl,
  handleClose,
  memberSelected,
  member,
  handleInvite,
  loading,
  filteredMembers,
}) => {

  

  return (
    <Popover
    id={memberSelected?._id || ''}
    open={Boolean(anchorEl)}
    onClose={handleClose}
    anchorEl={anchorEl}
    // elevation={1}
    PaperProps={{
      sx: {
        borderRadius: '10px',
        boxShadow:
          '0px 5px 5px -3px #00000033 0px 8px 10px 1px #00000024 0px 3px 14px 2px #0000001F  !important',
      },
    }}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
  >
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF',
        padding: '10px',
        gap: '10px',
        width: '276px',
        height: '152px',
      }}
    >
      <Chip
        aria-describedby={memberSelected?._id || ''}
        label={
          past30Days(
            filteredMembers.find((m) => m._id === memberSelected?._id)
              ?.inviteSentOn,
          )
            ? 'Pending'
            : 'Expired'
        }
        variant="filled"
        sx={{
          backgroundColor: past30Days(
            filteredMembers.find((m) => m._id === memberSelected?._id)
              ?.inviteSentOn,
          )
            ? 'hsla(36, 100%, 50%, 0.3)'
            : 'hsla(14, 100%, 70%, 0.7)',
          color: 'hsla(0, 0%, 0%, 0.87)',
          fontWeight: 600,
          width: '75px',
          alignSelf: 'center',
        }}
        size="medium"
      />
      <Divider />
      <Typography variant="body2" textAlign="center">
        {past30Days(
          filteredMembers.find((m) => m._id === memberSelected?._id)
            ?.inviteSentOn,
        )
          ? `Invite sent ${timeAgo(
              new Date(
                filteredMembers.find(
                  (m) => m._id === memberSelected?._id,
                )?.inviteSentOn,
              ),
            )}`
          : 'Invite expired'}
      </Typography>
      <LoadingButton
        variant="contained"
        color="primary"
        size="small"
        sx={{
          width: '171px',
          alignSelf: 'center',
          height: '32px',
        }}
        endIcon={<RestartAlt />}
        onClick={handleInvite}
        loading={loading && memberSelected?._id === member._id}
      >
        Resend
      </LoadingButton>
    </Card>
  </Popover>
  )
}

export default MemberPopover