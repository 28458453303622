import type {} from '@mui/lab/themeAugmentation';
import { createTheme, ThemeProvider } from '@mui/material/styles';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    linkedin: true;
  }
}

const theme = createTheme({
  typography: {
    fontFamily: 'Raleway, Helvetica',
  },
  palette: {
    primary: {
      main: '#FF007A',
      dark: '#C20B63',
      light: '#F9E7F0',
    },
    text: {
      primary: '#292929',
      secondary: '#6E6E6E',
    },
    grey: {
      50: '#ddd',
      100: '#BBBBBB',
      200: '#ECECEC',
      300: '#6F6F6F',
      400: '#F8F8F8',
      500: '#DCDCDC',
    },
  },
  spacing: 10,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1200,
      lg: 1900,
      xl: 2500,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Raleway';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Raleway'), local('Raleway-Regular'), url(https://fonts.gstatic.com/s/raleway/v14/1Ptug8zYS_SKggPNyC0ITw.woff2) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        body {
          background-color: hsla(0, 0%, 97%, 1);
        }
      `,
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '9px',
          background: '#FBFBFB',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          borderRadius: '9px',
          // background: "red"
        },
      },
    },
    MuiLoadingButton: {
      variants: [
        {
          props: { variant: 'linkedin' },
          style: {
            backgroundColor: '#30527C',
            color: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#1C3353',
            },
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'linkedin' },
          style: {
            backgroundColor: '#30527C',
            color: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#1C3353',
            },
            '&.Mui-disabled': {
              backgroundColor: '#E0E0E0',
              color: 'hsla(0, 0%, 0%, 0.26)',
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
          padding: '7px 15px',
          height: '38px',
        },
        containedPrimary: ({ theme }) => ({
          fontWeight: 600,
        }),
        outlinedSecondary: ({ theme }) => ({
          color: theme.palette.grey[100],
          borderColor: theme.palette.grey[100],
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
          },
        }),
        containedSecondary: ({ theme }) => ({
          backgroundColor: '#C62828',
          fontWeight: 600,
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
          },
        }),
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          background: 'rgba(248, 248, 248, 1)',
        },
        indicator: {
          borderRadius: '4px',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#0000EE',
          cursor: 'pointer',
          textDecoration: 'none',
        },
      },
    },
  },
});

type Props = {
  children: React.ReactNode;
};

export const MagnettuThemeProvider = (props: Props) => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};
