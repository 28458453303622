import { Box, Grid, Typography } from '@mui/material';
import { MetricsCompanyResponse, MonthlyDataEntry } from '../../__generated__/graphql';
import { formatPostsCountDifference } from '../../utils';
import CardReport from '../Reports/CardReport';
import GraphicReportCompanyImpressions from '../Reports/GraphicReportCompanyImpressions';
import GraphicReportCompanyTeamPostsFollowers from '../Reports/GraphicReportCompanyTeamPostsFollowers';
import GraphicReportCompanyTeamPostsFollowersForDays from '../Reports/GraphicReportCompanyTeamPostsFollowersForDays';
import CompanyPerformanceV2 from './CompanyPerformancev2';

interface Props {
  metricsCompany: MetricsCompanyResponse | null;
}

const CompanyPerformance = ({ metricsCompany }: Props) => {
  return (
    <>
      <Grid
        container
        display="flex"
        gap={2}
        alignContent="center"
        flexDirection={{ xs: 'column', md: 'row' }}
        flexWrap={{ xs: 'wrap', md: 'nowrap' }}
      >
        <Grid item sm={6} md={3}>
          <CardReport
            title="Company followers"
            lastDays="in the last 30 days"
            comparation={formatPostsCountDifference(
              metricsCompany?.followers || 0,
              (metricsCompany?.followers || 0) -
                (metricsCompany?.followers30DaysAgo || 0),
            )}
          >
            <Typography fontSize="2rem" textAlign="center" mr="5px">
              {metricsCompany?.followers || '0'}
            </Typography>
          </CardReport>
        </Grid>
        <Grid item sm={6} md={3}>
          <CardReport
            title="Impressions"
            lastDays="vs the previous 30 days"
            subtitle
            comparation={formatPostsCountDifference(
              metricsCompany?.impressions || 0,
              metricsCompany?.impressions30DaysAgo || 0,
            )}
          >
            <Typography fontSize="2rem" textAlign="center" mr="5px">
              {metricsCompany?.impressions || '0'}
            </Typography>
            <Typography fontSize="1rem" textAlign="center">
              {' '}
              impressions
            </Typography>
          </CardReport>
        </Grid>
        <Grid item sm={6} md={3}>
          <CardReport
            title="Interactions"
            lastDays="vs the previous 30 days"
            subtitle
            comparation={formatPostsCountDifference(
              metricsCompany?.interactions || 0,
              metricsCompany?.interactions30DaysAgo || 0,
            )}
          >
            <Typography fontSize="2rem" textAlign="center" mr="5px">
              {metricsCompany?.interactions || '0'}
            </Typography>
            <Typography fontSize="1rem" textAlign="center">
              {' '}
              interactions
            </Typography>
          </CardReport>
        </Grid>
        <Grid item sm={6} md={3}>
          <CardReport title="Engagement" subtitle comparation={' '}>
            <Box>
              <Typography marginTop="1rem" fontSize="1rem" textAlign="center" mr="5px">
                {metricsCompany &&
                metricsCompany.interactions &&
                metricsCompany.impressions
                  ? `${(
                      ((metricsCompany?.interactions || 0) /
                        (metricsCompany.impressions || 0)) *
                      100
                    ).toFixed(2)}% `
                  : 'N/A'}
                {!!metricsCompany?.interactions && !!metricsCompany?.impressions && (
                  <span>
                    audience reaction per
                    <span
                      style={{
                        color: '#CD0061',
                        display: 'inline-block',
                        marginLeft: '0.5rem',
                        marginRight: '0.5rem',
                      }}
                    >
                      company post
                    </span>
                  </span>
                )}
              </Typography>
            </Box>
          </CardReport>
        </Grid>
      </Grid>
      <Box
        width="100%"
        marginTop="2rem"
        justifyContent="space-between"
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box width={{ xs: '100%', sm: '49%' }}>
          <GraphicReportCompanyImpressions
            metric={metricsCompany?.monthlyData as MonthlyDataEntry[]}
          />
        </Box>
        <Box width={{ xs: '100%', sm: '49%' }}>
          <GraphicReportCompanyTeamPostsFollowers
            metricCompany={metricsCompany!!}
          />
        </Box>
      </Box>
      <Box width={{ xs: '100%' }} marginY="2rem">
        <GraphicReportCompanyTeamPostsFollowersForDays metricCompany={metricsCompany!!} />
      </Box>
      <CompanyPerformanceV2 key={metricsCompany?.impressions} />
    </>
  );
};

export default CompanyPerformance;
