import {
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import AvatarCell from '../Avatar/AvatarCell';
import { Brand } from '../../__generated__/graphql';
import AddIcon from '@mui/icons-material/Add';
import { useContext } from 'react';
import { BrandContext } from '../../context/BrandContext';

const HeaderCell = ({ text, sx = {} }: { text: string; sx?: SxProps }) => (
  <TableCell sx={{ color: 'black', fontWeight: 'bold', ...sx }}>
    <Typography fontSize="14px" fontWeight="bold">
      {text}
    </Typography>
  </TableCell>
);

interface Props {
  filteredBrands: Brand[] | [];
  handleBrandSelected: (brand: Brand) => void;
  brandSelected: any;
}

const BrandsTable = ({ filteredBrands, handleBrandSelected, brandSelected }: Props) => {
  const { setModalNewBrand } = useContext(BrandContext);

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: '20px',
      }}
    >
      <Table
        sx={{
          width: '100%',
        }}
      >
        <TableHead>
          <TableRow>
            <HeaderCell text="Brand name" />
          </TableRow>
        </TableHead>
        <TableBody>
          {!filteredBrands?.length ? (
            <>
              <TableRow
                sx={{
                  background: 'rgba(236, 236, 236, 1)',
                  marginBottom: 3,
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
                onClick={() => setModalNewBrand(true)}
              >
                <TableCell
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    justifyContent: 'center',
                    gap: 1,
                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                  }}
                >
                  <AddIcon
                    sx={{
                      fontSize: '24px',
                    }}
                  />
                  <Typography fontSize={14}>Create new brand</Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  height: '50px',
                }}
              />
            </>
          ) : (
            filteredBrands?.map((brand) => (
              <TableRow
                onClick={() => handleBrandSelected(brand)}
                key={brand._id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  backgroundColor:
                    brandSelected?._id === brand._id ? 'rgba(255, 0, 122, 0.1)' : 'white',
                  cursor: 'pointer',
                  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                }}
                data-testid="user-row"
              >
                <AvatarCell
                  avatar={brand?.account?.avatar || ''}
                  name={brand.name}
                  backgroundColor={brand.backgroundColor || ''}
                />
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BrandsTable;
