import { Stack, Typography } from '@mui/material';
import PostsList from './PostsList';

const ManageTeam = () => {
  return (
    <>
      <Stack
        direction="column"
        p={3}
        gap={4}
      >
        <Stack direction="column" alignItems="flex-start">
          <Typography variant={'h4'} fontWeight="bold">
            Content Manager
          </Typography>
          <Typography variant={'body1'}>
            Create posts and manage your brands with ease
          </Typography>
        </Stack>
        <PostsList />
      </Stack>
    </>
  );
};

export default ManageTeam;
