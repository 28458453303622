import { ArrowForward, AutoAwesome, Close } from '@mui/icons-material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { Box, Button, Divider, Grid, IconButton, Stack } from '@mui/material';
import { useContext, useEffect } from 'react';
import { ArticleType, Insight, Sentiment } from '../../__generated__/graphql';
import { InsightContent } from './InsightContent';
import { InsightContentLinkedIn } from './InsightContentLinkedIn';
import { DeviceContext } from '../../context/DeviceContext';

interface Props {
  insight: Insight | null;
  onClose?: () => void;
  handleNextInsight: () => void;
  handlePreviousInsight: () => void;
  disabledButtonNext: boolean;
  disabledButtonBack: boolean;
  handleCreateOrUpdateInsight: (
    insightInput: Insight,
    sentiment?: Sentiment,
    favorite?: boolean,
  ) => void;
  handleCreatePostDraft: (insightInput: Insight, addPerspective: boolean) => void;
}

const InsightView = ({
  insight,
  handleNextInsight,
  handlePreviousInsight,
  onClose = () => null,
  handleCreateOrUpdateInsight,
  handleCreatePostDraft,
  disabledButtonNext,
  disabledButtonBack,
}: Props) => {
  const { isMobile } = useContext(DeviceContext);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        handlePreviousInsight();
      } else if (event.key === 'ArrowRight') {
        handleNextInsight();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handlePreviousInsight, handleNextInsight]);

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" marginBottom="1rem">
        <IconButton
          onClick={() => {
            onClose();
          }}
          sx={{
            marginLeft: 'auto',
            fontSize: '10px',
            padding: '0px',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          <Close />
        </IconButton>
      </Box>

      <Grid container spacing={1}>
        {isMobile && (
          <Grid item xs={12}>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Button
                sx={{
                  justifyContent: 'flex-start',
                  gap: '0.8rem',
                  color: insight?.favorite ? '#FF007A' : '#A69BA1',
                  border: insight?.favorite
                    ? '1px solid #FF007A'
                    : '1px solid rgba(166, 155, 161, 0.5)',
                  backgroundColor: insight?.favorite
                    ? 'rgba(255, 0, 122, 0.08)'
                    : 'white',
                  fontWeight: 700,
                  fontSize: '15px',
                }}
                onClick={() => {
                  if (insight) {
                    handleCreateOrUpdateInsight(
                      insight,
                      insight.sentiment!!,
                      !insight.favorite,
                    );
                  }
                }}
              >
                <FavoriteIcon /> Favorite
              </Button>
              <Box
                display="flex"
                gap={1}
                // marginTop="1rem"
                flexDirection={{
                  md: 'row',
                }}
              >
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={handlePreviousInsight}
                  disabled={disabledButtonBack}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleNextInsight}
                  disabled={disabledButtonNext}
                >
                  Next
                </Button>
              </Box>
            </Stack>
          </Grid>
        )}
        <Grid item sm={9} md={10.5}>
          {insight?.type === ArticleType.Article ? (
            <InsightContent insight={insight} />
          ) : (
            <InsightContentLinkedIn insight={insight!} />
          )}
        </Grid>
        {!isMobile && (
          <Grid item sm={3} md={1.5}>
            <Button
              fullWidth
              sx={{
                justifyContent: 'flex-start',
                gap: '0.8rem',
                color: insight?.favorite ? '#FF007A' : '#A69BA1',
                border: insight?.favorite
                  ? '1px solid #FF007A'
                  : '1px solid rgba(166, 155, 161, 0.5)',
                backgroundColor: insight?.favorite ? 'rgba(255, 0, 122, 0.08)' : 'white',
                fontWeight: 700,
                fontSize: '15px',
              }}
              onClick={() => {
                if (insight) {
                  handleCreateOrUpdateInsight(
                    insight,
                    insight.sentiment!!,
                    !insight.favorite,
                  );
                }
              }}
            >
              <FavoriteIcon /> Favorite
            </Button>
            <Divider
              sx={{
                marginY: '0.5rem',
              }}
            />
            <Box
              display="flex"
              gap={1}
              // marginTop="1rem"
              flexDirection={{
                md: 'row',
              }}
            >
              <Button
                variant="outlined"
                fullWidth
                onClick={handlePreviousInsight}
                disabled={disabledButtonBack}
              >
                Back
              </Button>
              <Button
                variant="contained"
                fullWidth
                onClick={handleNextInsight}
                disabled={disabledButtonNext}
              >
                Next
              </Button>
            </Box>
          </Grid>
        )}

        <Stack
          direction={isMobile ? 'column' : 'row'}
          display="flex"
          justifyContent="space-between"
          width="100%"
          marginTop="1.5rem"
          paddingLeft="10px"
        >
          {!isMobile && (
            <Box display="flex" gap={1}>
              <Button
                sx={{
                  backgroundColor:
                    insight?.sentiment === Sentiment.Negative
                      ? 'rgba(211, 47, 47, 0.08)'
                      : 'white',
                  color: '#D32F2F',
                  border:
                    insight?.sentiment === Sentiment.Negative
                      ? '1px solid #D32F2F'
                      : '1px solid rgba(166, 155, 161, 0.5)',
                  fontWeight: 700,
                  fontSize: '15px',
                }}
                // // color="error"
                onClick={() => {
                  if (insight) {
                    const newSentiment =
                      insight.sentiment === Sentiment.Negative
                        ? Sentiment.Neutral
                        : Sentiment.Negative;
                    handleCreateOrUpdateInsight(
                      insight,
                      newSentiment,
                      insight.favorite!!,
                    );
                  }
                }}
              >
                <ThumbDownAltIcon />
              </Button>
              <Button
                color="success"
                sx={{
                  backgroundColor:
                    insight?.sentiment === Sentiment.Positive
                      ? 'rgba(46, 125, 50, 0.08)'
                      : 'white',
                  color: '#2E7D32',
                  border:
                    insight?.sentiment === Sentiment.Positive
                      ? '1px solid #2E7D32'
                      : '1px solid rgba(166, 155, 161, 0.5)',
                  fontWeight: 700,
                  fontSize: '15px',
                }}
                onClick={() => {
                  if (insight) {
                    const newSentiment =
                      insight.sentiment === Sentiment.Positive
                        ? Sentiment.Neutral
                        : Sentiment.Positive;
                    handleCreateOrUpdateInsight(
                      insight,
                      newSentiment,
                      insight.favorite!!,
                    );
                  }
                }}
              >
                <ThumbUpIcon />
              </Button>
            </Box>
          )}
          <Stack direction={isMobile ? 'column' : 'row'} gap={1}>
            <Button
              variant="outlined"
              endIcon={<ArrowForward />}
              onClick={() => handleCreatePostDraft(insight!, true)}
            >
              Add my perspective and generate draft
            </Button>
            <Button
              variant="contained"
              endIcon={<AutoAwesome />}
              onClick={() => handleCreatePostDraft(insight!, false)}
            >
              Generate post draft
            </Button>
          </Stack>
        </Stack>
      </Grid>
    </Box>
  );
};

export default InsightView;
