import { Close, Public } from '@mui/icons-material';
import { Box, Button, Divider, Grid, IconButton, Paper, Snackbar, TextField, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { useMutation } from '@apollo/client';
import { CREATE_THEME_DESCRIPTION } from '../../graphql/mutations';
import { LoadingButton } from '@mui/lab';

const buttonStyle = {
  height: '32px',
  border: '1px solid rgba(255, 0, 122, 1)',
  borderRadius: '100px',
};
const GeneratePostTheme = () => {
  const sugestions = [
    'Viajes y Aventuras',
    'Estilo de Vida Saludable',
    'Tecnología e Innovación',
    'Cocina y Recetas',
    'Entretenimiento y Cultura Pop',
    'Consejos de Productividad',
    'Moda y Tendencias',
    'Desarrollo Personal',
    'Consejos Financieros',
    'Humor y Entretenimiento',
    'Animales y Mascotas',
  ];

  const [optionSelected, setOptionSelected] = useState<string>('');
  const [dataShow, setDataShow] = useState<string>('');
  const { control } = useForm();
  const [createTheme, { loading }] = useMutation(CREATE_THEME_DESCRIPTION);
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);

  const onSubmit = () => {
    createTheme({
      variables: { input: optionSelected },
      onCompleted: (data) => {
        setDataShow(data.createThemeDescription.data as string);
      },
    });
  };

  const onChangeTheme = (event: any, value: any) => {
    setOptionSelected(value);
  };

  const copyText = () => {
    navigator.clipboard.writeText(dataShow);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);

  };

  return (
    <Box
      width="100vw"
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={4}
      marginTop="4rem"
    >
      <Grid container alignItems="center">
        <Grid item xs={12} md={6}>
          <Box width="100%" maxWidth="586px" marginX="auto">
            <Typography
              fontWeight={600}
              fontSize="26px"
              textAlign="center"
            >
              Elige la temática para tu contenido
            </Typography>
            <Box display="flex" justifyContent="center">
              <Controller
                name="theme"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      InputProps={{
                        style: {
                          height: '40px',
                          borderRadius: '10px',
                          backgroundColor: 'white',
                          boxShadow: '0px 10px 24px 4px #4F4F4F1F',
                          padding: '0 1rem',
                        },
                      }}
                      id="outlined-select-currency"
                      // label="Selecciona una temática"
                      placeholder="Especifica tu temática"
                      value={optionSelected}
                      onChange={(event) => {
                        onChange(event);
                        onChangeTheme(event, event.target.value);
                      }}
                      // variant="outlined"
                      sx={{
                        width: { xs: '90%', md: '555px' },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'rgba(0, 0, 0, 0.1)',
                          },
                          '&:hover fieldset': {
                            borderColor: 'rgba(0, 0, 0, 0.1)',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'rgba(0, 0, 0, 0.1)',
                          },
                        },
                        marginY: '2rem',
                      }}
                    />
                  );
                }}
              />
            </Box>
            <Box
              display="flex"
              width="100%"
              flexWrap="wrap"
              gap={2}
              justifyContent="center"
              // marginTop="2rem"
            >
              {sugestions.map((option) => (
                <Button
                  variant={optionSelected === option ? 'contained' : 'outlined'}
                  sx={{
                    height: '32px',
                    border: '1px solid rgba(255, 0, 122, 1)',
                    borderRadius: '100px',
                    color: optionSelected === option ? 'white' : 'black',
                    boxShadow: ' 0px 10px 24px 4px #4F4F4F1F',
                  }}
                  key={option}
                  onClick={() => setOptionSelected(option)}
                >
                  {option}
                </Button>
              ))}
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
              <LoadingButton
                variant="contained"
                sx={{
                  borderRadius: '100px',
                  height: '42px',
                  marginX: 'auto',
                  marginTop: '2rem',
                  width: '143px',
                  marginBottom: { xs: '2rem', md: '0' },
                }}
                disabled={!optionSelected}
                onClick={onSubmit}
                loading={loading}
              >
                Generar post
              </LoadingButton>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} justifyContent="center">
          <Box
            sx={{
              width: '100%',
              maxWidth: '555px',
              marginX: 'auto',
            }}
          >
            {dataShow && (
              <LoadingButton
                variant="outlined"
                sx={{
                  ...buttonStyle,
                  height: '42px',
                  marginTop: '2rem',
                  marginBottom: { xs: '2rem', md: '1rem' },
                  fontWeight: 700,
                  fontSize: '15px',
                }}
                disabled={!optionSelected}
                onClick={onSubmit}
                loading={loading}
              >
                Generar de nuevo
              </LoadingButton>
            )}
            <Paper
              sx={{
                width: '100%',
                height: 'auto',
                marginX: 'auto',
                borderRadius: '8px',
                boxShadow: '0px 10px 24px 4px rgba(79, 79, 79, 0.12) !important',
                paddingBottom: '1rem',
              }}
            >
              <Box
                display="flex"
                padding="15px"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box display="flex" gap={1}>
                  <Box width="50px">
                    <img
                      src="/images/lm/imgProfile.png"
                      alt="imagen"
                      style={{
                        width: '100%',
                        margin: 'auto',
                      }}
                    />
                  </Box>
                  <Box fontFamily={"'Roboto', sans-serif"}>
                    <Typography fontFamily="inherit" fontWeight={600} fontSize="14px">
                      Carolina Medina
                      <span
                        style={{
                          color: 'rgba(0, 0, 0, 0.6)',
                          fontWeight: '400',
                          fontSize: '14px',
                        }}
                      >
                        · 2º
                      </span>
                    </Typography>
                    <Typography
                      fontFamily="inherit"
                      fontSize="12px"
                      color="rgba(0, 0, 0, 0.6)"
                    >
                      Design Manager BBVA | Founder The Behavio...
                    </Typography>
                    <Box display="flex">
                      <Typography
                        fontFamily="inherit"
                        fontSize="12px"
                        color="rgba(0, 0, 0, 0.6)"
                      >
                        1 hora ·{' '}
                      </Typography>
                      <Public
                        sx={{
                          fontSize: '18px',
                          color: 'rgba(0, 0, 0, 0.6)',
                        }}
                      />
                    </Box>
                  </Box>
                </Box>

                <Box display="flex" alignItems="center" gap={1}>
                  <Box display="flex">
                    <PersonAddAlt1Icon
                      sx={{
                        color: '#0A66C2',
                      }}
                    />
                    <Typography
                      color="#0A66C2"
                      fontSize="16px"
                      fontWeight={700}
                      fontFamily="'Roboto', sans-serif"
                    >
                      Conectar
                    </Typography>
                  </Box>

                  <img
                    src="/images/icons/connect.png"
                    style={{ width: '20px', height: '4px' }}
                    alt="connect"
                  />
                </Box>
              </Box>

              <Box padding="20px">
                <Typography
                  fontFamily="'Roboto', sans-serif"
                  fontSize="14px"
                  color="rgba(0, 0, 0, 0.9)"
                  fontWeight={400}
                  textAlign="justify"
                >
                  {dataShow ? (
                    dataShow
                      .split('\n')
                      .map((line, index) => (
                        <Fragment key={index}>
                          {line} <br />
                        </Fragment>
                      ))
                  ) : (
                    <>
                      ¡Hola profesionales! ✨ <br /> <br />
                      ¿Listos para maximizar su jornada laboral? Aquí va un consejo para
                      cada día:
                      <br />
                      <br /> Enfócate en tus metas desde el principio, traza tu camino
                      hacia el éxito. <br />
                      <br /> Aplica la técnica Pomodoro ⏲️: divide tu día, conquista tus
                      tareas. <br />
                      <br />
                      Comparte responsabilidades; juntos, logramos más. <br />
                      <br /> Elimina distracciones 📵 y da tu 100% a cada tarea. <br />
                      <br /> Sé específico con tus objetivos; ponles un límite de tiempo.{' '}
                      <br />
                      <br /> ¡Muévete! Breves momentos de actividad física 💪 reviven
                      cuerpo y mente. Usa herramientas como Trello 🛠️ para organizarte
                      eficientemente. Aprende a decir no a tareas que no te acercan a tus
                      metas. ¡Transforma cada día laboral en una victoria! 💪✨ <br />
                      <br /> #ProductividadLaboral #ÉxitoProfesional
                    </>
                  )}
                </Typography>
                <Typography
                  marginTop="2rem"
                  fontFamily="'Roboto', sans-serif"
                  fontSize="14px"
                  color="rgba(10, 102, 194, 1)"
                  fontWeight={600}
                >
                  Ver traducción
                </Typography>
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                paddingX="5px"
                marginY="1rem"
                alignItems="center"
                fontFamily="'Roboto', sans-serif"
                color="rgba(0, 0, 0, 0.6)"
              >
                <Box display="flex" alignItems="center">
                  <img
                    src="/images/icons/interactions.png"
                    style={{ width: '44px', height: '20px' }}
                    alt="interactions"
                  />
                  <Typography fontSize="12px" marginLeft="5px" fontFamily="inherit">
                    Carol TH y 45 personas más
                  </Typography>
                </Box>
                <Typography fontSize="12px" fontFamily="inherit">
                  2 comentarios · 4 veces compartido
                </Typography>
              </Box>
              <Divider
                orientation="horizontal"
                sx={{
                  height: '1px',
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  width: '100%',
                  marginY: '1rem',
                }}
              />
              <Box
                display="flex"
                paddingX="2rem"
                justifyContent="space-around"
                fontSize={{ xs: '12px', md: '14px' }}
                fontFamily={"'Roboto', sans-serif"}
                marginBottom={{ xs: '2rem', md: '1rem' }}
              >
                <Box display="flex" alignItems="center" color="rgba(0, 0, 0, 0.6)">
                  <img
                    src="/images/icons/like.png"
                    style={{ width: '24px', height: '24px' }}
                    alt="like"
                  />
                  <Typography marginLeft="5px" fontSize="inherit" fontFamily="inherit">
                    Recomendar
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <img
                    src="/images/icons/comment.png"
                    style={{ width: '24px', height: '24px' }}
                    alt="comment"
                  />
                  <Typography fontSize="inherit" fontFamily="inherit" marginLeft="5px">
                    Comentar
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <img
                    src="/images/icons/share.png"
                    style={{ width: '24px', height: '24px' }}
                    alt="share"
                  />
                  <Typography fontSize="inherit" fontFamily="inherit" marginLeft="5px">
                    Compartir
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <img
                    src="/images/icons/send.png"
                    style={{ width: '24px', height: '24px' }}
                    alt="send"
                  />
                  <Typography fontSize="inherit" fontFamily="inherit" marginLeft="5px">
                    Enviar
                  </Typography>
                </Box>
              </Box>
            </Paper>

          {dataShow && !loading && (
            <Box
              display="flex"
              justifyContent={{ xs: 'center', md: 'flex-end' }}
              marginTop={{ xs: '2rem', md: '1rem' }}
              gap={2}
            >
              <Button sx={{
                ...buttonStyle,
                height: '42px',
              }} variant="outlined" onClick={copyText}>
                Copiar
              </Button>
              <Button variant="contained" sx={{
                ...buttonStyle,
                height: '42px',
              }}
              onClick={()=>window.open('https://app.magnettu.com/signup', '_blank')}
              >Publicar en redes sociales</Button>
            </Box>
          )}
          </Box>

        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isSnackbarOpen}
        autoHideDuration={2000}
        onClose={()=>setSnackbarOpen(false)}
        message="¡Texto copiado!"
        action={
          <IconButton size="small" color="inherit" onClick={handleSnackbarClose}>
            <Close fontSize="small" />
          </IconButton>
        }
      />
    </Box>
  );
};

export default GeneratePostTheme;
