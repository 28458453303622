import { Stack, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { TabPanel } from '../../components/Tabs/TabPanel';
import ChainsTab from './ChainsTab';
import PostsTab from './PostsTab';
import PrompterRunsTab from './PrompterRunsTab';
import { useLocation } from 'react-router-dom';
import IdeasTab from './IdeasTab';

export enum ContentManagerTab {
  Chains = 0,
  Ideas = 1,
  PrompterRuns = 2,
  Posts = 3,
}

const ContentManagerAdmin = () => {
  const { state } = useLocation();
  const [tab, setTab] = useState(
    () =>
      Number((state && state.tab) || localStorage.getItem('contentManagerTab')) ||
      ContentManagerTab.Posts,
  );

  useEffect(() => {
    localStorage.setItem('contentManagerTab', String(tab));
  }, [tab]);

  const handleTabChange = (_: any, newValue: number) => {
    setTab(newValue);
  };

  return (
    <>
      <Stack direction="column" p={3} gap={4}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          sx={{ borderBottom: 1, borderRadius: 0, borderColor: 'grey.100' }}
        >
          <Tab
            disableRipple
            label="Chains"
            sx={{
              textTransform: 'none',
              fontSize: 16,
              fontWeight: 'bold',
              color: 'text.primary',
            }}
          />
            <Tab
              disableRipple
              label="Ideas"
              sx={{
                textTransform: 'none',
                fontSize: 16,
                fontWeight: 'bold',
                color: 'text.primary',
              }}
            />
          <Tab
            disableRipple
            label="Prompter"
            sx={{
              textTransform: 'none',
              fontSize: 16,
              fontWeight: 'bold',
              color: 'text.primary',
            }}
          />
            <Tab
              disableRipple
              label="Posts"
              sx={{
                textTransform: 'none',
                fontSize: 16,
                fontWeight: 'bold',
                color: 'text.primary',
              }}
            />
        </Tabs>
        <TabPanel value={tab} index={ContentManagerTab.Chains} sx={{ p: 0 }}>
          <ChainsTab />
        </TabPanel>
        <TabPanel value={tab} index={ContentManagerTab.Ideas} sx={{ p: 0 }}>
          <IdeasTab />
        </TabPanel>
        <TabPanel value={tab} index={ContentManagerTab.PrompterRuns} sx={{ p: 0 }}>
          <PrompterRunsTab />
        </TabPanel>
        <TabPanel value={tab} index={ContentManagerTab.Posts} sx={{ p: 0 }}>
          <PostsTab />
        </TabPanel>
      </Stack>
    </>
  );
};

export default ContentManagerAdmin;
