import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { Post } from '../../__generated__/graphql';
import { PostHandlers, PostStatus } from '../../hooks/usePost';
import Avatar from '../Avatar/Avatar';

interface Props {
  status: PostStatus | undefined;
  post: Post | undefined;
  handlers: PostHandlers | undefined;
  onClose: (force?: boolean) => void;
}

const ConfirmationPostModal = ({ status, post, handlers, onClose }: Props) => {
  return (
    <Dialog
      maxWidth="xs"
      open={status!.confirmationPostsDialogOpen}
      onClose={handlers?.handleCloseConfirmationPostsDialog}
      PaperProps={{
        sx: {
          borderRadius: '8px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          width: '550px',
        },
      }}
    >
      <DialogTitle>
        <Typography fontSize={25} fontWeight={700}>
          Post on LinkedIn
        </Typography>
        <Close
          style={{
            position: 'absolute',
            right: '10px',
            top: '10px',
            cursor: 'pointer',
          }}
          onClick={handlers?.handleCloseConfirmationPostsDialog}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Divider />
          <Box
            display="block"
            width="100%"
            alignItems="center"
            gap={1}
            sx={{
              height: '56px',
              marginY: '10px',
              color: 'black',
            }}
          >
            <Typography>Are you sure you want to publish this post on </Typography>
            <Stack flexDirection="row" gap={1} alignItems="center">
              <Avatar
                name={post?.brand?.name || ''}
                backgroundColor={post?.brand?.backgroundColor || undefined}
                avatar={post?.brand?.account?.avatar || undefined}
                size={25}
              />{' '}
              <Typography>
                <b> {post?.brand?.name}</b>
              </Typography>
            </Stack>
          </Box>
          <Divider />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={() => handlers?.handlePostOnLinkedin(() => onClose(true))}
          disabled={status?.isSubmitDisabled}
          loading={status?.isPosting}
          variant="contained"
          sx={{
            width: '100%',
            maxWidth: '258px',
            marginX: 'auto',
            marginTop: '-10px',
            marginBottom: '15px',
          }}
        >
          Accept
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationPostModal;
